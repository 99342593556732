import BlobedCapsuleModel from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedCapsuleModel.class";
import {Mesh, ShaderMaterial, Vector3} from "@babylonjs/core";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import EventBusEvents from "@/classes/EventBusEvents";
import EventBus from "@/classes/EventBus.class";
import eases from "eases";

export default class SatelliteCapsuleModel extends BaseBlob {
  private _parentModel!: BlobedCapsuleModel
  private _positionIndex = 0
  private _sPosition = Vector3.Zero()
  private _sRotation = Vector3.Zero()
  private _sScaling = 1

  private _slideUpTime = 0

  constructor(props: SceneItemArgs) {
    super(props);

    this._linesCount = 20
    this._lineThickness = 0.4
    this._speed = 0.00005

    // this._createMaterial()
    this._createStaticCustomPbr()
    this._createCapsule()

    // this._meshFront.scaling = new Vector3(0.2, 0.2, 0.2)
    // this._meshBack.scaling = new Vector3(0.2, 0.2, 0.2)
  }

  protected _createStaticCustomPbr() {
    super._createStaticCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/capsule-satellite/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
  }

  protected _update() {
    this._time += this.sceneController.engine.getDeltaTime()

    if (this._materialBack) {
      this._materialBack.setFloat('time', this._time)
      this._materialBack.setFloat('speed', this._speed)
      this._materialBack.setFloat('radius', this._radius)
      this._materialBack.setFloat('linesCount', this._linesCount)
    }

    if (this._meshFront) {

      if (this._parentModel.visible) {
        this._meshFront.position = this._sPosition.clone().add(new Vector3(0,Math.sin(this._time * 0.002 + this._positionIndex * Math.PI) * 0.03,0))
        this._meshFront.scaling = this._meshBack.scaling = new Vector3(this._sScaling, this._sScaling, this._sScaling)
        this._meshFront.rotation = this._meshBack.rotation = this._sRotation
      } else {
        if (this._slideUpTime < this._time) {
          const time = ((this._time - this._slideUpTime) / 2) / 1000
          this._meshFront.position.y = this._sPosition.y + eases.cubicInOut(time) * 3
        }
      }

      this._sRotation.y += this._sScaling * 0.1

      this._meshBack.position = this._meshFront.position
    }
  }

  protected _onMouseMove(e: MouseEvent) {
    //
  }

  public setParentModel(capsule: BlobedCapsuleModel, index: number) : void {
    this._parentModel = capsule
    this._positionIndex = index

    switch (index) {
      case 0:
        this._sPosition.x = -0.5
        this._sPosition.y = -0.7
        this._sPosition.z = -1.55

        this._sScaling = 0.2
        break

      case 1:
        this._sPosition.x = 0.817644
        this._sPosition.y = 0.878495
        this._sPosition.z = 1

        this._sScaling = 0.15
        break
    }

    this._sPosition = this._sPosition.multiplyByFloats(0.5, 0.5, 0.5)
  }


  private _createMaterial() {
    this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/landing/capsule-satellite/fx/blob", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })
    this._materialFront.backFaceCulling = false

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/capsule-satellite/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true

    this._createLights()
  }

  private _createCapsule() : void {

    this.loadModelFromUrl('/landing/capsule-satellite/model.glb', false, false).then((assetContainer) => {
      const originMesh:Mesh = assetContainer.meshes.filter((mesh) => mesh.name === 'Capsule')[0] as Mesh
      const parentMesh = new Mesh('ico-root', this.scene)

      originMesh.parent = parentMesh
      originMesh.position = Vector3.Zero()


      this._meshFront = parentMesh.clone()
      this._meshBack = parentMesh.clone()

      this._meshFront.getChildMeshes().forEach((mesh) => {
        if (mesh.material) mesh.material = this._customFrontMaterial
      })

      this._meshBack.getChildMeshes().forEach((mesh) => {
        const meshInstance : Mesh = mesh as Mesh
        meshInstance.makeGeometryUnique()
        meshInstance.flipFaces(true)
        mesh.material = this._materialBack
      })

      this._meshFront.parent = this._parentModel.meshFront
      this._meshBack.parent = this._parentModel.meshFront

      parentMesh.dispose()

      this._meshFront.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)
      this._meshBack.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)

      this._meshFront.layerMask = 2
      this._meshBack.layerMask = 2

      this.blobedPostProcess.addMeshForRender(this._meshFront, true)
      this.blobedPostProcess.addMeshForRender(this._meshBack, false)

      EventBus.emit(EventBusEvents.BlobedCapsuleSatelliteLoaded)

    })
  }

  public slideUp() : void {
    this._slideUpTime = this._time + this._positionIndex * 500 + 500
  }
}
