
import {Options, prop, Vue} from "vue-class-component";
import BackgroundSceneController, {SceneState} from "@/components/Landing/Background/classes/BackgroundSceneController.class";
import BackgroundText from "@/components/Landing/Background/BackgroundText.vue";
import {reactive} from "vue";
import EventBusEvents from "@/classes/EventBusEvents";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";

class BackgroundProps {
  hidden: boolean = prop({
    type: Boolean,
    required: true
  })
}

@Options({
  components: {
    BackgroundText
  },
  watch: {
    hidden(val) {
      val ? this.hide() : this.show()
    }
  }
})
export default class Background extends Vue.with(BackgroundProps) {
  private _sceneController!:BackgroundSceneController
  private lastTouch: Touch | null = null
  public state = reactive({
    sceneState: 0
  })


  public mounted() : void {
    this._sceneController = new BackgroundSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    document.addEventListener('wheel', this.onMouseWheel)
    // document.addEventListener('touchstart', this.onTouchStart)
    // document.addEventListener('touchend', this.onTouchEnd)
    // document.addEventListener('touchmove', this.onTouchMove)

    EventBus.addEventListener(EventBusEvents.LandingScene, this.onLandingScene)
    EventBus.addEventListener(EventBusEvents.ShowNextSlide, this.onShowNextSlide)
    EventBus.addEventListener(EventBusEvents.ShowPrevSlide, this.onShowPrevSlide)
  }

  public unmounted() : void {
    document.removeEventListener('wheel', this.onMouseWheel)
    EventBus.removeEventListener(EventBusEvents.LandingScene, this.onLandingScene)
    EventBus.removeEventListener(EventBusEvents.ShowNextSlide, this.onShowNextSlide)
    EventBus.removeEventListener(EventBusEvents.ShowPrevSlide, this.onShowPrevSlide)

    // document.removeEventListener('touchstart', this.onTouchStart)
    // document.removeEventListener('touchend', this.onTouchEnd)
    // document.removeEventListener('touchmove', this.onTouchMove)
  }

  private onShowNextSlide() : void {
    this._sceneController.showNextSlide()
  }

  private onShowPrevSlide() : void {
    this._sceneController.showPrevSlide()
  }

  public onLandingScene(e: Event) : void {
    const sceneState: SceneState | null = (e as EventBusEvent).data as SceneState | null
    this.state.sceneState = sceneState as number
  }

  public onMouseWheel(e:WheelEvent) : void {
    if (e.deltaY < -10) {
      this._sceneController.showPrevSlide()
    } else if (e.deltaY > 10) {
      this._sceneController.showNextSlide()
    }
  }

  private onTouchMove(e: TouchEvent) : void {
    const touch = e.touches[0]
    const deltaY = touch.clientY - this.lastTouch!.clientY

    if (deltaY > 0) {
      this._sceneController.showPrevSlide()
    } else {
      this._sceneController.showNextSlide()
    }
  }

  private onTouchStart(e: TouchEvent) : void {
    this.lastTouch = e.touches[0]
  }

  private onTouchEnd(e: TouchEvent) : void {
    this.lastTouch = null
  }

  public hide() {
    this._sceneController.hide()
  }

  public show() {
    this._sceneController.show()
  }
}
