import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "shaders-arts" }
const _hoisted_2 = { class: "canvas-container" }
const _hoisted_3 = { ref: "canvas" }
const _hoisted_4 = { class: "scenes-list" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scenesList, (item) => {
        return (_openBlock(), _createElementBlock("button", {
          key: item,
          onClick: ($event: any) => (_ctx.enableEffect(item))
        }, _toDisplayString(item), 9, _hoisted_5))
      }), 128))
    ])
  ]))
}