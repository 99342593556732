
import { reactive } from "vue";
import {Vue} from "vue-class-component";
import EventBusEvents from "@/classes/EventBusEvents";
import EventBus from "@/classes/EventBus.class";
import isMobile from "is-mobile";

export default class LandingPreloader extends Vue {
  public state = reactive({
    progress: 0,
    toProgess: 0,
    itemsVisible: false,
    preloaderVisible: true,
    complete: false
  })

  private animate = true

  private needToLoadEvents: string[] = [
    EventBusEvents.CubeTextureLoaded,
    EventBusEvents.BlobedCapsuleLoaded,
    EventBusEvents.BlobedCapsuleSatelliteLoaded,
    EventBusEvents.BlobedCapsulesLoaded,
    EventBusEvents.BlobedIcoLoaded,
    EventBusEvents.BlobedIcoSatelliteLoaded,
    EventBusEvents.BlobedSphereLoaded,
    EventBusEvents.BlobedVrLoaded,
    EventBusEvents.BlobedVrSatelliteLoaded
  ]

  private loadedEvents: string[] = []

  get height() : number {
    return this.state.progress * 65
  }

  get y() : number {
    return 65 - this.state.progress * 65
  }

  get loaded() : string {
    const percents = Math.round(this.state.progress * 100)
    return percents + '%'
  }

  mounted() {
    this.needToLoadEvents.forEach((event) => {
      EventBus.addEventListener(event, this.onLoadEvent)
    })

    setTimeout(() => {
      this.state.itemsVisible = true
    }, 100)

    this.animationFrame()
  }

  private onLoadEvent(e: Event) : void {
    if (this.loadedEvents.indexOf(e.type) < 0) this.loadedEvents.push(e.type)
    this.state.toProgess = this.loadedEvents.length / this.needToLoadEvents.length
  }

  private animationFrame() {
    if (this.animate) {
      window.requestAnimationFrame(() => {
        this.state.progress += (this.state.toProgess - this.state.progress) / 20

        if (this.state.progress > 0.99 && !this.state.complete) {
          this.state.complete = true
          this.state.progress = 1
          setTimeout(() => {
            this.state.preloaderVisible = false
            this.animate = false
            EventBus.emit(EventBusEvents.AllLoaded)

            if (isMobile()) {
              try { // prevent exception on browsers not supporting DOM styleSheets properly
                for (let si in document.styleSheets) {
                  let styleSheet = document.styleSheets[si]
                  if (!styleSheet.rules) continue;

                  for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    const rule = styleSheet.rules[ri] as CSSStyleRule
                    if (!rule.selectorText) continue;

                    if (rule.selectorText.match(':hover')) {
                      styleSheet.deleteRule(ri);
                    }
                  }
                }
              } catch (ex) {
                console.log(ex)
              }
            }
          }, 100)
        }

        this.animationFrame()
      })
    }
  }
}
