
import {Options, prop, Vue} from "vue-class-component";
import {reactive} from 'vue'
import MathDesignSlide from "@/components/Landing/SlidesManager/slides/MathDesignSlide.vue";
import MarketLeadersSlide from "@/components/Landing/SlidesManager/slides/MarketLeadersSlide.vue";
import Web3dSlide from "@/components/Landing/SlidesManager/slides/Web3dSlide.vue";
import ArSlide from "@/components/Landing/SlidesManager/slides/ArSlide.vue";
import VrSlide from "@/components/Landing/SlidesManager/slides/VrSlide.vue";
import SliderSlide from "@/components/Landing/SlidesManager/slides/SliderSlide.vue";
import FinalSlide from "@/components/Landing/SlidesManager/slides/FinalSlide.vue";
import ScrollerInfo from "@/components/Landing/SlidesManager/ScrollerInfo.vue";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import {SceneState} from "@/components/Landing/Background/classes/BackgroundSceneController.class";

class SlidesManagerProps {
  hidden: boolean = prop({
    required: true
  })
}

@Options({
  components: {
    MathDesignSlide,
    MarketLeadersSlide,
    Web3dSlide,
    ArSlide,
    VrSlide,
    SliderSlide,
    FinalSlide,
    ScrollerInfo
  }
})
export default class SlidesManager extends Vue.with(SlidesManagerProps) {
  public state = reactive({
    currentComponent: '',
    hidden: false
  })

  public currentState!: SceneState | null
  public lastState!: SceneState

  private _scenesMap:Map<string, SceneState> = new Map(Object.entries({
    'MathDesignSlide': SceneState.MathDesign,
    'MarketLeadersSlide': SceneState.MarketLeaders,
    'Web3dSlide': SceneState.Web3d,
    'ArSlide1': SceneState.AR1,
    'ArSlide2': SceneState.AR2,
    'VrSlide': SceneState.VR,
    'SliderSlide': SceneState.Slider,
    'FinalSlide': SceneState.Final
  }))

  private _sceneStatesList: SceneState[] = [
    SceneState.MathDesign,
    SceneState.MarketLeaders,
    SceneState.Web3d,
    SceneState.AR1,
    SceneState.AR2,
    SceneState.VR,
    SceneState.Slider,
    SceneState.Final,
  ]

  public mounted() : void {

    // setTimeout(() => {
    //   this.state.currentComponent = 'MathDesignSlide'
    // }, 1000)

    EventBus.addEventListener(EventBusEvents.LandingScene, this._onLandingScene)
  }

  public unmounted() : void {
    EventBus.removeEventListener(EventBusEvents.LandingScene, this._onLandingScene)
  }

  public getPosition(name: string) : string {

    const currentIndex = this._sceneStatesList.indexOf(this.lastState)
    const sceneIndex = this._sceneStatesList.indexOf(this.getStateByName(name))

    return sceneIndex === currentIndex ? 'center' : sceneIndex < currentIndex ? 'top' : 'bottom'
  }

  public getStateByName(name: string) : SceneState {
    return this._scenesMap.get(name) as SceneState
  }

  private _onLandingScene(e: Event) : void {
    this.currentState = (e as EventBusEvent).data as (SceneState | null)
    if (this.currentState !== null) {
      this.lastState = this.currentState

      const keys: string[] = Array.from(this._scenesMap.keys())

      for (let key of keys) {
        if (this._scenesMap.get(key) as SceneState === this.currentState) {
          this.state.currentComponent = key
          break
        }
      }
    }
  }
}
