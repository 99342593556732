import SceneController from "@/classes/SceneController.class";
import {
  Color3,
  Color4,
  Scene,
  WebXRCamera,
  WebXRExperienceHelper,
  WebXRFeaturesManager,
  WebXRSessionManager
} from "@babylonjs/core";
import TestSphereSceneItem from "@/components/Vr/classes/sceneItems/TestSphereSceneItem.class";
import JettaModelWrapperSceneItem from "@/components/Vr/classes/sceneItems/JettaModelWrapperSceneItem.class";
import ControllersInput from "@/components/Vr/classes/VrSceneController/ControllersInput.class";

export class VrSceneEvent extends Event {
  public data:any = {}

  constructor(type : string, data : any = null) {
    super(type)
    this.data = data
  }
}

export enum VrSceneEvents {
  XRHelperCreated = 'XRHelperCreated',
  XRSessionStarted = 'XRSessionStarted',
  XRSessionStopped = 'XRSessionStopped'
}

export default class VrSceneController extends SceneController {
  private _webXRSessionManager!: WebXRSessionManager
  private _xrHelper!: WebXRExperienceHelper
  private _xrAvailable = false

  private _xrCamera!: WebXRCamera

  private _testSphereSceneItem!: TestSphereSceneItem
  private _jettaModel!: JettaModelWrapperSceneItem

  private _controllersInput!: ControllersInput

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()

    this.scene.clearColor = new Color4(0, 0, 0, 1)
    this.scene.ambientColor = Color3.Black()
    this.scene.environmentIntensity = 1

    this.scene.fogEnabled = true
    this.scene.fogColor = Color3.Black()
    this.scene.fogStart = 15
    this.scene.fogEnd = 25
    this.scene.fogMode = Scene.FOGMODE_LINEAR

    // this._testSphereSceneItem = new TestSphereSceneItem({
    //   sceneController: this
    // })
    this.scene.createDefaultXRExperienceAsync()
    this._jettaModel = new JettaModelWrapperSceneItem({
      sceneController: this
    })

    this._createXrHelper()
  }

  private async _createXrHelper() : Promise<void> {
    try {
      this._xrHelper = await WebXRExperienceHelper.CreateAsync(this.scene)
      this._controllersInput = new ControllersInput(this._xrHelper.sessionManager, this._xrHelper.camera)
      console.log(this._xrHelper)
      this.dispatchEvent(new VrSceneEvent(VrSceneEvents.XRHelperCreated, true))
      this._xrAvailable = true
    } catch (e) {
      this.dispatchEvent(new VrSceneEvent(VrSceneEvents.XRHelperCreated, false))
      this._xrAvailable = false
    }
  }

  public enterToXR() : void {
    if (this._xrAvailable) {
      this._xrHelper.enterXRAsync("immersive-vr", "local-floor")
        .then((sessionManager) => {
          this.dispatchEvent(new VrSceneEvent(VrSceneEvents.XRSessionStarted))
        })
    }
  }

  public exitFromVR() : void {
    this._controllersInput.dispose()
    this._xrHelper.exitXRAsync()
  }
}
