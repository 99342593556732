import {ArcRotateCamera, HemisphericLight, Light, Vector3} from "@babylonjs/core"
import {SceneItem, SceneItemArgs} from "./abstract/SceneItem.class"
import isMobile from "is-mobile";

class CameraController extends SceneItem {
  protected _camera!: ArcRotateCamera
  private _light!: Light

  get camera(): ArcRotateCamera {
    return this._camera
  }

  constructor(args: SceneItemArgs) {
    super(args)
    this._createCamera()
    this._createLight()
  }

  private _createCamera(): void {
    const camera: ArcRotateCamera = new ArcRotateCamera('camera', 0, 0, 5, Vector3.Zero(), this.scene)
    camera.position = new Vector3(0,0, -5)
    camera.minZ = 0
    camera.lowerRadiusLimit = 1
    camera.upperRadiusLimit = 20
    camera.wheelPrecision = isMobile() ? 1000 :  100

    camera.attachControl(this.canvas)

    this._camera = camera
  }

  private _createLight(): void {
    this._light = new HemisphericLight('light', Vector3.Up().add(Vector3.Right()).add(Vector3.Forward()), this.scene)
  }
}

export default CameraController
