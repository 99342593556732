import ModelController from "@/classes/SceneController/ModelController.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Color3, Mesh, MeshBuilder, StandardMaterial, Vector3} from "@babylonjs/core";
import WaterModelController from "@/components/WaterWorld/classes/WaterModelController.class";

class Drop {
  public mesh: Mesh
  public acceleration: number
  public speed = 0
  public disposed = false

  constructor(mesh: Mesh, acceleration: number) {
    this.mesh = mesh
    this.acceleration = acceleration
    this.speed = Math.random() * 0.1

    this.mesh.position = new Vector3(Math.random() * 40 - 20, 20, Math.random() * 40 - 20)
  }

  public dispose() {
    this.disposed = true
    this.mesh.dispose()
  }
}

export default class DropsController extends ModelController {

  private _waterController!: WaterModelController
  private _drops: Drop[] = []
  private _dropMaterial!: StandardMaterial

  constructor(args: SceneItemArgs) {
    super(args)

    this.scene.onBeforeRenderObservable.add(this._update)

    this._dropMaterial = new StandardMaterial('drop mat', this.scene)
    this._dropMaterial.diffuseColor = Color3.White()

    setInterval(() => {
      this._createDrop()
    }, 500)
  }

  private _update = () : void => {
    this._drops.forEach((dropItem) => {
      dropItem.mesh.position.y -= dropItem.speed
      dropItem.speed += dropItem.acceleration * .1

      if (dropItem.mesh.position.y < -0.1) {
        this._waterController.createRipple(new Vector3(dropItem.mesh.position.x, 0, dropItem.mesh.position.z))
        dropItem.dispose()
      }
    })

    this._drops = this._drops.filter((dropItem) => !dropItem.disposed)
  }

  private _createDrop() : void {
    const dropMesh:Mesh = MeshBuilder.CreateSphere('drop', {
      diameter: 0.1
    }, this.scene)

    dropMesh.material = this._dropMaterial

    const drop : Drop = new Drop(dropMesh, Math.random() * 0.1 + 0.02)
    this._drops.push(drop)
  }

  public setWaterController(waterModelController: WaterModelController) : void {
    this._waterController = waterModelController
  }
}
