<template>
  <div class="project-video">
    <iframe :src="videoUrl + '?rel=0&fs=0&controls=0&disablekb=1&modestbranding=0'" width="100%" height="100%" title="ACROSS TECHNOLOGY TO ART - A virtual journey to Arles with LG SIGNATURE /// Immerse lab" frameborder="0"></iframe>
  </div>
</template>
<script>
import {prop, Vue} from 'vue-class-component'

class ProjectVideoProps {
  videoUrl = prop({
    type: String,
    required: true
  })
}

export default class ProjectVideo extends Vue.with(ProjectVideoProps) {
}
</script>
