
import {Options, Vue} from "vue-class-component";
import Background from "@/components/Landing/Background.vue";
import Logo from "@/components/Landing/Logo.vue";
import SlidesManager from "@/components/Landing/SlidesManager.vue";
import MainMenu from "@/components/Landing/MainMenu.vue";
import { reactive } from "vue";
import Contacts from "@/components/Landing/Contacts.vue";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import LandingPreloader from "@/components/Landing/LandingPreloader.vue";

@Options({
  components: {
    Background,
    Logo,
    SlidesManager,
    MainMenu,
    Contacts,
    LandingPreloader
  },
})
export default class Landing extends Vue {
  public state = reactive({
    menuOpened: false,
    contactsOpened: false
  })

  public onMainMenuOpened(opened: boolean) : void {
    this.state.menuOpened = opened
    if (!this.state.menuOpened) {
      this.state.contactsOpened = false
    }
  }

  public mounted() : void {
    window.location.href = 'https://immercyb.com'
    EventBus.addEventListener(EventBusEvents.ContactsPageOpened, this.onContactPageState)
    EventBus.addEventListener(EventBusEvents.ViewProjects, this.onViewProjects)
  }

  public unmounted() : void {
    EventBus.removeEventListener(EventBusEvents.ContactsPageOpened, this.onContactPageState)
    EventBus.removeEventListener(EventBusEvents.ViewProjects, this.onViewProjects)
  }

  private onContactPageState(e: Event) : void {
    this.state.contactsOpened = (e as EventBusEvent).data as boolean
  }

  private onViewProjects() : void {
    this.state.menuOpened = true
  }
}
