enum EventBusEvents {
  SceneLoadStarted = 'SceneLoadStarted',
  SceneLoaded = 'SceneLoaded',
  QrCodePopup = 'QrCodePopup',

  LogoSceneThemeSelected = 'LogoSceneThemeSelected',
  LocaleChanged = 'LocaleChanged',

  LandingScene = 'LandingScene',
  ContactsPageOpened = 'ContactsPageOpened',
  ViewProjects = 'ViewProjects',
  ScrollUp = 'ScrollUp',

  CubeTextureLoaded = 'CubeTextureLoaded',
  BlobedCapsuleLoaded = 'BlobedCapsuleLoaded',
  BlobedCapsuleSatelliteLoaded = 'BlobedCapsuleSatelliteLoaded',
  BlobedCapsulesLoaded = 'BlobedCapsulesLoaded',
  BlobedIcoLoaded = 'BlobedIcoLoaded',
  BlobedIcoSatelliteLoaded = 'BlobedIcoSatelliteLoaded',
  BlobedSphereLoaded = 'BlobedSphereLoaded',
  BlobedVrLoaded = 'BlobedVrLoaded',
  BlobedVrSatelliteLoaded = 'BlobedVrSatelliteLoaded',

  AllLoaded = 'AllLoaded',

  ShowNextSlide = 'ShowNextSlide',
  ShowPrevSlide = 'ShowPrevSlide'
}

export default EventBusEvents
