import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {
  Color3,
  Mesh,
  MeshBuilder,
  Nullable,
  PBRMaterial,
  PickingInfo,
  StandardMaterial,
  Texture,
  Vector3
} from "@babylonjs/core";
import {GridMaterial} from "@babylonjs/materials";

export default class ArPlaceMarkerModel extends SceneItem {
  private _isSetuped = false
  private _mesh!: Mesh
  private _groundMaterial!: GridMaterial

  private _planesMesh!: Mesh

  constructor(props: SceneItemArgs) {
    super(props)
    this._createMesh()
    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _createPlanes() : void {
    this._planesMesh = new Mesh('ArMarker planes root mesh', this.scene)
    this._planesMesh.parent = this._mesh

    const planeVertical: Mesh = MeshBuilder.CreatePlane('Ar vertical plane', {
      width: 2.6,
      height: 2,
      sideOrientation: Mesh.DOUBLESIDE
    }, this.scene)

    planeVertical.parent = this._planesMesh
    planeVertical.position = new Vector3(0, 0.99, -0.98)

    const verticalMaterial:PBRMaterial = new PBRMaterial('vertical plane', this.scene)
    verticalMaterial.albedoColor = Color3.FromHexString('#FFFFFF')//Color3.FromHexString('#F7F5F0')
    verticalMaterial.alpha = 0.97
    verticalMaterial.environmentIntensity = 0
    verticalMaterial.roughness = 0.5
    verticalMaterial.metallic = 0.5

    const markerTextTexture: Texture = new Texture('/projects/lg-vangogh/textures/marker-top.png', this.scene)
    markerTextTexture.uScale = -1
    markerTextTexture.hasAlpha = true
    verticalMaterial.albedoTexture = markerTextTexture
    verticalMaterial.useAlphaFromAlbedoTexture = true

    planeVertical.material = verticalMaterial

    const planeHorizontal: Mesh = MeshBuilder.CreatePlane('Ar vertical plane', {
      width: 2.6,
      height: 2,
      sideOrientation: Mesh.DOUBLESIDE
    }, this.scene)

    planeHorizontal.parent = this._planesMesh
    planeHorizontal.position = new Vector3(0, 0, 0)
    planeHorizontal.rotation.x = Math.PI / 2

    const horizontalMaterial:PBRMaterial = new PBRMaterial('horizontal plane', this.scene)
    horizontalMaterial.albedoColor = Color3.FromHexString('#F7F5F0')
    horizontalMaterial.alpha = 0.9
    horizontalMaterial.environmentIntensity = 0
    horizontalMaterial.roughness = 0
    horizontalMaterial.metallic = 0

    planeHorizontal.material = horizontalMaterial

    const markerBottomTexture: Texture = new Texture('/projects/lg-vangogh/textures/marker-bottom.png', this.scene)
    markerBottomTexture.uScale = -1
    markerBottomTexture.hasAlpha = true
    horizontalMaterial.albedoTexture = markerBottomTexture
    horizontalMaterial.useAlphaFromAlbedoTexture = true
    horizontalMaterial.backFaceCulling = false
  }

  private _createMesh() : void {
    this._mesh = new Mesh('ArMarker mesh', this.scene)
    this._createPlanes()

    const groundMesh: Mesh = MeshBuilder.CreateGround('Marker', {
      width: 4,
      height: 4
    }, this.scene)

    groundMesh.scaling = Vector3.Zero()

    groundMesh.parent = this._mesh

    const ratio = 0.05
    const groundMaterial : GridMaterial = new GridMaterial("groundMaterial", this.scene)
    groundMaterial.majorUnitFrequency = (100 / ratio) * 0.01;
    groundMaterial.minorUnitVisibility = 0.3;
    groundMaterial.gridRatio = ratio;
    groundMaterial.backFaceCulling = false;
    groundMaterial.mainColor = Color3.White()
    groundMaterial.lineColor = Color3.FromHexString('#FFffff')
    groundMaterial.opacity = 0.9;
    groundMaterial.wireframe = false;
    groundMaterial.fogEnabled = true

    groundMesh.material = groundMaterial
    this._groundMaterial = groundMaterial
    // const standartMaterial: StandardMaterial = new StandardMaterial('marker', this.scene)
    //
    // const texture: Texture = new Texture('/textures/marker.png', this.scene)
    // standartMaterial.diffuseTexture = texture
    // groundMesh.material = standartMaterial
    //
    // this._material = groundMaterial
    // this._material.alpha = 0
    // this._mesh.material = this._material
  }

  private _update = () : void => {
    this._groundMaterial.opacity = this._isSetuped ? 0 : 0

    if (!this._isSetuped) {
      this._mesh.lookAt(this.camera.position)
      this._mesh.rotation.z = 0
      this._mesh.rotation.x = 0
      this._mesh.scaling = Vector3.Zero()
    } else {
      this._mesh.scaling = Vector3.Zero()
    }

    // if (this._mesh) {
    //   if (!this._isSetuped) {
    //     const x: number = window.innerWidth / 2
    //     const y: number = window.innerHeight - window.innerHeight / 2
    //     const pickedInfo: Nullable<PickingInfo> = this.scene.pick(x, y, (mesh) => mesh.isPickable)
    //
    //     if (pickedInfo?.pickedMesh && pickedInfo?.pickedMesh.name === 'ground' && pickedInfo?.pickedPoint) {
    //       this._mesh.position = pickedInfo.pickedPoint.clone()
    //       this._mesh.position.y = 0
    //       this._mesh.lookAt(this.camera.position)
    //       this._mesh.rotation.z = 0
    //       this._mesh.rotation.x = 0
    //     }
    //     this._mesh.scaling = Vector3.One()
    //   } else {
    //     this._mesh.scaling = Vector3.Zero()
    //   }
    // }
  }

  public setupOnSurface() : void {
    this._isSetuped = true
  }

}
