import {FreeCamera, HemisphericLight, Light, Vector3} from "@babylonjs/core"
import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import BackgroundSceneController from "@/components/Landing/Background/classes/BackgroundSceneController.class";

class BackgroundCameraController extends SceneItem {
  protected _camera!: FreeCamera
  private _light!: Light

  private _to = 0

  private _cameraPosition = new Vector3(0, 0, -10)

  get camera(): FreeCamera {
    return this._camera
  }

  constructor(args: SceneItemArgs) {
    super(args)
    this._createCamera()
    this._createLight()

    document.addEventListener('mousemove', this._onMouseMove)

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () => {
    // this._camera.position = this._cameraPosition.clone()
    const circlesParticles = (this._sceneController as BackgroundSceneController).circleParticles
    if (circlesParticles) {
      circlesParticles.setPosition(this._cameraPosition)
    }
  }

  private _createCamera(): void {
    const camera: FreeCamera = new FreeCamera('camera', this._cameraPosition.clone(), this.scene)
    camera.minZ = 0.00001
    camera.setTarget(Vector3.Forward())
    this._camera = camera
  }

  private _onMouseMove = (e:MouseEvent) => {
    if (this._camera) {
      this._cameraPosition.x = e.clientX * .02
      this._cameraPosition.y = e.clientY * .02
    }
  }

  private _createLight(): void {
     // this._light = new HemisphericLight('light', Vector3.Backward(), this.scene)
  }

  dispose() : void {
    document.removeEventListener('mousemove', this._onMouseMove)
  }
}

export default BackgroundCameraController
