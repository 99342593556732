import ModelController from "@/classes/SceneController/ModelController.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Mesh, PBRMaterial, TransformNode, Vector3} from "@babylonjs/core";

export enum BuildingModelControllerEvents {
  Loaded = 'Loaded'
}

export default class BuildingModelController extends ModelController {

  private _position = 0
  private _loaded = false
  private _alphaComplete = false
  private _alpha = 0

  public get loaded() : boolean {
    return this._loaded
  }

  constructor(args: SceneItemArgs) {
    super(args)

    // this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = (): void => {
    if (this._loaded && !this._alphaComplete) {
      this._alpha += (1 - this._alpha) / 500
      if (this._alpha > 0.9) {
        this._alpha = 1
        this._alphaComplete = true
      }

      this.mesh?.getChildMeshes().forEach(mesh => {
        if (mesh.material) {
          mesh.material.alpha = this._alpha
          if (this._alphaComplete) mesh.material.freeze()
        }
      })
    }
  }

  public containsMesh(mesh: Mesh) : boolean {
    let contains = false

    if (mesh.parent) {
      if (mesh.parent === this.mesh) {
        contains = true
      } else {
        contains = this.containsMesh(mesh.parent as Mesh)
      }
    }

    return contains
  }

  public enableTransparent(enable: boolean) :void {
    this.mesh?.getChildMeshes().forEach(mesh => {
        if (mesh.name.indexOf('wall') >= 0) mesh.visibility = enable ? 0.3 : 1
    })
  }

  public setPosition(position: number) : void {
    this._position = position
  }

  public loadBuilding(url: string) : void {
    this.loadModelFromUrl(url).then((assetContainer) => {
      if (this.mesh) {
        this.mesh.getChildMeshes().forEach(mesh => {
          if (mesh.material) {
            const mat: PBRMaterial = mesh.material as PBRMaterial
            // mat.alpha = this._alpha
            mat.disableLighting = true
          }
        })

        this.mesh.scaling = new Vector3(0.1, 0.1, 0.1)
        this.mesh.position = new Vector3(-this._position * 2, 0, 0)
      }

      this._loaded = true

      this.dispatchEvent(new Event(BuildingModelControllerEvents.Loaded))
    })
  }
}
