/* eslint-disable */
import * as BABYLON from "@babylonjs/core";
import {UniversalCamera, Vector3} from "@babylonjs/core";
import FreeCameraSearchInput from "@/classes/panorama/input/FreeCameraSearchInput";
import isMobile from "is-mobile";

class TourCameraController{
  /**
   * @type {TourSceneController}
   * @private
   */
  _sceneController
  _camera

  /**
   * @type TourCube
   * @private
   */
  _prevTargetCube

  /**
   * @type TourCube
   * @private
   */
  _targetCube

  /**
   * @type {number}
   * @private
   */
  _initalDistance

  _onPosition = false

  /**
   * @type {Number}
   * @private
   */
  _cameraSpeed = 0

  /**
   *
   * @type {Number}
   * @private
   */
  _toYRotation = null

  get destinated() {
    return this._onPosition
  }

  get moving() {
    return this.currentDistance > 0.1
  }

  get currentDistance() {
    let distance = this._targetCube ? Vector3.Distance(this._targetCube.position, this._camera.position) : 0
    return distance
  }

  get progress() {
    const progress = this._targetCube ? 1 - this.currentDistance / this._initalDistance : 1
    return isNaN(progress) ? 1 : progress
  }

  /**
   * @return {TourSettings}
   */
  get settings() {
    return this._sceneController.settings
  }

  /**
   *
   * @return {Scene}
   * @private
   */
  get _scene() {
    return this._sceneController.scene
  }

  /**
   *
   * @return {UniversalCamera}
   */
  get camera() {
    return this._camera
  }

  /**
   * @return {TourCube}
   */
  get cube() {
    return this._targetCube
  }

  /**
   * @return {TourCube}
   */
  get prevCube() {
    return this._prevTargetCube ? this._prevTargetCube : this._targetCube
  }

  /**
   *
   * @param sceneController {TourSceneController}
   */
  constructor({sceneController}) {
    this._sceneController = sceneController
    this._createCamera()
    // this._createArcCamera()
    this._scene.onBeforeRenderObservable.add(() => {
      this._update()
    })
  }

  rotateY (rotation) {
    this._toYRotation = rotation
  }

  cleanRotateY() {
    this._toYRotation = null
  }


  _update() {
    if (this._targetCube) {

      if (this._toYRotation !== null) {
        this._camera.rotation.y += ((this._toYRotation - this.camera.rotation.y) / 1000) * this._sceneController.engine.getDeltaTime()
        if (Math.abs(this._camera.rotation.y - this._toYRotation) < 0.01) {
          this._camera.rotation.y = this._toYRotation
          this.cleanRotateY()
        }
      }

      const speed = (this._cameraSpeed / 3) * this._sceneController.engine.getDeltaTime()

      const toPosition = this._targetCube.position
      const direction = toPosition.subtract(this.prevCube.position).normalize()

      direction.x *= speed
      direction.y *= speed
      direction.z *= speed


      const prevDistance = this.currentDistance

      if (Vector3.Distance(toPosition, this._camera.position) < speed * 2) {
        this._camera.position = toPosition.clone()
        this._onPosition = true
      } else {
        this._camera.position = this._camera.position.add(direction)

        if (this.currentDistance > prevDistance) {
          this._camera.position = toPosition
          this._cameraSpeed = 0
        } else {
          if (this.progress > 0.55) {
            this._cameraSpeed -= this.settings.cameraAcceleration
          } else {
            this._cameraSpeed += this.settings.cameraAcceleration
          }
        }
      }

      // this._camera.position = Vector3.Lerp(this._camera.position, toPosition, 0.01)
    }
  }

  _createCamera() {
    const camera = new UniversalCamera("UniversalCamera", new BABYLON.Vector3(0, 20, 0), this._scene)
    camera.fov = isMobile() ? 1.8 :  1.2
    camera.angularSensibility = 1300
    camera.touchAngularSensibility = 600
    camera.invertRotation = true
    camera.position = BABYLON.Vector3.Zero()
    camera.attachControl(this._sceneController.canvas, true)
    camera.minZ = 0

    camera.inputs.remove(camera.inputs.attached.touch)

    camera.inputs.add(new FreeCameraSearchInput())

    this._camera = camera
  }

  _createArcCamera() {

    const camera = new BABYLON.ArcRotateCamera("UniversalCamera", 0, Math.PI / 2, 7, Vector3.Zero(), this._scene)
    camera.fov = 1.2
    camera.angularSensibility = 1000
    camera.touchAngularSensibility = 600
    camera.invertRotation = true
    camera.attachControl(this._sceneController.canvas, true)
    camera.minZ = 0

    const boxMesh = BABYLON.MeshBuilder.CreateBox('box', {
      size: 0.1
    }, this._scene)

    boxMesh.position.z = 2.5

    boxMesh.material = new BABYLON.StandardMaterial('box', this._scene)
    boxMesh.material.emissiveColor = BABYLON.Color3.Red()

    // eslint-disable-next-line no-unused-vars
    const light = new BABYLON.HemisphericLight('light', Vector3.Up(), this._scene)

    this._camera = camera
  }

  /**
   *
   * @param cube {TourCube}
   */
  setCube(cube, loadAnyway = false) {
    return new Promise((resolve => {
      if (loadAnyway || cube !== this._targetCube) {
        this._onPosition = false

        cube.loadTexture().then(() => {
          if (!this._targetCube) {
            this._camera.position = cube.position.clone()
            this._onPosition = true
          }

          this._cameraSpeed = 0

          this._prevTargetCube = this._targetCube
          this._targetCube = cube

          this._initalDistance = this.currentDistance


          this._sceneController
            .tourCubes
            .filter(item => item !== this._prevTargetCube && item !== this._targetCube)
            .forEach(item => {
              item.unloadTexture()
            })

          resolve()
        })
      } else {
        resolve()
      }
    }))
  }
}

export default TourCameraController
