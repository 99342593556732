
import {Options, Vue} from "vue-class-component"
import {reactive} from 'vue'
import SiteButton from "@/components/_common/ui/SiteButton.vue"
import isMobile from "is-mobile"
import {detect} from "detect-browser";
import {any} from "@tensorflow/tfjs-backend-webgl/dist/kernels/Any";

@Options({
  components: {
    SiteButton
  }
})
export default class AugmentedViewer extends Vue {
  public state = reactive({
    mobile: isMobile(),
    tooltipVisible: false,
    qrCodeVisible: false,
    tooltipText: 'Press this button to switch to augmented reality mode'
  })

  // eslint-disable-next-line
  private modelsData: any = {
    'alpaca': {
      glb: '/projects/about-immercyb/models/alpaca/model.glb',
      usdz: '/projects/about-immercyb/models/alpaca/model.usdz',
      cameraOrbit: 'calc(0rad + env(window-scroll-y) * 4rad) calc(90deg + env(window-scroll-y) * 180deg) calc(10m - env(window-scroll-y) * 20m)',
      cameraTarget: '0m 0.1m 0m'
    },
    'immercyb-logo': {
      glb: '/projects/about-immercyb/models/ar/logo.glb',
      usdz: '/projects/about-immercyb/models/ar/logo.usdz',
      cameraOrbit: 'calc(0rad + env(window-scroll-y) * 4rad) calc(90deg + env(window-scroll-y) * 180deg) calc(10m - env(window-scroll-y) * 20m)',
      cameraTarget: '0m 0.1m 0m'
    },
    'snowman': {
      glb: '/projects/about-immercyb/models/snowman/evil_snowman.glb',
      usdz: '/projects/about-immercyb/models/snowman/Evil_Snowman.usdz',
      cameraOrbit: '0 0 10m',
      cameraTarget: '0m 1m 0m'
    },
    'horror-mask': {
      glb: '/projects/about-immercyb/models/horror-mask/horror_mask.glb',
      usdz: '/projects/about-immercyb/models/horror-mask/Horror_mask.usdz',
      cameraOrbit: '0 0 1m',
      cameraTarget: '0m 0.1m 0m'
    },
    'jetta': {
      glb: '/projects/about-immercyb/models/ar/jetta.glb',
      usdz: '/projects/about-immercyb/models/ar/jetta.usdz',
      cameraOrbit: 'calc(0rad + env(window-scroll-y) * 4rad) calc(90deg + env(window-scroll-y) * 180deg) calc(10m - env(window-scroll-y) * 20m)',
      cameraTarget: '0m 1.5m 0m'
    },
    'clothes': {
      glb: '/projects/about-immercyb/models/clothes/clothes.glb',
      usdz: '/projects/about-immercyb/models/clothes/clothes.usdz',
      cameraOrbit: 'calc(0rad + env(window-scroll-y) * 4rad) calc(90deg + env(window-scroll-y) * 180deg) calc(10m - env(window-scroll-y) * 20m)',
      cameraTarget: '130m 90m 0m'
    },
    'ny2023': {
      glb: '/projects/ny2023/models/Elka2023_13.glb',
      usdz: '/projects/ny2023/models/Elka2023_13.usdz',
      cameraOrbit: 'calc(0rad + env(window-scroll-y) * 4rad) calc(90deg + env(window-scroll-y) * 180deg) calc(10m - env(window-scroll-y) * 20m)',
      cameraTarget: '0m 1.5m 0m'
    }
    // 'ny2023': {
    //   glb: '/projects/ny2023/models/Elka2023_01.glb',
    //   usdz: '/projects/ny2023/models/Elka2023_01.usdz'
    // }
  }

  public get modelCameraTarget() : string {
    return this.modelsData[this.modelId].cameraTarget
  }

  public get modelCameraOrbit() : string {
    return this.modelsData[this.modelId].cameraOrbit
  }

  public get modelGlbUrl() : string {
    return this.modelsData[this.modelId].glb
  }

  public get modelIosUrl() : string {
    return this.modelsData[this.modelId].usdz
  }

  private get modelId() : string {
    return this.$route.params.modelId as string
  }

  mounted() : void {
    const browserInfo = detect()

    // alert(window.navigator.userAgent)
    const modelViewer = document.querySelector('model-viewer') as any
    modelViewer.exposure = 0.6;
    // modelViewer.environmentImage = 'legacy'

    if (isMobile()) {
      if (browserInfo?.os === 'iOS' && window.navigator.userAgent.toLowerCase().indexOf('safari') < 0) {
        this.state.tooltipText = 'Press this button to switch to augmented reality mode, using Safari browser'
      } else if (window.navigator.userAgent.toLowerCase().indexOf('yasearch') >= 0 || window.navigator.userAgent.toLowerCase().indexOf('yabrowser') >= 0) {
        if (browserInfo?.os === 'iOS') {
          this.state.tooltipText = 'Press this button to switch to augmented reality mode, using Safari browser'
        } else {
          this.state.tooltipText = 'Press this button to switch to augmented reality mode, using Chrome browser'
        }
      } else if (window.navigator.userAgent.toLowerCase().indexOf('firefox') >= 0) {
        if (browserInfo?.os === 'iOS') {
          this.state.tooltipText = 'Press this button to switch to augmented reality mode, using Safari browser'
        } else {
          this.state.tooltipText = 'Press this button to switch to augmented reality mode, using Chrome browser'
        }
      } else if (window.navigator.userAgent.toLowerCase().indexOf('opr/') >= 0 || window.navigator.userAgent.toLowerCase().indexOf('opios/') >= 0) {
        if (browserInfo?.os === 'iOS') {
          this.state.tooltipText = 'Press this button to switch to augmented reality mode, using Safari browser'
        } else {
          this.state.tooltipText = 'Press this button to switch to augmented reality mode, using Chrome browser'
        }
      }
    }

    if (!this.modelId) {
      this.$router.replace('/')
    }

    if (this.modelId === 'snowman') {
      modelViewer.shadowIntensity = 1
      modelViewer.exposure = 1.5
      modelViewer.shadowSoftness = 0.1
    }

    document.addEventListener('click', this.onDocumentClick)

    setTimeout(() => {
      this.state.tooltipVisible = isMobile()
    }, 3000)
  }

  unmounted() : void {
    document.removeEventListener('click', this.onDocumentClick)
  }

  public onBackButtonClick() : void {
    this.$router.replace('/')
  }

  public onArButtonClick() : void {
    this.state.qrCodeVisible = !this.state.qrCodeVisible
  }

  private onDocumentClick = (e: MouseEvent) => {
    if (this.state.qrCodeVisible && (e.target as HTMLElement).className !== 'site-button ar-button') {
      this.state.qrCodeVisible = false
    }
  }
}
