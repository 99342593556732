import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vr-man" }
const _hoisted_2 = { class: "canvas-container" }
const _hoisted_3 = { ref: "canvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lights_settings = _resolveComponent("lights-settings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512)
    ]),
    (_ctx.sceneInited)
      ? (_openBlock(), _createBlock(_component_lights_settings, {
          key: 0,
          positions: _ctx.lightsProps.positions,
          targets: _ctx.lightsProps.targets,
          colors: _ctx.lightsProps.colors,
          forces: _ctx.lightsProps.forces,
          specularForces: _ctx.lightsProps.specularForces,
          shininess: _ctx.lightsProps.shininess
        }, null, 8, ["positions", "targets", "colors", "forces", "specularForces", "shininess"]))
      : _createCommentVNode("", true)
  ]))
}