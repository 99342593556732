import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slides-wrapper" }
const _hoisted_2 = { class: "slide-item left" }
const _hoisted_3 = { class: "slide-item right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_words_slider = _resolveComponent("words-slider")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slide slider", {visible: _ctx.position==='center', top: _ctx.position === 'top', bottom: _ctx.position === 'bottom'}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_words_slider, { direction: -1 })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_words_slider, { direction: 1 })
      ])
    ])
  ], 2))
}