
import { reactive } from "vue";
import {Options, Vue} from "vue-class-component";
import SlideButton from "@/components/Landing/ui/SlideButton.vue";
import ProjectImage from "@/components/Landing/MainMenu/ProjectsMenuContent/ProjectImage.vue";
import isMobile from "is-mobile";

export interface Project {
  id: string,
  title: string,
  texture: string,
  url: string
}

@Options({
  components: {
    SlideButton,
    ProjectImage
  },
})
export default class ProjectsMenuContent extends Vue {
  public state = reactive({
    textureIndex: 0,
    isMobile: isMobile(),
    contentMarginTop: 0,
    toContentMarginTop: 0,
    mounted: false
  })

  public projectsList: Project[] = [
    {
      id: 'jetta',
      title: '3D-Showroom for VW',
      texture: '/landing/image-effects/textures/jetta.jpg',
      url: '/jetta'
    },
    {
      id: 'tour',
      title: '3D-Tour Engine',
      texture: '/landing/image-effects/textures/tour.jpg',
      url: '/tour'
    },
    {
      id: 'kraus',
      title: 'Kraus Blocks Constructor',
      texture: '/landing/image-effects/textures/kraus.jpg',
      url: '/kraus'
    },
    {
      id: 'lg-arle',
      title: 'LG Signature WebAR',
      texture: '/landing/image-effects/textures/lg.jpg',
      url: '/lg-arle'
    },
    {
      id: 'ar-logo',
      title: 'Immercyb Logo WebAR',
      texture: '/landing/image-effects/textures/ar-logo.jpg',
      url: '/ar-logo'
    }
  ]

  get textures() : string[] {
    const list = ['/landing/image-effects/textures/tour.png', ...this.projectsList.map((project) => project.texture)]
    return list
  }

  public onBackButtonClick() : void {
    //this.$emit('backClick')
    this.$router.push('/menu')
  }

  public onButtonMouseEnter(index: number) : void {
    this.state.textureIndex = index
  }

  public onButtonsListMouseLeave() : void {
    this.state.textureIndex = 0
  }

  public onProjectClick(project: Project) : void {
    this.$router.push(project.url)
    this.$emit('project', project.id)
  }
}
