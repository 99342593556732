
import {Vue} from "vue-class-component";
import WaterWorldSceneController from "@/components/WaterWorld/classes/WaterWorldSceneController.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class WaterWorld extends Vue {
  private _sceneController!: WaterWorldSceneController

  public mounted() : void {
    this._createSceneController()
  }

  private _createSceneController() : void {
    this._sceneController = new WaterWorldSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    EventBus.emit(EventBusEvents.SceneLoaded)
  }
}
