import {Sound, Vector3} from "@babylonjs/core"
import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import {PictureAnimations} from "@/components/LgVangogh/classes/PictureModel.class";

export default class SoundPlayer extends SceneItem {
  private _soundInstance!: Sound
  private _soundPosition!: Vector3

  private _animationsList: PictureAnimations[] = [
    PictureAnimations.Start,
    PictureAnimations.Frames,
    PictureAnimations.Letter,
    PictureAnimations.Details,
    PictureAnimations.Smears,
    PictureAnimations.Final,
  ]

  constructor(props:SceneItemArgs) {
    super(props)
    // const url = '/sounds/sound-1.mp3'
    // const xhr:XMLHttpRequest = new XMLHttpRequest()
    // xhr.open('get', url)
    // xhr.send()
  }

  private _getAnimationIndex(animation: PictureAnimations) {
    return this._animationsList.indexOf(animation)
  }

  public play(position: Vector3 | null, animation : PictureAnimations | null = null) : Promise<void> {
    if (this._soundInstance) {
      this._soundInstance.stop()
    }

    return new Promise<void>((resolve) => {
      const index = this._getAnimationIndex(animation!)
      this._soundInstance = new Sound(
        "theme sound",
        `/projects/lg-vangogh/sounds/frame_${index}.mp3`,
        this.scene,
        () => {
          resolve()
          this._soundInstance.play()
        }, {
          loop: false,
          autoplay: false,
          // spatialSound: true,
          // maxDistance: 20
        })

      if (position) {
        this._soundPosition = position
        this._soundInstance.setPosition(position)
      }
    })
  }

  // public setPosition(position: Vector3) : void {
  //   //this._soundInstance.setPosition(position)
  // }
  public dispose() : void {
    if (this._soundInstance) this._soundInstance.stop()
  }
}
