import SceneController from "../../../classes/SceneController.class"
import JettaModelController, {JettaModelControllerEvents} from "@/components/JettaProject/classes/JettaModelController.class";
import EventBusEvents from "@/classes/EventBusEvents"
import EventBus from '@/classes/EventBus.class'
import {
  ArcRotateCamera,
  Color3,
  Color4,
  CubeTexture,
  DefaultRenderingPipeline,
  Scene,
  SpotLight,
  Vector3
} from "@babylonjs/core";
import isMobile from "is-mobile";

export default class JettaSceneContoller extends SceneController{
  private _modelController: JettaModelController

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})

    this.createCameraController()
    this._updateSceneSettings()
    this._createVignette()

    const hdrTexture: CubeTexture = CubeTexture.CreateFromPrefilteredData("/projects/about-immercyb/env/autoshop.env", this.scene)
    this.scene.environmentTexture = hdrTexture

    this._modelController = new JettaModelController({
      sceneController: this
    })

    this.scene.onBeforeRenderObservable.add(() => {
      this._update()
    })

    this._modelController.addEventListener(JettaModelControllerEvents.ModelLoaded, this._onModelLoaded)
  }

  private _update() : void {
    if (this._modelController.modelLoaded) {
      this.cameraController.camera.fov += (Math.PI * 0.3 - this.cameraController.camera.fov) / 50
    }
  }

  private _onModelLoaded = () : void => {
    EventBus.emit(EventBusEvents.SceneLoaded)
    this._updateCameraSettings()
  }

  private _updateSceneSettings() : void {
    this.scene.clearColor = new Color4(0, 0, 0, 1)
    this.scene.ambientColor = Color3.Black()
    this.scene.environmentIntensity = 1

    this.scene.fogEnabled = true
    this.scene.fogColor = Color3.Black()
    this.scene.fogStart = 15
    this.scene.fogEnd = 25
    this.scene.fogMode = Scene.FOGMODE_LINEAR

    const upLight: SpotLight = new SpotLight(
      'up_light',
      new Vector3(0, 20,0),
      new Vector3(0, -1, 0),
      Math.PI / 2,
      1.0,
      this.scene)

    upLight.intensity = 300
    upLight.diffuse = new Color3(1,1, 1)
    this.scene.addLight(upLight)
  }

  private _updateCameraSettings() : void {
    const camera: ArcRotateCamera = this.cameraController.camera
    camera.alpha = Math.PI / 10
    camera.beta =  Math.PI / 2.2
    camera.radius = 5
    camera.target = new Vector3(0, 0.6, 0)
    camera.useAutoRotationBehavior = true
    camera.wheelPrecision = isMobile() ? 1000 : 100
    camera.lowerRadiusLimit = 5
    camera.upperRadiusLimit = 5
    camera.lowerBetaLimit = Math.PI / 5
    camera.upperBetaLimit = Math.PI / 2
    camera.position = new Vector3(0, 1.8, 6.5)
    camera.fov = Math.PI * 0.9

    if (camera.autoRotationBehavior) camera.autoRotationBehavior.idleRotationSpeed = 0.2
  }

  private _createVignette() : void {
    // const postProcess = new ImageProcessingPostProcess("processing", 1.0, this.cameraController.camera);
    // postProcess.vignetteWeight = 3
    // postProcess.vignetteStretch = 10
    // postProcess.vignetteColor = new Color4(0, 0, 0, 1)
    // postProcess.vignetteEnabled = true
    // postProcess.exposure = 1.5

    const defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, [this.cameraController.camera])
    defaultPipeline.samples = 4

    defaultPipeline.grainEnabled = true
    defaultPipeline.grain.intensity = 5
    defaultPipeline.grain.animated = true

    defaultPipeline.bloomEnabled = true
    defaultPipeline.fxaaEnabled = true
    defaultPipeline.bloomWeight = 0.2

    defaultPipeline.imageProcessingEnabled = true
    defaultPipeline.imageProcessing.vignetteWeight = 2
    defaultPipeline.imageProcessing.vignetteStretch = 5
    defaultPipeline.imageProcessing.vignetteColor = new Color4(0, 0, 0, 1)
    defaultPipeline.imageProcessing.vignetteEnabled = true
    defaultPipeline.imageProcessing.exposure = 0.6
    defaultPipeline.imageProcessing.contrast = 0.97

    // defaultPipeline.cameraFov = this.cameraController.camera.fov
  }

  public dispose() : void {
    super.dispose()
    this._modelController.dispose()
  }
}
