import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Color3, Color4, FreeCamera, Mesh, ShaderMaterial, Vector3} from "@babylonjs/core";
import ModelController from "@/classes/SceneController/ModelController.class";
import VectorEasing from "@/classes/Easing/VectorEasing.class";
import BlobedPostProcess from "@/components/Landing/Background/classes/postprocess/BlobedPostProcess.class";
import BackgroundSceneController from "@/components/Landing/Background/classes/BackgroundSceneController.class";
import {PBRCustomMaterial} from "@babylonjs/materials";

export default class BaseBlob extends ModelController {
  private _lightsPositions: Vector3[] = []
  private _lightsColors: Color4[] = []
  private _lightsTargets: Vector3[] = []
  private _lightsForces: number[] = []
  private _lightsSpecularForces: number[] = []
  private _lightsShininess: number[] = []

  protected _mesh!:Mesh

  protected _materialFront!: ShaderMaterial
  protected _materialBack!: ShaderMaterial

  protected _customFrontMaterial!:PBRCustomMaterial
  protected _customBackMaterial!:PBRCustomMaterial

  protected _meshFront!: Mesh
  protected _meshBack!: Mesh

  protected _linesCount = 100

  protected _speed = .1
  protected _radius = .5
  protected _toRadius = .5
  protected _lineThickness = 0.25

  protected _time = 0

  protected _visible = false

  protected _toPosition: Vector3 = Vector3.Zero()
  protected _position: Vector3 = Vector3.Zero()

  protected _rotationOffset: Vector3 = Vector3.Zero()
  protected _toRotation: Vector3 = Vector3.Zero()
  protected _rotation: Vector3 = Vector3.Zero()

  protected _toScaling: Vector3 = Vector3.Zero()
  protected _scaling: Vector3 = Vector3.Zero()

  protected _easeTimes = {
    position: {
      time: 0,
      duration: 1
    },
    rotation: {
      time: 0,
      duration: 1
    },
    scaling: {
      time: 0,
      duration: 1
    }
  }

  protected _easeVectors = {
    position: Vector3.Zero(),
    rotation: Vector3.Zero(),
    scaling: Vector3.Zero()
  }

  get easePositionTime() : number {
    const time: number = ((this._time - this._easeTimes.position.time) / this._easeTimes.position.duration ) / 1000
    return time < 1 ? time : 1
  }

  get easeRotationTime() : number {
    const time: number = ((this._time - this._easeTimes.rotation.time) / this._easeTimes.rotation.duration) / 1000
    return time < 1 ? time : 1
  }

  get easeScalingTime() : number {
    const time: number = ((this._time - this._easeTimes.scaling.time) / this._easeTimes.scaling.duration) / 1000
    return time < 1 ? time : 1
  }

  get blobedPostProcess() : BlobedPostProcess {
    return (this.sceneController as BackgroundSceneController).blobedPostProcess
  }

  constructor(props: SceneItemArgs) {
    super(props)

    this.scene.onBeforeRenderObservable.add(this._update.bind(this))

    document.addEventListener('mousemove', this._onMouseMove.bind(this))
    document.addEventListener('touchmove', this._onTouchMove.bind(this))
  }

  protected _update(){
    this._time += this.sceneController.engine.getDeltaTime()
    const camera: FreeCamera = this.scene.activeCamera as FreeCamera

    if (this._materialFront) {
      // this._materialFront.setInt('lightsCount', 2)
      this._materialFront.setFloat('time', this._time)
      this._materialFront.setFloat('speed', this._speed)
      this._materialFront.setFloat('radius', this._radius)
      this._materialFront.setFloat('linesCount', this._linesCount)
      this._materialFront.setFloat('lineThickness', this._lineThickness)
      this._materialFront.setVector3('cameraPosition', this.scene.activeCamera!.position)
    }

    if (this._materialBack) {
      this._materialBack.setFloat('time', this._time)
      this._materialBack.setFloat('speed', this._speed)
      this._materialBack.setFloat('radius', this._radius)
      this._materialBack.setFloat('linesCount', this._linesCount)
      this._materialBack.setFloat('lineThickness', this._lineThickness)
    }

    if (this._meshFront) {
      this._radius += (this._toRadius - this._radius) / 100

      this._rotationOffset = VectorEasing.cubicInOut(this._easeVectors.rotation, this._toRotation, this.easeRotationTime)

      this._meshFront.rotation = this._rotation.multiplyByFloats(0.3, 0.3, 0.3).add(this._rotationOffset)
      this._meshBack.rotation = this._meshFront.rotation

      this._position = VectorEasing.cubicInOut(this._easeVectors.position, this._toPosition, this.easePositionTime)

      this._meshFront.position = camera.position.add(camera.getDirection(new Vector3(0, 0, 2.2))).add(this._position)
      this._meshBack.position = this._meshFront.position

      this._scaling = VectorEasing.cubicInOut(this._easeVectors.scaling, this._toScaling, this.easeScalingTime)

      this._meshFront.scaling = this._scaling
      this._meshBack.scaling = this._meshFront.scaling
    }
  }

  protected _createCustomPbr() {
    this._customFrontMaterial = new PBRCustomMaterial('front custom', this.scene)
    this._customFrontMaterial.backFaceCulling = false

    this._customFrontMaterial.albedoColor = Color3.FromHexString('#ffffff')
    this._customFrontMaterial.roughness = 0.09
    this._customFrontMaterial.metallic = 1
    this._customFrontMaterial.environmentIntensity = 1.4
    this._customFrontMaterial.enableSpecularAntiAliasing = true
    this._customFrontMaterial.useLogarithmicDepth = true

    this._customFrontMaterial.AddUniform('time', 'float', 0)
    this._customFrontMaterial.AddUniform('speed', 'float', 0)
    this._customFrontMaterial.AddUniform('radius', 'float', 0)
    this._customFrontMaterial.AddUniform('linesCount', 'float', 0)
    this._customFrontMaterial.AddUniform('lineThickness', 'float', 0)

    this._customFrontMaterial.Vertex_Begin('// Varying\n' +
      'attribute vec2 uv;\n' +
      'varying vec4 vPosition;\n' +
      'varying vec3 oPosition;\n' +
      'varying vec3 vNormal;\n' +
      'varying vec2 vUV;')

    this._customFrontMaterial.Vertex_Definitions('float random (in vec2 st) {\n' +
      '    return fract(sin(dot(st.xy, vec2(12.9898,78.233)))*43758.5453123);\n' +
      '}\n' +
      '\n' +
      '// Based on Morgan McGuire @morgan3d\n' +
      '// https://www.shadertoy.com/view/4dS3Wd\n' +
      'float noise (in vec2 st) {\n' +
      '    vec2 i = floor(st);\n' +
      '    vec2 f = fract(st);\n' +
      '\n' +
      '    // Four corners in 2D of a tile\n' +
      '    float a = random(i);\n' +
      '    float b = random(i + vec2(1.0, 0.0));\n' +
      '    float c = random(i + vec2(0.0, 1.0));\n' +
      '    float d = random(i + vec2(1.0, 1.0));\n' +
      '\n' +
      '    vec2 u = f * f * (3.0 - 2.0 * f);\n' +
      '\n' +
      '    return mix(a, b, u.x) +\n' +
      '            (c - a)* u.y * (1.0 - u.x) +\n' +
      '            (d - b) * u.x * u.y;\n' +
      '}\n' +
      '\n' +
      '#define OCTAVES 1\n' +
      'float fbm (in vec2 st) {\n' +
      '    // Initial values\n' +
      '    float value = 0.0;\n' +
      '    float amplitude = .5;\n' +
      '    float frequency = 0.;\n' +
      '    //\n' +
      '    // Loop of octaves\n' +
      '    for (int i = 0; i < OCTAVES; i++) {\n' +
      '        value += amplitude * noise(st);\n' +
      '        st *= 2.;\n' +
      '        amplitude *= .5;\n' +
      '    }\n' +
      '    return value;\n' +
      '}')

    this._customFrontMaterial.Vertex_Before_PositionUpdated('float PI = 3.14;\n' +
      '    float t = time * (speed * 0.01);\n' +
      '    float noise = fbm(vec2(position.x + t, position.y + position.z));\n' +
      '\n' +
      '    float r = (radius) * 0.1;\n' +
      '    float distortion = sin(position.y * 10. + t + 1.) * r;\n' +
      '\n' +
      '    distortion += cos(noise + (position.x + position.y) * 10. - t + cos(position.z)) * r;\n' +
      '    distortion += cos(noise + (position.z + position.y) * 10. + t + cos(position.y)) * r;\n' +
      '    distortion += sin(noise + position.x * 10. + t + 2.) * r;\n' +
      '    distortion -= sin(noise + position.z * 10. - t + position.x + 5.) * r;\n' +
      '    distortion += cos(position.z * 10. + t + 7.) * r;\n' +
      '\n' +
      '    positionUpdated = position + normal * distortion;\n' +
      '\n' +
      '    vPosition = vec4( positionUpdated, 1. );\n' +
      '    vUV = uv;\n')

    this._customFrontMaterial.Fragment_Definitions('varying vec4 vPosition;\n' +
      'varying vec3 vNormal;\n' +
      'varying vec2 vUV;')
    this._customFrontMaterial.Fragment_Custom_Alpha('vec2 puv = vec2(vUV.x , vUV.y + vPosition.y);\n' +
      '    vec2 suv = fract(puv * linesCount);\n' +
      '\n' +
      `    alpha = suv.y < lineThickness ? 1. : 0.;`)

    this._customFrontMaterial.onBindObservable.add(() => {
      const effect = this._customFrontMaterial.getEffect()
      effect.setFloat('time', this._time)
      effect.setFloat('radius', this._radius)
      effect.setFloat('speed', this._speed)
      effect.setFloat('linesCount', this._linesCount)
      effect.setFloat('lineThickness', this._lineThickness)
    })
  }

  protected _createStaticCustomPbr() {
    this._customFrontMaterial = new PBRCustomMaterial('front custom', this.scene)
    this._customFrontMaterial.backFaceCulling = false

    this._customFrontMaterial.albedoColor = Color3.FromHexString('#ffffff')
    this._customFrontMaterial.roughness = 0.09
    this._customFrontMaterial.metallic = 1
    this._customFrontMaterial.environmentIntensity = 1.4
    this._customFrontMaterial.enableSpecularAntiAliasing = true
    this._customFrontMaterial.useLogarithmicDepth = true

    this._customFrontMaterial.AddUniform('time', 'float', 0)
    this._customFrontMaterial.AddUniform('speed', 'float', 0)
    this._customFrontMaterial.AddUniform('radius', 'float', 0)
    this._customFrontMaterial.AddUniform('linesCount', 'float', 0)
    this._customFrontMaterial.AddUniform('lineThickness', 'float', 0)

    this._customFrontMaterial.Vertex_Begin('// Varying\n' +
      'attribute vec2 uv;\n' +
      'varying vec4 vPosition;\n' +
      'varying vec3 oPosition;\n' +
      'varying vec3 vNormal;\n' +
      'varying vec2 vUV;')

    this._customFrontMaterial.Vertex_Definitions('float random (in vec2 st) {\n' +
      '    return fract(sin(dot(st.xy, vec2(12.9898,78.233)))*43758.5453123);\n' +
      '}\n' +
      '\n' +
      '// Based on Morgan McGuire @morgan3d\n' +
      '// https://www.shadertoy.com/view/4dS3Wd\n' +
      'float noise (in vec2 st) {\n' +
      '    vec2 i = floor(st);\n' +
      '    vec2 f = fract(st);\n' +
      '\n' +
      '    // Four corners in 2D of a tile\n' +
      '    float a = random(i);\n' +
      '    float b = random(i + vec2(1.0, 0.0));\n' +
      '    float c = random(i + vec2(0.0, 1.0));\n' +
      '    float d = random(i + vec2(1.0, 1.0));\n' +
      '\n' +
      '    vec2 u = f * f * (3.0 - 2.0 * f);\n' +
      '\n' +
      '    return mix(a, b, u.x) +\n' +
      '            (c - a)* u.y * (1.0 - u.x) +\n' +
      '            (d - b) * u.x * u.y;\n' +
      '}\n' +
      '\n' +
      '#define OCTAVES 1\n' +
      'float fbm (in vec2 st) {\n' +
      '    // Initial values\n' +
      '    float value = 0.0;\n' +
      '    float amplitude = .5;\n' +
      '    float frequency = 0.;\n' +
      '    //\n' +
      '    // Loop of octaves\n' +
      '    for (int i = 0; i < OCTAVES; i++) {\n' +
      '        value += amplitude * noise(st);\n' +
      '        st *= 2.;\n' +
      '        amplitude *= .5;\n' +
      '    }\n' +
      '    return value;\n' +
      '}')

    this._customFrontMaterial.Vertex_Before_PositionUpdated('' +
      '    vPosition = vec4( positionUpdated, 1. );\n' +
      '    vUV = uv;\n')

    this._customFrontMaterial.Fragment_Definitions('varying vec4 vPosition;\n' +
      'varying vec3 vNormal;\n' +
      'varying vec2 vUV;')
    this._customFrontMaterial.Fragment_Custom_Alpha('vec2 puv = vec2(0, vPosition.y + speed * time);\n' +
      '    vec2 suv = fract(puv * linesCount);\n' +
      '\n' +
      `    alpha = suv.y < lineThickness ? 1. : 0.;`)

    this._customFrontMaterial.onBindObservable.add(() => {
      const effect = this._customFrontMaterial.getEffect()
      effect.setFloat('time', this._time)
      effect.setFloat('radius', this._radius)
      effect.setFloat('speed', this._speed)
      effect.setFloat('linesCount', this._linesCount)
      effect.setFloat('lineThickness', this._lineThickness)
    })
  }

  protected _onMouseMove(e:MouseEvent) : void {
    if (this._meshFront && this._meshBack) {
      // const xDelta = e.movementX * 0.001
      // const yDelta = e.movementY * 0.001
      this._rotation.x = e.clientY * 0.001
      this._rotation.y = e.clientX * 0.001
    }
  }

  protected _onTouchMove(e:TouchEvent) : void {
    if (e.touches.length > 0) {
      const touch = e.touches[0]
      //this._rotation.x = touch.clientY * 0.01
      this._rotation.y = touch.clientX * 0.01
    }
  }

  protected _createLights() : void {
    this._lightsPositions.push(new Vector3(3, 0, 0))
    this._lightsPositions.push(new Vector3(-2, 3, -4))
    this._lightsPositions.push(new Vector3(-3, 4, 3))
    this._lightsPositions.push(new Vector3(0., 1., -0.))
    //this._lightsPositions.push(new Vector3(-10., 10, -0.))

    this._lightsColors.push(Color4.FromHexString('#ac15ffFF'))
    this._lightsColors.push(Color4.FromHexString('#00fff0FF'))
    this._lightsColors.push(Color4.FromHexString('#2300ffFF'))
    this._lightsColors.push(new Color4(0, 0, 0, 0.))

    this._lightsTargets.push(new Vector3(0, -0.5, 0.5))
    this._lightsTargets.push(new Vector3(1, 3, 3))
    this._lightsTargets.push(new Vector3(2, 1, 0.5))
    this._lightsTargets.push(new Vector3(0, 11, 0))

    this._lightsForces.push(0.2)
    this._lightsForces.push(0.4)
    this._lightsForces.push(0.3)
    this._lightsForces.push(0)

    this._lightsSpecularForces.push(0.4)
    this._lightsSpecularForces.push(0.3)
    this._lightsSpecularForces.push(0.05)
    this._lightsSpecularForces.push(0)

    this._lightsShininess.push(20)
    this._lightsShininess.push(60)
    this._lightsShininess.push(5)
    this._lightsShininess.push(1)

    this._applyLightsOnMaterial()
  }

  protected _applyLightsOnMaterial() : void {
    const lightsPositions: number[] = []
    this._lightsPositions.forEach((position) => {
      lightsPositions.push(position.x)
      lightsPositions.push(position.y)
      lightsPositions.push(position.z)
    })

    const lightsTargets: number[] = []
    this._lightsTargets.forEach((position) => {
      lightsTargets.push(position.x)
      lightsTargets.push(position.y)
      lightsTargets.push(position.z)
    })

    const lightsColors: number[] = []
    this._lightsColors.forEach((color) => {
      lightsColors.push(color.r)
      lightsColors.push(color.g)
      lightsColors.push(color.b)
      lightsColors.push(color.a)
    })

    this._materialFront.setArray3('lightsPositions', lightsPositions)
    this._materialFront.setArray3('lightsTargets', lightsTargets)
    this._materialFront.setArray4('lightsColors', lightsColors)
    this._materialFront.setFloats('lightsForces', this._lightsForces)
    this._materialFront.setFloats('lightsSpecularForces', this._lightsSpecularForces)
    this._materialFront.setFloats('lightsShininess', this._lightsShininess)

    this._materialFront.setInt('lightsCount', this._lightsPositions.length)

    // this._materialFront.setVector3('cameraPosition', this.camera.position)
  }

  public rotateTo(rotation: Vector3, duration = 1) : void {
    this._easeTimes.rotation.time = this._time
    this._easeTimes.rotation.duration = duration
    this._easeVectors.rotation = this._rotationOffset.clone()
    this._toRotation = rotation
  }

  public positionTo(position: Vector3, duration = 1) : void {
    this._easeTimes.position.time = this._time
    this._easeTimes.position.duration = duration

    this._easeVectors.position = this._position.clone()
    this._toPosition = position
  }

  public scaleTo(scaling: Vector3, duration = 1) : void {
    this._easeTimes.scaling.time = this._time
    this._easeTimes.scaling.duration = duration

    this._easeVectors.scaling = this._scaling.clone()
    this._toScaling = scaling
  }

  public dispose() : void {
    //dispose
  }
}
