
import {reactive} from "vue";
import {Options, prop, Vue} from "vue-class-component";
import {SceneState} from "@/components/Landing/Background/classes/BackgroundSceneController.class";

class BackgroundTextProps {
  sceneState = prop({
    type: Number
  })
}

@Options({
  watch: {
    sceneState(sceneState) {
      this.setNewSceneState(sceneState)
    }
  }
})
export default class BackgroundText extends Vue.with(BackgroundTextProps) {
  public state = reactive({
    currentText: '',
    nextText: '',
    hidden: true,
    right: true,
    textStyle: {}
  })

  mounted() {
    //this.state.currentText = this.text
    this.setNewSceneState(this.sceneState as SceneState)
  }

  public setNewSceneState(sceneState: SceneState | null) {

    let newText = ''

    switch (sceneState) {
      case SceneState.Web3d:
        newText = 'Web3D'
        break

      case SceneState.VR:
        newText = 'VR'
        break

      case SceneState.AR1:
      case SceneState.AR2:
        newText = 'AR'
        break

      default:
        newText = ''
        break
    }

    this.state.hidden = true
    this.state.nextText = newText

    setTimeout(() => {
      this.state.currentText = this.state.nextText
      this.state.right = true
      this.state.hidden = false

      this.udpateStyle()

      setTimeout(() => {
        this.state.right = false
      }, 500)

    }, 1000)
  }

  public udpateStyle() {
    switch (this.sceneState) {
      case SceneState.Web3d:
        this.state.textStyle = {
          bottom: '28px',
          left: '40px'
        }
        break;

      case SceneState.AR1:
      case SceneState.AR2:
        this.state.textStyle = {
          top: '20%',
          left: '160px'
        }
        break;

      case SceneState.VR:
        this.state.textStyle = {
          top: '20%',
          left: '160px'
        }
        break;
    }
  }
}
