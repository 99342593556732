import ModelController from "@/classes/SceneController/ModelController.class"
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import {
  Color3,
  Color4,
  Mesh,
  MeshBuilder,
  PointLight,
  ShaderMaterial,
  StandardMaterial, Texture,
  Vector3
} from "@babylonjs/core";
import {Shaders} from "@/classes/ShaderCreator/shaders";
import ShadersCreator from "@/classes/ShaderCreator/ShadersCreator.class";
import {LightsProps} from "@/components/VrMan/classes/VrManSceneController.class";
import { reactive } from "vue";

export default class VrManModelController extends ModelController {

  private _modelUrl = '/projects/vr-man/man.glb'
  private _time = 0

  private _material!: ShaderMaterial
  private _lightsPositions: Vector3[] = []
  private _lightsColors: Color4[] = []
  private _lightsTargets: Vector3[] = []
  private _lightsForces: number[] = []
  private _lightsSpecularForces: number[] = []
  private _lightsShininess: number[] = []
  private _lightSourcesBoxes: Mesh[] = []

  private _lightsProps = reactive({
    positions: [],
    colors: [],
    targets: [],
    forces: [],
    specularForces: [],
    shininess: []
  })

  public get lightsProps() : LightsProps {
    return this._lightsProps
  }

  constructor(args: SceneItemArgs) {
    super(args)

    this._lightsProps.colors.forEach(color => {
      console.log(color)
    })

    ShadersCreator.CreateShaders()
    this._createLights()
    //this._createTestSphere()
    this._loadModel()
  }

  private _createLights() : void {
    this._lightsPositions.push(new Vector3(3, 0, 0))
    this._lightsPositions.push(new Vector3(-2, 3, -4))
    this._lightsPositions.push(new Vector3(-3, 4, 3))
    this._lightsPositions.push(new Vector3(0., 1., -0.))
    //this._lightsPositions.push(new Vector3(-10., 10, -0.))

    this._lightsColors.push(Color4.FromHexString('#ac15ffFF'))
    this._lightsColors.push(Color4.FromHexString('#00fff0FF'))
    this._lightsColors.push(Color4.FromHexString('#2300ffFF'))
    this._lightsColors.push(new Color4(0, 0, 0, 0.))

    this._lightsTargets.push(new Vector3(0, -0.5, 0.5))
    this._lightsTargets.push(new Vector3(1, 3, 3))
    this._lightsTargets.push(new Vector3(2, 1, 0.5))
    this._lightsTargets.push(new Vector3(0, 11, 0))

    this._lightsForces.push(0.2)
    this._lightsForces.push(0.4)
    this._lightsForces.push(0.3)
    this._lightsForces.push(0)

    this._lightsSpecularForces.push(0.4)
    this._lightsSpecularForces.push(0.3)
    this._lightsSpecularForces.push(0.05)
    this._lightsSpecularForces.push(0)

    this._lightsShininess.push(20)
    this._lightsShininess.push(60)
    this._lightsShininess.push(5)
    this._lightsShininess.push(1)

    // this._lightsPositions.forEach((position, index) => {
    //   const lightSourceBox = MeshBuilder.CreateBox('light source',{
    //     width: 2,
    //     height: 2,
    //     depth: 0.5
    //   }, this.scene)
    //
    //   const material: StandardMaterial = new StandardMaterial('light source material', this.scene)
    //   material.diffuseColor = new Color3(this._lightsColors[index].r, this._lightsColors[index].g, this._lightsColors[index].b)
    //
    //   lightSourceBox.material = material
    //   lightSourceBox.position = position
    //   lightSourceBox.lookAt(this._lightsTargets[index])
    //
    //   this._lightSourcesBoxes.push(lightSourceBox)
    // })

    this._updateLightsProps()
  }

  private _updateLightsProps() : void {
    this.lightsProps.positions = this._lightsPositions
    this.lightsProps.colors = this._lightsColors.map((color) => color.toHexString())
    this.lightsProps.targets = this._lightsTargets
    this.lightsProps.forces = this._lightsForces
    this.lightsProps.specularForces = this._lightsSpecularForces
    this.lightsProps.shininess = this._lightsShininess
  }

  private _createTestSphere() : void {
    const sphere = MeshBuilder.CreateSphere('test', {
      diameter: 3,
      segments: 1000
    }, this.scene)

    // const box = MeshBuilder.CreateBox('box', {
    //   size: 1
    // }, this.scene)
    // box.position.y = 4
    // box.position.z = 0
    //
    this.mesh = sphere
    this._createMaterial()

    this.mesh.material = this._material
    this.sceneController.scene.onBeforeRenderObservable.add(this._update)
  }

  private _loadModel() : void {
    this.loadModelFromUrl(this._modelUrl).then(() => {
      this.mesh!.scaling = new Vector3(.03, .03, .03)
      // this.mesh!.position.y = -1

      // this.mesh!.rotation.x = -Math.PI / 2
      this._createMaterial()
      this.sceneController.scene.onBeforeRenderObservable.add(this._update)
    })
  }

  private _update = () => {
    this._time += 0.03
    this._material.setFloat('time', this._time)
    this._material.setVector3('cameraPosition', this.camera.position)

    // this._lightsPositions[3].x = Math.sin(this._time / (1 + 1) + 1 * 2) * 7;
    // this._lightsPositions[3].z = Math.cos(this._time / (1 + 1) + 1 * 2) * 7;

    // this._lightsPositions.forEach((position, index) => {
    //   this._lightsColors[index] = Color4.FromHexString(this._lightsProps.colors[index])
    //
    //   this._lightSourcesBoxes[index].position = position
    //   this._lightSourcesBoxes[index].lookAt(this._lightsTargets[index])
    //   const mat = (this._lightSourcesBoxes[index].material as StandardMaterial)
    //   mat.diffuseColor = new Color3(this._lightsColors[index].r, this._lightsColors[index].g, this._lightsColors[index].b)
    // })

    this._applyLightsOnMaterial()
    // this.mesh!.rotation.y += 0.01
  }

  private _applyLightsOnMaterial() : void {
    const lightsPositions: number[] = []
    this._lightsPositions.forEach((position) => {
      lightsPositions.push(position.x)
      lightsPositions.push(position.y)
      lightsPositions.push(position.z)
    })

    const lightsTargets: number[] = []
    this._lightsTargets.forEach((position) => {
      lightsTargets.push(position.x)
      lightsTargets.push(position.y)
      lightsTargets.push(position.z)
    })

    const lightsColors: number[] = []
    this._lightsColors.forEach((color) => {
      lightsColors.push(color.r)
      lightsColors.push(color.g)
      lightsColors.push(color.b)
      lightsColors.push(color.a)
    })

    this._material.setArray3('lightsPositions', lightsPositions)
    this._material.setArray3('lightsTargets', lightsTargets)
    this._material.setArray4('lightsColors', lightsColors)
    this._material.setFloats('lightsForces', this._lightsForces)
    this._material.setFloats('lightsSpecularForces', this._lightsSpecularForces)
    this._material.setFloats('lightsShininess', this._lightsShininess)

    this._material.setInt('lightsCount', this._lightsPositions.length)
  }

  private _createMaterial() : void {
    this._material = new ShaderMaterial("shader_man", this.scene, {
      vertex: Shaders.Man,
      fragment: Shaders.Man
    }, {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection", "time", "frame"],
      samplers: ['textureSampler'],
      needAlphaBlending: false,
    })

    this._material.backFaceCulling = false
    this._material.setFloat('time', this._time)

    this._applyLightsOnMaterial()

    this.mesh!.getChildMeshes().forEach((meshItem) => {
      if (meshItem.material && meshItem.material.name === 'Mat.2') {
        meshItem.material = this._material
      }
    })
  }
}
