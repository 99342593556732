import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "vr" }
const _hoisted_2 = { class: "canvas-container" }
const _hoisted_3 = { ref: "canvas" }
const _hoisted_4 = {
  key: 0,
  class: "enter-to-vr-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512)
    ]),
    (_ctx.state.xrAvailable && !_ctx.state.xrSessionActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onStartXrSessionClick && _ctx.onStartXrSessionClick(...args)))
          }, "enter to vr")
        ]))
      : _createCommentVNode("", true)
  ]))
}