
import SlideMixin from "./SlideMixin";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import {SceneState} from "@/components/Landing/Background/classes/BackgroundSceneController.class";
import {reactive} from "vue";
import isMobile from "is-mobile";

export default class ArSlide extends SlideMixin {
  public state = reactive({
    slideNum: 0,
    isMobile: false
  })

  public mounted() : void {
    this.state.isMobile = isMobile()
    EventBus.addEventListener(EventBusEvents.LandingScene, this.onLandingScene)
  }

  public unmounted() : void {
    EventBus.removeEventListener(EventBusEvents.LandingScene, this.onLandingScene)
  }

  private onLandingScene(e: Event) : void {
    const sceneState: SceneState = (e as EventBusEvent).data as SceneState

    switch (sceneState) {
      case SceneState.AR1:
        this.state.slideNum = 1
        break
      case SceneState.AR2:
        this.state.slideNum = 2
        break
      default:
        this.state.slideNum = 0
        break
    }
  }
}
