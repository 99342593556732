import ModelController from "@/classes/SceneController/ModelController.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Vector3} from "@babylonjs/core";

export default class CityModelController extends ModelController {
  constructor(args: SceneItemArgs) {
    super(args)
    this.loadModelFromUrl('/projects/post-process/models/city/scene.gltf').then(() => {
      this.mesh!.scaling = new Vector3(0.005, 0.005, 0.005)
      this.mesh!.position.y = -1.5
    })
  }
}
