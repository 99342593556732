
import {Vue} from "vue-class-component";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

declare global {
  interface Window {
    XRIFrame:any
  }
}

export default class LgTest extends Vue {
  public mounted() : void {
    EventBus.emit(EventBusEvents.SceneLoaded)

    this.startIframe()
  }

  public startIframe() : void {
    const iframeId = 'lg-iframe'
    const iframeElement = document.getElementById(iframeId)
    const iframeUrl = 'https://lg-vangogh.dev.immercyb.com'

    window.XRIFrame.registerXRIFrame(iframeId)

    iframeElement!.setAttribute('src', iframeUrl)
    console.log('Started')
  }
}
