import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import CapsuleItemModel
  from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedCapuseles/CapsuleItemModel.class";

export default class BlobedCapsulesModel extends BaseBlob {

  private _items: CapsuleItemModel[] = []

  get visible() : boolean {
    return this._visible
  }

  constructor(props: SceneItemArgs) {
    super(props);
    this._createBlobs()
  }

  protected _update() {
    //super._update();
  }

  private _createBlobs() : void {
    for (let i = 0; i < 3 ; i++) {
      const item: CapsuleItemModel = new CapsuleItemModel({
        sceneController: this.sceneController
      })

      item.setParentModel(this, i)
      this._items.push(item)
    }
  }

  public show(direction = 1) : void {
    if (!this._visible) {
      this._visible = true
      this._items.forEach((item) => item.show(direction))
    }
  }

  public hide() : void {
    if (this._visible) {
      this._visible = false
      this._items.forEach((item) => item.hide())
    }
  }
}
