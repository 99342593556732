import SceneController from "@/classes/SceneController.class";
import BackgroundCameraController from "@/components/Landing/Background/classes/BackgroundCameraController.class";
import CircleParticles from "@/components/Landing/Background/classes/sceneItems/CircleParticles.class";
import {
  Color3,
  Color4, CubeTexture,
  DefaultRenderingPipeline,
  DirectionalLight,
  HemisphericLight,
  Scene,
  Vector3
} from "@babylonjs/core";
import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import BlobedSphereModel from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedSphereModel.class";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import BlobedIcoSphereModel from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedIcoSphereModel.class";
import BlobedCapsuleModel from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedCapsuleModel.class";
import BlobedCapsulesModel from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedCapsulesModel.class";
import VrBlobModel from "@/components/Landing/Background/classes/sceneItems/blobs/VrBlobModel.class";
import set = Reflect.set;
import CirclesPostProcess from "@/components/Landing/Background/classes/postprocess/CirclesPostProcess.class";
import BlobedPostProcess from "@/components/Landing/Background/classes/postprocess/BlobedPostProcess.class";
import isMobile from "is-mobile";

export enum SceneState {
  MathDesign,
  MarketLeaders,
  Web3d,
  AR1,
  AR2,
  VR,
  Slider,
  Final
}

export default class BackgroundSceneController extends SceneController {
  private _customCameraController!:BackgroundCameraController
  private _circleParticles!:CircleParticles

  private _blobedSphere!:BlobedSphereModel
  private _blobedIcoSphere!:BlobedIcoSphereModel
  private _blobedCapsule!: BlobedCapsuleModel
  private _blobedCapsules!: BlobedCapsulesModel
  private _blobedVr!: VrBlobModel

  private _sceneState!: SceneState | null
  private _prevState!: SceneState | null
  private _lastState!: SceneState
  private _sceneChangeAvailable = true

  private _circlesPostProcess!: CirclesPostProcess
  private _blobedPostProcess!: BlobedPostProcess

  private _sceneStatesList: SceneState[] = [
    SceneState.MathDesign,
    SceneState.MarketLeaders,
    SceneState.Web3d,
    SceneState.AR1,
    SceneState.AR2,
    SceneState.VR,
    SceneState.Slider,
    SceneState.Final,
  ]

  get blobedPostProcess() : BlobedPostProcess {
    return this._blobedPostProcess
  }

  get circleParticles() : CircleParticles {
    return this._circleParticles
  }

  //private _blobs: BaseBlob[] = []

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this._createCustomCameraController()
    this._customCameraController.camera.layerMask = 1

    this.engine.setHardwareScalingLevel(1)

    setTimeout(() => {
      const hdrTexture: CubeTexture = CubeTexture.CreateFromPrefilteredData("/landing/env/pink-7-3.env", this.scene)
      this.scene.environmentTexture = hdrTexture

      hdrTexture.onLoadObservable.add(() => {
        EventBus.emit(EventBusEvents.CubeTextureLoaded)
        this._createSceneItems()
      })

      document.addEventListener('mousemove', (e) => {
        // hdrTexture.rotationY = Math.PI * (e.clientX / window.innerWidth) * 0.1 - Math.PI * .25
      })
    }, 1500)

    this._createDefaultRenderingPipeline()

    // this._createLights()
  }

  private _onAllLoaded = () : void => {
    setTimeout(() => {
      this.setState(SceneState.MathDesign)
    }, 1500)

    this._circlesPostProcess.startAnimation()
  }

  private _onScrollUp = () : void => {
    this.hide()
    this.setState(SceneState.MathDesign)
    this._circlesPostProcess.startAnimation()
  }

  private _createSceneItems() : void {

    this._blobedPostProcess = new BlobedPostProcess({
      sceneController: this
    })

    this.scene.clearColor = new Color4(0,0,0,0)

    this._circleParticles = new CircleParticles({
      sceneController: this
    })

    this._blobedSphere = new BlobedSphereModel({
      sceneController: this
    })

    this._blobedIcoSphere = new BlobedIcoSphereModel({
      sceneController: this
    })

    this._blobedCapsule = new BlobedCapsuleModel({
      sceneController: this
    })

    this._blobedCapsules = new BlobedCapsulesModel({
      sceneController: this
    })

    this._blobedVr = new VrBlobModel({
      sceneController: this
    })

    this._circlesPostProcess = new CirclesPostProcess({
      sceneController: this
    })

    // this.setState(SceneState.MathDesign)

    EventBus.addEventListener(EventBusEvents.ScrollUp, this._onScrollUp)
    EventBus.addEventListener(EventBusEvents.AllLoaded, this._onAllLoaded)

  }

  private _createDefaultRenderingPipeline() : void {
    const pipeline = new DefaultRenderingPipeline('default pipeline',
      true,
      this.scene,
      [this._customCameraController.camera])

    // pipeline.samples = 32
    pipeline.fxaaEnabled = false
    pipeline.imageProcessingEnabled = false
  }

  private _createLights() {
    const ik = 0.05

    const light1 = new HemisphericLight("DirectionalLight", new Vector3(-5, 0, 0), this.scene)
    light1.diffuse = new Color3(0.5, 0.1, 1)
    light1.specular = new Color3(0.5, 0.1, 1)
    light1.intensity = 150 * ik

    const light2 = new HemisphericLight("DirectionalLight", new Vector3(7, 0, 1), this.scene)
    light2.diffuse = new Color3(0.25, 0, 1)
    light2.specular = new Color3(0.25, 0, 1)
    light2.intensity = 25 * ik

    const light3 = new HemisphericLight("DirectionalLight", new Vector3(5, 5, -1), this.scene)
    light3.diffuse = new Color3(0.15, 0.6, 1)
    light3.specular = new Color3(0.15, 0.6, 1)
    light3.intensity = 70 * ik

    const light = new HemisphericLight("HemiLight", new Vector3(0, 0, -1), this.scene);
    light.diffuse = new Color3(0.15, 0, 1)
    light.intensity = 1

  }

  private _createCustomCameraController() : void {
    this._customCameraController = new BackgroundCameraController({
      sceneController: this
    })
  }

  public setState(state: SceneState | null) : void {
    if (this._sceneState !== state) {
      if (state !== null) this._lastState = state
      this._prevState = this._sceneState
      this._sceneState = state

      switch (state) {
        case SceneState.MathDesign:
          this._blobedSphere.show()
          this._blobedSphere.positionTo(new Vector3(0 , 0, 0), 1.5)
          this._blobedSphere.rotateTo(new Vector3(0.3 , Math.PI, 0.3), 1.5)
          this._blobedSphere.setRadius(.5)
          break

        case SceneState.MarketLeaders:

          setTimeout(() => {
            this._blobedSphere.show()
            if (isMobile()) {
              this._blobedSphere.positionTo(new Vector3(0, 0, 0), 2)
            } else {
              this._blobedSphere.positionTo(new Vector3(-0.5, 0, 0), 2)
            }
            this._blobedSphere.rotateTo(new Vector3(0.3 , Math.PI * 2, 0.3), 2)
            this._blobedSphere.setRadius(.2)
          }, this._prevState === SceneState.Web3d ? 200 : 0)

          this._blobedIcoSphere.hide()

          break

        case SceneState.Web3d:
          this._blobedSphere.hide()
          this._blobedCapsule.hide()

          setTimeout(() => {
            this._blobedIcoSphere.show()
          }, 500)

          break

        case SceneState.AR1:
          this._blobedIcoSphere.hide()
          this._blobedCapsules.hide()

          this._blobedCapsule.show()

          break

        case SceneState.AR2:
          this._blobedCapsule.hide()
          this._blobedVr.hide()

          this._blobedCapsules.show(this._prevState === SceneState.VR ? 1 : -1)
          break

        case SceneState.VR:
          this._blobedCapsules.hide()
          this._blobedVr.show()
          break

        case SceneState.Slider:
          this._blobedVr.hide()
          break
      }

      EventBus.emit(EventBusEvents.LandingScene, state)
    }
  }

  private _lockSceneChange() : void {
    this._sceneChangeAvailable = false
    setTimeout(() => {
      this._sceneChangeAvailable = true
    }, 2000)
  }

  public showNextSlide() : void {
    if (this._sceneChangeAvailable && this._sceneState !== null) {
      const statesCount = this._sceneStatesList.length
      let nextSceneState = this._sceneStatesList.indexOf(this._sceneState) + 1

      if (nextSceneState >= statesCount) {
        nextSceneState = statesCount - 1
      } else {
        if (nextSceneState > 1) this._circleParticles.speedUp(1)
        this._circlesPostProcess.startAnimation()
        this.setState(this._sceneStatesList[nextSceneState])
        this._lockSceneChange()
      }
    }
  }

  public showPrevSlide() : void {
    if (this._sceneChangeAvailable && this._sceneState !== null) {
      let nextSceneState = this._sceneStatesList.indexOf(this._sceneState) - 1

      if (nextSceneState < 0) {
        nextSceneState = 0
      } else {
        if (nextSceneState > 0) this._circleParticles.speedUp(-1)
        this._circlesPostProcess.startAnimation()
        this.setState(this._sceneStatesList[nextSceneState])
        this._lockSceneChange()
      }
    }
  }

  public hide() : void {
    this._blobedIcoSphere.hide()
    this._blobedSphere.hide()
    this._blobedCapsules.hide()
    this._blobedCapsule.hide()
    this._blobedVr.hide()
    this.setState(null)
    // this._circleParticles.speedUp(1)
  }

  public show() : void {
    this.setState(this._lastState)
    // this._circleParticles.speedUp(-1)
  }

  dispose() {
    super.dispose();

    EventBus.removeEventListener(EventBusEvents.ScrollUp, this._onScrollUp)

    this._blobedPostProcess.dispose()

    this._customCameraController.dispose()
    this._blobedSphere.dispose()
    this._blobedCapsule.dispose()


    // this._blobs.forEach(item => item.dispose())
    // this._blobs = []
  }
}
