import ModelController from "@/classes/SceneController/ModelController.class"
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import {AnimationGroup, Color3, GlowLayer, Mesh, MeshBuilder, PBRMaterial, StandardMaterial} from "@babylonjs/core";

export enum JettaModelControllerEvents {
  ModelLoaded = 'ModelLoaded'
}

interface AnimationInfo {
  group: AnimationGroup,
  isPlayed: boolean
}

interface ToColorInterface {
  r: number,
  g: number,
  b: number
}

export default class JettaModelController extends ModelController {
  private _animationGroups: AnimationInfo[] = []
  private _modelLoaded = false

  private _colorTo!: NodeJS.Timeout
  private _animationTo!: NodeJS.Timeout

  private _toColor!: ToColorInterface
  private _colorMaterial!: PBRMaterial

  public get modelLoaded() : boolean {
    return this._modelLoaded
  }

  private options = {
    enableGlow: true
  }

  constructor(args: SceneItemArgs, enableGlow = true) {
    super(args)
    this.options.enableGlow = enableGlow
    this._loadModel()

    this.scene.onBeforeRenderObservable.add(() => {
      this._update()
    })
  }

  private _update() : void {
    if (this._colorMaterial && this._toColor) {
      const currentColor = this._colorMaterial.albedoColor
      currentColor.r += (this._toColor.r - currentColor.r) / 100
      currentColor.g += (this._toColor.g - currentColor.g) / 100
      currentColor.b += (this._toColor.b - currentColor.b) / 100
    }
  }

  private _loadModel() : void {
    this.loadModelFromUrl('/projects/jetta/jetta.glb').then((container) => {
      container.animationGroups.forEach((animationGroup) => {
        animationGroup.stop()

        this._animationGroups.push({
          group: animationGroup,
          isPlayed: false
        })
      })

      this.mesh?.getChildMeshes().forEach((meshItem) => {
        const material: PBRMaterial | null = meshItem.material as PBRMaterial
        if (material) {
          if (['FOOR_CONCRETE_BASE', 'FOOR_CONCRETE_CAR'].indexOf(meshItem.name) >= 0) {
            material.albedoColor = new Color3(0.25, 0.25, 0.25)
          }

          if (meshItem.name === 'KRISHA') {
            this._colorMaterial = material
            this._colorMaterial.albedoColor = Color3.White()
          }
        }
      })

      if (this.options.enableGlow) this._enableGlow()

      setTimeout(() => {
        this._playRandomAnimation()
        this._setNewColor()
      }, 1000)

      this._modelLoaded = true
      this.dispatchEvent(new Event(JettaModelControllerEvents.ModelLoaded))
    })
  }

  private _playRandomAnimation() : void {
    const index = Math.floor(Math.random() * this._animationGroups.length)

    const item = this._animationGroups[index]
    if (item.isPlayed) {
      item.isPlayed = false
      item.group.start(false, 1, item.group.to, item.group.from)
    } else {
      item.isPlayed = true
      item.group.start(false, 1, item.group.from, item.group.to)
    }

    this._animationTo =  setTimeout(() => {
      this._playRandomAnimation()
    }, 5000)
  }

  private _enableGlow() : void {
    const whiteGlowLayer : GlowLayer = new GlowLayer('white_glow', this.scene, {
      mainTextureSamples: 10,
    })
    whiteGlowLayer.intensity = 0.3

    const whiteGlowLightsLayer : GlowLayer = new GlowLayer('whte_glow', this.scene, {
      mainTextureSamples: 10,
    })

    whiteGlowLightsLayer.intensity = 1.2


    const redGlowLayer = new GlowLayer('red_glow', this.scene, {
      mainTextureSamples: 1
    })
    redGlowLayer.intensity = 0.6

    const whiteGlowBox = MeshBuilder.CreateBox("white_glow_box", {
      width: 2,
      height: 3.5,
      depth: 1.5
    }, this.scene)

    const whiteGlowBoxMat : StandardMaterial = new StandardMaterial('whiteGlowBox', this.scene)
    whiteGlowBoxMat.diffuseColor = new Color3(0.1, 0.1, 0.1)
    whiteGlowBoxMat.emissiveColor = Color3.Black()
    whiteGlowBoxMat.disableColorWrite = true
    whiteGlowBoxMat.disableDepthWrite = true

    whiteGlowBox.material = whiteGlowBoxMat
    whiteGlowBox.rotation.x = Math.PI / 2
    whiteGlowBox.position.y = 0.9

    this.scene.addMesh(whiteGlowBox)
    whiteGlowLightsLayer.addIncludedOnlyMesh(whiteGlowBox)

    const whiteGlowBox2 = MeshBuilder.CreateBox("white_glow_box", {
      width: 1,
      height: 5,
      depth: 1.5
    }, this.scene)

    const whiteGlowBox2Mat : StandardMaterial = new StandardMaterial('whiteGlowBox2Mat', this.scene)
    whiteGlowBox2Mat.diffuseColor = new Color3(0.1, 0.1, 0.1)
    whiteGlowBox2Mat.emissiveColor = Color3.Black()
    whiteGlowBox2Mat.disableColorWrite = true
    whiteGlowBox2Mat.disableDepthWrite = true
    whiteGlowBox2.material = whiteGlowBox2Mat

    whiteGlowBox2.rotation.x = Math.PI / 2
    whiteGlowBox2.position.y = 0.9
    this.scene.addMesh(whiteGlowBox2)

    whiteGlowLightsLayer.addIncludedOnlyMesh(whiteGlowBox2)

    const whiteGlowBottomLineBox = MeshBuilder.CreateBox("white_glow_box", {
      width: 4,
      height: 7,
      depth: 0.1
    }, this.scene)

    const whiteGlowBottomLineBoxMat : StandardMaterial = new StandardMaterial('whiteGlowBottomLineBoxMat', this.scene)
    whiteGlowBottomLineBoxMat.diffuseColor = new Color3(0.1, 0.1, 0.1)
    whiteGlowBottomLineBoxMat.emissiveColor = Color3.Black()
    whiteGlowBottomLineBoxMat.disableColorWrite = true
    whiteGlowBottomLineBoxMat.disableDepthWrite = true
    whiteGlowBottomLineBox.material = whiteGlowBottomLineBoxMat

    whiteGlowBottomLineBox.rotation.x = Math.PI / 2
    whiteGlowBottomLineBox.position.y = 0.1

    whiteGlowLayer.addIncludedOnlyMesh(whiteGlowBottomLineBox)


    redGlowLayer.addIncludedOnlyMesh(whiteGlowBox)

    this.mesh?.getChildMeshes().forEach((meshItem) => {
      if (['HOOD_LOW'].indexOf(meshItem.name) >= 0) {
        whiteGlowLightsLayer.addIncludedOnlyMesh(meshItem as Mesh)
      }

      if (meshItem.material) {
        const mat: PBRMaterial = meshItem.material as PBRMaterial

        if (['BOTTOM_LINE_EM', 'TOP_LINE_EM', 'VW_LOGO_EM', 'YOUNIVERSE_EM', 'JETTA_EM', 'WALL_LOGO_LINE_EM'].indexOf(meshItem.name) >= 0 ) {
          mat.emissiveColor = Color3.White()
          whiteGlowLayer.addIncludedOnlyMesh(meshItem as Mesh)
        }

        if (['FARA_FRONT_EM'].indexOf(meshItem.name) >= 0 ) {
          mat.emissiveColor = Color3.White()
          whiteGlowLightsLayer.addIncludedOnlyMesh(meshItem as Mesh)
        }

        if (meshItem.name === 'BAG_KRISH_MET') {
          redGlowLayer.addIncludedOnlyMesh(meshItem as Mesh)
        }

        if (meshItem.name === 'FARA_BACK_EM' || meshItem.name === 'FARA_BAGAZH_EM') {
          mat.emissiveColor = Color3.Red()
          redGlowLayer.addIncludedOnlyMesh(meshItem as Mesh)
        }
      }
    })
  }

  private _setNewColor() : void {
    this._toColor = {
      r: Math.random() * 0.7,
      g: Math.random() * 0.7,
      b: Math.random() * 0.7
    }

    this._colorTo = setTimeout(() => {
      this._setNewColor()
    }, Math.random() * 3000 + 3000)
  }

  public dispose() : void {
    if (this._colorTo) clearTimeout(this._colorTo)
    if (this._animationTo) clearTimeout(this._animationTo)
  }
}
