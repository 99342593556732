
import {prop, Vue} from "vue-class-component";
import I18n from "@/plugins/I18n";
import isMobile from "is-mobile";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import { reactive } from "vue";

class SlideButtonProps {
  disabled = prop({
    type: Boolean,
    required: false,
    default: false
  })
}

export default class SlideButton extends Vue.with(SlideButtonProps) {

  public state = reactive({
    disabledLineWidth: 0,
    disabledLineY: 0
  })


  mounted() {
    this.updateLine()
    EventBus.addEventListener(EventBusEvents.LocaleChanged, this.onLocaleChanged)
  }

  unmounted() {
    EventBus.removeEventListener(EventBusEvents.LocaleChanged, this.onLocaleChanged)
  }

  private onLocaleChanged() : void {
    this.updateLine()
  }

  private updateLine() : void {
    this.state.disabledLineWidth = isMobile() ? (I18n.getLocale() === 'ru' ? 220 : 160) : (I18n.getLocale() === 'ru' ? 350 : 260)
    this.state.disabledLineY = isMobile() ? 55 : 47
  }
}
