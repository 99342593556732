import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {FxaaPostProcess, PostProcess, Vector2} from "@babylonjs/core";
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection'
import '@tensorflow/tfjs-backend-webgl'
import {FaceLandmarksDetector} from "@tensorflow-models/face-landmarks-detection/dist/types";
import {AnnotatedPrediction} from "@tensorflow-models/face-landmarks-detection/dist/mediapipe-facemesh";
import {min} from "@tensorflow/tfjs-backend-webgl/dist/kernels/Min";

export default class FaceMeshSceneItem extends SceneItem {
  private _time = 0
  private _postProcess!: PostProcess;
  private _fxaaPostProcess!: FxaaPostProcess;
  private _mouseVector: Vector2 = Vector2.Zero()

  private _videoEl!:HTMLVideoElement | null
  private _localMediaStream!:MediaStream | null

  private _faceDetectionModel!: FaceLandmarksDetector

  private _points : number[] = []

  constructor(props: SceneItemArgs) {
    super(props)
    this._createVideoElement()

    this.scene.onBeforeRenderObservable.add(this._update)
    // window.addEventListener('mousemove', this._onMouseMove)
  }

  private _createVideoElement() : void {
    const container = document.querySelector('.shaders-arts .canvas-container')

    if (container) {
      this._videoEl = document.createElement('video') as HTMLVideoElement
      container.insertBefore(this._videoEl, container.firstChild)


      navigator.mediaDevices
        .getUserMedia({video: true, audio: false})
        .then((localStream) => {
          this._localMediaStream = localStream
          this._videoEl!.srcObject = localStream
          this._videoEl!.play()
          this._videoEl!.addEventListener('loadeddata', () => {
            this._createFaceDetectionModel()
          })
        })
    }
  }

  private async _createFaceDetectionModel() : Promise<void> {
    this._faceDetectionModel = await faceLandmarksDetection
      .load(faceLandmarksDetection.SupportedPackages.mediapipeFacemesh)

    await this._startPredictions()
  }

  private async _startPredictions() : Promise<void> {
    const annotatedPrediction = await this._faceDetectionModel.estimateFaces({ input: this._videoEl! })

    if (annotatedPrediction[0]) {
      if (!this._postProcess) {
        this._createEffect()
      }
      //this._points = []
      const predictionPoints = <Array<number[]>>annotatedPrediction[0].mesh

      //let minX = 1000
      // let maxX = 0

      let pointIndex = 0
      predictionPoints.forEach((vector, index) => {
        if (index % 1 == 0) {
          if (this._points[pointIndex * 3]) {
            this._points[pointIndex * 3] += (vector[0] - this._points[pointIndex * 3]) / 3
            this._points[pointIndex * 3 + 1] += (vector[1] - this._points[pointIndex * 3 + 1]) / 3
            this._points[pointIndex * 3 + 2] += (vector[2] - this._points[pointIndex * 3 + 2]) / 3
          } else {
            this._points.push(vector[0])
            this._points.push(vector[1])
            this._points.push(vector[2])
          }

          pointIndex ++
        }
      })
    }

    //console.log(minX, maxX)

    await this._startPredictions()
  }

  private _onMouseMove = (e : MouseEvent) => {
    // this._mouseVector.x = e.clientX
    // this._mouseVector.y = e.clientY
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
  }

  private _createEffect() {
    const facePostProcess = new PostProcess(
      'occlusion',
      '/projects/shaders-arts/fx/voronoi-face',
      ['time', 'screenSize', 'points', 'pointsCount'],
      [],
      1.,
      this.camera
    )

    facePostProcess.onApply = (effect) => {
      effect.setFloat('time', this._time)
      effect.setVector2('screenSize', new Vector2(facePostProcess.width, facePostProcess.height))
      if (this._points.length > 0) {
        effect.setArray3('points', this._points)
        effect.setInt('pointsCount', this._points.length)
      } else {
        effect.setInt('pointsCount', 0)
      }
    }

    this._postProcess = facePostProcess

    // this._fxaaPostProcess = new FxaaPostProcess('fxaa', 1., this.camera)

    // this._fxaaPostProcess.onApply = (effect) => {
    //   effect.setTextureFromPostProcessOutput('textureSampler', facePostProcess)
    // }
  }

  public dispose() : void {

    if (this._videoEl) {
      this._videoEl.remove()
      this._videoEl = null
    }

    if (this._localMediaStream) {
      this._localMediaStream.getTracks().forEach(track => {
        track.stop()
      })

      this._localMediaStream = null
    }

    if (this._postProcess) {
      this._postProcess.dispose(this.camera)
      //window.removeEventListener('mousemove', this._onMouseMove)
    }

    if (this._fxaaPostProcess) this._fxaaPostProcess.dispose(this.camera)
  }
}
