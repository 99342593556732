import {
  WebXRAbstractMotionController,
  WebXRCamera,
  WebXRInput,
  WebXRInputSource,
  WebXRSessionManager
} from "@babylonjs/core";

export default class ControllersInput {
  private _xrSessionManager: WebXRSessionManager
  private _xrCamera: WebXRCamera
  private _webXRInput: WebXRInput

  private _leftController!: WebXRAbstractMotionController
  private _rightController!: WebXRAbstractMotionController

  constructor(xrSessionManager: WebXRSessionManager, camera: WebXRCamera) {
    this._xrSessionManager = xrSessionManager
    this._xrCamera = camera

    this._webXRInput = new WebXRInput(this._xrSessionManager, this._xrCamera)
    this._webXRInput.onControllerAddedObservable.add(this._onControllerAdded)
    this._webXRInput.onControllerRemovedObservable.add(this._onControllerRemoved)

    console.log('Controllers input created', this._webXRInput.controllers)
  }

  private _onControllerAdded = (inputSource: WebXRInputSource) : void => {
    console.log('input source', inputSource)
    inputSource.onMotionControllerInitObservable.add((motionController) => {
      const xr_ids = motionController.getComponentIds()
      console.log(xr_ids)

      if (motionController.handedness === 'left') {
        this._leftController = motionController
      } else {
        this._rightController = motionController
      }

      // motionController.onModelLoadedObservable.add((model) => {
      //
      // })
    })
  }

  private _onControllerRemoved = (inputSource: WebXRInputSource) : void => {
    //
  }

  public dispose() : void {
    //
  }
}
