import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import JettaModelController, {JettaModelControllerEvents} from "@/components/JettaProject/classes/JettaModelController.class";
import {Nullable, Observer, Scene} from "@babylonjs/core";

export default class JettaModelWrapperSceneItem extends SceneItem {

  private _jettaModelSceneItem: JettaModelController
  private _updateObserver!: Nullable<Observer<Scene>>

  constructor(props: SceneItemArgs) {
    super(props)

    this._jettaModelSceneItem = new JettaModelController(props, false)
    this._jettaModelSceneItem.addEventListener(JettaModelControllerEvents.ModelLoaded, this._onModelLoaded)

    this._updateObserver = this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    if (this._jettaModelSceneItem.mesh) {
      // this._jettaModelSceneItem.mesh.rotation.y += 0.01
    }
  }

  private _onModelLoaded = () : void => {
    this._jettaModelSceneItem.mesh!.position.y = -1.7
    this._jettaModelSceneItem.mesh!.rotation.y = Math.PI
  }

  public dispose() : void {
    this.scene.onBeforeRenderObservable.remove(this._updateObserver)
    this._jettaModelSceneItem.removeEventListener(JettaModelControllerEvents.ModelLoaded, this._onModelLoaded)
  }
}
