import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-menu-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon_button, {
      class: _normalizeClass({hidden: _ctx.menuOpened}),
      icon: 'menu'
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.$tr('Menu')), 1)
        ])
      ]),
      _: 3
    }, 8, ["class"]),
    _createVNode(_component_icon_button, {
      class: _normalizeClass({hidden: !_ctx.menuOpened}),
      icon: 'close'
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.$tr('Close')), 1)
        ])
      ]),
      _: 3
    }, 8, ["class"])
  ]))
}