import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Color3, Mesh, PBRMaterial, ShaderMaterial, StandardMaterial, Vector3} from "@babylonjs/core";
import SatelliteCapsuleModel
  from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedCapsuleModel/SatelliteCapsuleModel.class";
import eases from "eases";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class BlobedCapsuleModel extends BaseBlob {

  private _satellites: SatelliteCapsuleModel[] = []
  private _capsuleMeshes: Mesh[] = []

  private _slideUpStartTime = 0

  get meshFront() : Mesh {
    return this._meshFront
  }

  get visible() : boolean {
    return this._visible
  }

  constructor(props: SceneItemArgs) {
    super(props)

    this._linesCount = 17
    this._speed = -0.0001

    // this._createMaterials()
    this._createStaticCustomPbr()
    this._createCapsule()
  }

  protected _createStaticCustomPbr() {
    super._createStaticCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/capsule/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
  }

  // private _createMaterials() : void {
  //   this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/landing/capsule/fx/blob", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //   this._materialFront.backFaceCulling = true
  //
  //   this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/capsule/fx/blob-back", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //
  //   this._materialBack.backFaceCulling = true
  //   this._createLights()
  // }

  protected _update() {
    if (this._meshFront) {
      this._rotation.x = 0
      this._rotation.z = 0
      this._toRotation.x = 0
      this._toRotation.z = 0
      this._rotationOffset.x = 0
      this._rotationOffset.z = 0

      const [m1, m2] = this._capsuleMeshes

      if (this.visible) {
        m1.position.y = 0
      } else {
        const time = ((this._time - this._slideUpStartTime) / 2) / 1000
        m1.position.y = eases.cubicInOut(time) * 2

        if (m1.position.y > 1.9) {
          this._toRotation = new Vector3(0, -Math.PI * 2, 0)
        }
      }

      m2.position.y = m1.position.y
    }

    super._update();
  }

  private _createSatellites() : void {
    for (let i = 0; i < 2 ; i++) {
      const satellite = new SatelliteCapsuleModel({
        sceneController: this.sceneController
      })

      satellite.setParentModel(this, i)
      this._satellites.push(satellite)
    }
  }

  private _createCapsule() : void {
    const scaling = 0.5

    this.loadModelFromUrl('/landing/capsule/model.glb', false, false)
      .then((assetContainer) => {
        const originMesh:Mesh = assetContainer.meshes.filter((mesh) => mesh.name === 'Capsule')[0] as Mesh

        const parentMesh = new Mesh('capsule-root', this.scene)

        originMesh.parent = parentMesh
        originMesh.position = Vector3.Zero()
        originMesh.scaling = new Vector3(scaling, scaling, scaling)

        this._meshFront = parentMesh.clone()
        this._meshFront.name = 'Front capsule clone'
        this._meshBack = parentMesh.clone()
        this._meshFront.name = 'Back capsule clone'

        this._meshFront.getChildMeshes().forEach((mesh) => {
          if (mesh.material) {
            // mesh.material = this._customFrontMaterial
            const material: PBRMaterial = mesh.material as PBRMaterial
            material.backFaceCulling = true
            material.environmentIntensity = 10
            // material.emissiveColor = new Color3(0, 0, 0.2)
            // material.emissiveIntensity = 0.2
            this._capsuleMeshes.push(mesh as Mesh)
          }
        })

        this._meshBack.getChildMeshes().forEach((mesh) => {
          const meshInstance : Mesh = mesh as Mesh
          meshInstance.makeGeometryUnique()
          meshInstance.flipFaces(true)
          mesh.material = this._materialBack
          this._capsuleMeshes.push(meshInstance)
        })

        parentMesh.dispose()

        this._meshFront.scaling = this._meshBack.scaling = Vector3.Zero()

        this._meshFront.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)
        this._meshBack.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)

        this._meshFront.layerMask = 2
        this._meshBack.layerMask = 2

        this.blobedPostProcess.addMeshForRender(this._meshFront, true)
        this.blobedPostProcess.addMeshForRender(this._meshBack, false)

        this._createSatellites()

        EventBus.emit(EventBusEvents.BlobedCapsuleLoaded)
    })
  }

  public show() : void {
    if (!this._visible) {
      this._visible = true
      this._scaling = Vector3.Zero()

      this.positionTo(new Vector3(0.2, 0, 0), 2)
      this.scaleTo(Vector3.One(), 2)
      this.rotateTo(new Vector3(0, 0, 0), 2)
    }
  }

  public hide() : void {
    if (this._visible) {
      this._visible = false
      this._slideUpStartTime = this._time
      this.positionTo(Vector3.Zero(), 2)
      this._satellites.forEach((satellite) => satellite.slideUp())
    }
  }
}
