import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Engine, Mesh, MeshBuilder, PBRMaterial, Scene} from "@babylonjs/core";

class SphereWrapper {
  public mesh: Mesh
  public time = Math.random() * Math.PI * 2
  public speed = Math.random() * 0.001 + 0.001
  public yRadius = Math.random() * Math.PI * 10

  static _material: PBRMaterial | null = null

  constructor(scene: Scene) {
    if (!SphereWrapper._material) {
      const mat:PBRMaterial = new PBRMaterial('shpere mat', scene)
      //mat.alphaMode = Engine.ALPHA_COMBINE
      mat.alpha = 0.1
      mat.reflectionTexture = scene.environmentTexture
      mat.roughness = 0.4
      mat.metallic = 0.3
      SphereWrapper._material = mat
    }
    this.mesh = MeshBuilder.CreateSphere('sphere', {
      diameter: Math.random() * 20 + 1
    }, scene)

    this.mesh.material = SphereWrapper._material

    const radius = 30 + Math.random() * 100
    const position = Math.random() * Math.PI * 2

    this.mesh.position.x = Math.cos(position) * radius
    this.mesh.position.z = Math.sin(position) * radius
    this.mesh.position.y = Math.sin(this.time) * this.yRadius + this.yRadius
  }

  update() {
    this.time += this.speed
    this.mesh.position.y = Math.sin(this.time) * this.yRadius
  }
}

export default class SpheresController extends SceneItem {
  private _spheresList : SphereWrapper[] = []

  constructor(props: SceneItemArgs) {
    super(props)

    this.scene.onBeforeRenderObservable.add(this._update)

    for (let i = 0 ; i < 20; i++) {
      this._spheresList.push(new SphereWrapper(this.scene))
    }
  }

  private _update = () : void => {
    this._spheresList.forEach((sphereItem) => {
      sphereItem.update()
    })
  }

  private _removeMaterial() : void {
    SphereWrapper._material = null
  }

  public dispose() : void {
    this._spheresList = []
    this._removeMaterial()
  }
}
