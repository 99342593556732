import SceneController from "@/classes/SceneController.class";
import CircleShaderSceneItem from "@/components/ShadersArts/classes/shadersSceneItems/CircleShaderSceneItem.class";
import {Color3, Color4} from "@babylonjs/core";
import RayMarchingSceneItem from "@/components/ShadersArts/classes/shadersSceneItems/RayMarchingSceneItem.class";
import {EffectsList} from "@/components/ShadersArts.vue";
import LavaWorldSceneItem from "@/components/ShadersArts/classes/shadersSceneItems/LavaWorldSceneItem.class";
import FaceMeshSceneItem from "@/components/ShadersArts/classes/shadersSceneItems/FaceMeshSceneItem.class";
import TexturedCubeSceneItem from "@/components/ShadersArts/classes/shadersSceneItems/TexturedCubeSceneItem.class";

export default class ShadersArtsSceneController extends SceneController {

  private _circleShaderSceneItem!:CircleShaderSceneItem | null
  private _rayMarching!: RayMarchingSceneItem | null
  private _lavaWorld!: LavaWorldSceneItem | null
  private _faceMesh!: FaceMeshSceneItem | null
  private _texturedCube!: TexturedCubeSceneItem | null

  public currentEffect!: EffectsList

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()
    this.scene.clearColor = new Color4(0, 0, 0, 1);

    //this._createCube()
    // this._createCircleShader()
    // this._createRayMarching()
    //this._createLavaWorld()
    // this._createFaceMesh()

    this.enableEffect(EffectsList.LavaWorld)
  }

  public setOptions({color}: {color: Color3}): void {
    if (this._lavaWorld) {
      // this._lavaWorld.setOptions({color})
    }
  }

  private _createFaceMesh() : void {
    this.engine.setHardwareScalingLevel(2.);
    this.scene.clearColor = new Color4(0, 0, 0, 0);
    this._faceMesh = new FaceMeshSceneItem({
      sceneController: this
    })
  }

  private _createCircleShader() : void {
    this.engine.setHardwareScalingLevel(1.);
    this._circleShaderSceneItem = new CircleShaderSceneItem({
      sceneController: this
    })
  }

  private _createRayMarching() : void {
    this.engine.setHardwareScalingLevel(1.);
    this._rayMarching = new RayMarchingSceneItem({
      sceneController: this
    })
  }

  private _createLavaWorld() : void {
    this.engine.setHardwareScalingLevel(1.3);
    this._lavaWorld = new LavaWorldSceneItem({
      sceneController: this
    })
  }

  private _createCube() : void {
    this.engine.setHardwareScalingLevel(1.);
    this._texturedCube = new TexturedCubeSceneItem({
      sceneController: this
    })
  }

  public enableEffect(name: EffectsList) : void {
    this.currentEffect = name

    this.scene.clearColor = new Color4(0, 0, 0, 1);

    if (this._texturedCube) {
      this._texturedCube.dispose()
      this._texturedCube = null
    }

    if (this._faceMesh) {
      this._faceMesh.dispose()
      this._faceMesh = null
    }

    if (this._lavaWorld) {
      this._lavaWorld.dispose()
      this._lavaWorld = null
    }

    if (this._rayMarching) {
      this._rayMarching.dispose()
      this._rayMarching = null
    }

    if (this._circleShaderSceneItem) {
      this._circleShaderSceneItem.dispose()
      this._circleShaderSceneItem = null
    }

    switch (name) {
      case EffectsList.LavaWorld:
        this._createLavaWorld()
        break

      case EffectsList.Psyho:
        this._createCircleShader()
        break

      case EffectsList.RayMarching:
        this._createRayMarching()
        break

      case EffectsList.FaceMesh:
        this._createFaceMesh()
        break

      case EffectsList.TexturedCube:
        this._createCube()
        break
    }
  }
}
