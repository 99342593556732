import {Mesh, PBRMaterial, Vector3} from "@babylonjs/core"
import ModelController from "@/classes/SceneController/ModelController.class";
import SceneController from "@/classes/SceneController.class";
import eases from "eases"

export enum FlyingFramesEvents {
  AnimationComplete = 'AnimationComplete'
}

export default class FlyingFrames extends ModelController {
  private _frames: Mesh[] = []
  private _framesToPositions: Vector3[] = []
  private _framesPositions: Vector3[] = []
  private _framesScales: number[] = []
  private _visible = false
  private _initTime = 0
  private _positionInitTime = 0
  private _positioAnimationTime = 49000 //12000
  private _time = 0

  private _animationComplete = false
  private _expandAnimationComplete = false

  private _parentMesh : Mesh

  private _updateDisabled = true

  public get animationComplete() : boolean {
    return this._animationComplete
  }

  public get expandAnimationComplete() : boolean {
    return this._expandAnimationComplete
  }

  public get visible() : boolean {
    return this._visible
  }

  public set visible(val : boolean) {
    if (this._visible !== val) {
      this._visible = val

      if (this._visible) {
        this._updateDisabled = false
      }

      this._expandAnimationComplete = false
      this._animationComplete = false
      this._initTime = Date.now()
      // this._initTime = Date.now()
      this._positionInitTime = this._initTime + 2200
    }
  }

  constructor({sceneController, parentMesh}: {sceneController: SceneController, parentMesh: Mesh}) {
    super({
      sceneController
    })

    this._parentMesh = parentMesh
    this._loadModels()

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    if (!this._updateDisabled) {
      const deltaTime = this.sceneController.engine.getDeltaTime()

      this._time = Date.now()

      if (this._visible && !this._expandAnimationComplete) {

        this._frames.forEach((mesh, index) => {
          if (this._time > this._initTime + index * 500) {
            const speed = 0.0005 * (index + 1) * deltaTime

            if (this._time < this._positionInitTime) {

              let time = ((this._time - (this._initTime + index * 500) )) / 2000

              if (time >= 1) {
                time = 1
              }

              if (time < 0) {
                time = 0
              }

              const position = eases.linear(time)

              const toPosition: Vector3 = Vector3.Zero()
              toPosition.z = (0.6 - index * 0.2) + 0.2
              toPosition.x = 0 //this._framesToPositions[index].x / 2
              toPosition.y = 0 //this._framesToPositions[index].y / 2

              const scalingDelta: Vector3 = Vector3.One()
                .multiplyByFloats(0.5, 0.5, 0.5)
                .subtract(mesh.scaling)
                .multiplyByFloats(0.2, 0.2, 0.2)
              mesh.scaling = mesh.scaling.add(scalingDelta)

              const positionDelta: Vector3 = toPosition.subtract(mesh.position)
              mesh.position = mesh.position.add(positionDelta.multiplyByFloats(time, time, time))
              this._framesPositions[index] = mesh.position
            } else {

              const defDelta = 10000

              let delta = 0
              if (index === 0) {
                delta = 6000
              } else if (index === 1) {
                delta = 8000
              } else if (index === 2) {
                delta = 9000
              } else if (index === 3) {
                delta = 9000
              }

              if (this._time > this._positionInitTime + index * delta) {
                let time = 1 - ((this._time - (this._positionInitTime + index * delta) )) / delta

                if (time < 0.1 * (defDelta / delta)) {
                  time = 1 - time * 10
                } else {
                  time = 0
                }

                if (time >= 1) {
                  time = 1
                }

                if (time < 0) {
                  time = 0
                }

                const scale = eases.linear(time) * 0.5 + 0.5
                const position = eases.linear(time)

                // const distance = Vector3.Distance(this._framesToPositions[index], this._framesPositions[index])
                // const currentDistance = Vector3.Distance(mesh.position, this._framesToPositions[index])

                const scalingDelta: Vector3 = Vector3.One().multiplyByFloats(scale, scale, scale)
                const positionDelta = this._framesToPositions[index].subtract(this._framesPositions[index])

                mesh.scaling = scalingDelta
                mesh.position = this._framesPositions[index].add(positionDelta.multiplyByFloats(position, position, position))

                if (this._time > this._positionInitTime + this._positioAnimationTime && !this._animationComplete) {
                  this._expandAnimationComplete = true
                  this._initTime = Date.now()
                }
              }
            }
          }
        })
      } else {
        let onPlace = true

        this._frames.forEach((mesh, index) => {
          if (this._time > this._initTime + index * 500) {
            const speed = 0.003 * deltaTime
            const scalingDelta: Vector3 = Vector3.Zero().subtract(mesh.scaling).multiplyByFloats(speed, speed, speed)
            mesh.scaling = mesh.scaling.add(scalingDelta)

            const toPosition: Vector3 = Vector3.Zero() //this._pictureMesh.position.clone()
            const positionDelta: Vector3 = toPosition.subtract(mesh.position).multiplyByFloats(speed, speed, speed)

            mesh.position = mesh.position.add(positionDelta)

            if (mesh.scaling.x > 0.05) {
              onPlace = false
            }
          } else {
            onPlace = false
          }
        })

        if (onPlace && !this._animationComplete) {

          this._frames.forEach((mesh) => {
            mesh.scaling = Vector3.Zero()
          })

          this._updateDisabled = true
          this._animationComplete = true
          this.dispatchEvent(new Event(FlyingFramesEvents.AnimationComplete))
        }
      }
    }
  }

  private _loadModels() : void {
    const urls: string[] = [
      '/projects/lg-vangogh/models/fly-pics/pic-1.glb',
      '/projects/lg-vangogh/models/fly-pics/pic-2.glb',
      '/projects/lg-vangogh/models/fly-pics/pic-3.glb',
      '/projects/lg-vangogh/models/fly-pics/pic-4.glb'
    ]

    this._framesToPositions.push(new Vector3(-0.8, -0.5,  (4 * 0.3  - 0 * 0.3) + 0.5))
    this._framesToPositions.push(new Vector3(1.5, -0.3,  (4 * 0.3  - 1 * 0.3) + 0.5))
    this._framesToPositions.push(new Vector3(-1, 1.3,  (4 * 0.3  - 2 * 0.3) + 0.5))
    this._framesToPositions.push(new Vector3(1.2, 1.4,  (4 * 0.3  - 3 * 0.3) + 0.5))

    this._framesScales.push(1)
    this._framesScales.push(1)
    this._framesScales.push(1)
    this._framesScales.push(1)

    urls.forEach((url, index) => {
      this.loadAssetContainerFromUrl(url).then((assetContainer) => {
        assetContainer.addAllToScene()

        const rootMesh:Mesh = new Mesh(`frame-${index}`, this.scene)
        rootMesh.parent = this._parentMesh

        assetContainer.meshes.forEach((mesh) => {
          if (!mesh.parent) mesh.parent = rootMesh

          if (mesh.material) {
            const material: PBRMaterial = mesh.material as PBRMaterial
            material.emissiveIntensity = 0
            material.freeze()
          }
         })

        rootMesh.scaling = Vector3.Zero()
        this._frames.push(rootMesh)
      })
    })
  }

}
