import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import {PostProcess, Vector2} from "@babylonjs/core";

export default class RayMarchingSceneItem extends SceneItem {
  private _time = 0
  private _mouseVector: Vector2 = Vector2.Zero()

  private _postProcess!: PostProcess;

  constructor(props: SceneItemArgs) {
    super(props)
    this._createEffect()

    this.scene.onBeforeRenderObservable.add(this._update)
    window.addEventListener('mousemove', this._onMouseMove)
  }

  private _onMouseMove = (e : MouseEvent) => {
    this._mouseVector.x = e.clientX
    this._mouseVector.y = e.clientY
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
  }

  private _createEffect() {
    const rayMarchingPostProcess = new PostProcess(
      'occlusion',
      '/projects/shaders-arts/fx/ray-marching',
      ['time', 'mouse', 'screenSize'],
      [],
      1.,
      this.camera
    )

    rayMarchingPostProcess.onApply = (effect) => {
      effect.setFloat('time', this._time)
      effect.setVector2('screenSize', new Vector2(rayMarchingPostProcess.width, rayMarchingPostProcess.height))
      effect.setVector2('mouse', this._mouseVector)
    }

    this._postProcess = rayMarchingPostProcess
  }

  public dispose() : void {
    window.removeEventListener('mousemove', this._onMouseMove)
    this._postProcess.dispose(this.camera)
  }
}
