import SceneController from "@/classes/SceneController.class";
import KrausGround from "@/components/Kraus/classes/KrausGround.class";
import {Color3, Color4, CubeTexture, HemisphericLight, Scene, Vector3} from "@babylonjs/core"
import BuildingsController, {BuildingsControllerEvents} from "@/components/Kraus/classes/BuildingsController.class";

export enum KrausSceneControllerEvents {
  ModelsLoaded = 'ModelsLoaded'
}

export default class KrausSceneController extends SceneController {
  private _ground: KrausGround
  private _buildingsController: BuildingsController

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.engine.setHardwareScalingLevel(0.7)
    this.createCameraController()

    this.scene.clearColor = new Color4(5/255,60/255,141/255, 1)
    this.scene.environmentIntensity = 1.5
    this.scene.fogEnabled = true
    this.scene.fogStart = 10
    this.scene.fogEnd = 50
    this.scene.fogMode = Scene.FOGMODE_LINEAR
    this.scene.fogColor = Color3.Gray()

    this.cameraController.camera.lowerRadiusLimit = 1
    this.cameraController.camera.upperRadiusLimit = 7
    this.cameraController.camera.radius = 5
    this.cameraController.camera.inputs.remove(this.cameraController.camera.inputs.attached.mousewheel)

    const hdrTexture: CubeTexture = CubeTexture.CreateFromPrefilteredData("/projects/about-immercyb/env/autoshop.env", this.scene)
    this.scene.environmentTexture = hdrTexture

    this._ground = new KrausGround({
      sceneController: this
    })

    this._buildingsController = new BuildingsController({
      sceneController: this
    })

    this._buildingsController.addEventListener(BuildingsControllerEvents.ModelsLoaded, this._onModelsLoaded)
  }

  private _onModelsLoaded = () : void => {
    this.dispatchEvent(new Event(KrausSceneControllerEvents.ModelsLoaded))
  }
}
