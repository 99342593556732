
import {prop, Vue} from "vue-class-component";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import {SceneState} from "@/components/Landing/Background/classes/BackgroundSceneController.class";

class WordsSliderProps {
  direction = prop({
    type: Number,
    required: true
  })
}
export default class WordsSlider extends Vue.with(WordsSliderProps) {
  private animationStarted = false
  private htmlDivElements: HTMLDivElement[] = []
  private toSpeed = 1
  private speed = 1

  public firstDivElement() : HTMLDivElement | null {
    let element: HTMLDivElement | null = null
    let minValue: number | null = null

    for (let i = 0; i < this.htmlDivElements.length; i++) {
      const divElement = this.htmlDivElements[i]
      const rect = divElement.getBoundingClientRect()

      if (this.direction < 0) {
        if (minValue === null || minValue > rect.x) {
          minValue = rect.x
          element = divElement
        }
      } else {
        if (minValue === null || minValue < rect.x) {
          minValue = rect.x
          element = divElement
        }
      }
    }

    return element
  }

  public mounted() : void {
    this.htmlDivElements = [this.$refs.text1 as HTMLDivElement, this.$refs.text2 as HTMLDivElement]
    this.startAnimation()

    EventBus.addEventListener(EventBusEvents.LandingScene, this.onLandingScene)
  }

  public unmounted() : void {
    this.animationStarted = false
    EventBus.removeEventListener(EventBusEvents.LandingScene, this.onLandingScene)
  }

  private onLandingScene(e: Event) {
    this.toSpeed = 20
  }

  private startAnimation() : void {
    this.animationStarted = true
    this.requestAnimationFrame()
  }

  private requestAnimationFrame() : void {
    if (this.animationStarted) {
      window.requestAnimationFrame(() => {
        this.update()
        this.requestAnimationFrame()
      })
    }
  }

  private update() : void {

    this.speed += (this.toSpeed - this.speed) / 50

    if (this.speed > 19) {
      this.toSpeed = 1
    }


    const firstElement : HTMLDivElement = this.firstDivElement()!
    const secondElement : HTMLDivElement = firstElement === this.htmlDivElements[0] ? this.htmlDivElements[1] : this.htmlDivElements[0]

    const rect = firstElement.getBoundingClientRect()
    let position = rect.x + this.direction * this.speed

    if (this.direction < 0) {
      if (position + rect.width < 0) {
        this.flipElements()
      } else {
        firstElement.style.left = position + 'px'
        secondElement.style.left = (position + rect.width) + 'px'
      }
    } else {
      if (position > window.innerWidth) {
        this.flipElements()
      } else {
        firstElement.style.left = position + 'px'
        secondElement.style.left = (position - rect.width) + 'px'
      }
    }
  }

  private flipElements() {
    const firstElement : HTMLDivElement = this.firstDivElement()!
    const secondElement : HTMLDivElement = firstElement === this.htmlDivElements[0] ? this.htmlDivElements[1] : this.htmlDivElements[0]

    firstElement.remove()
    const rectFirst = firstElement.getBoundingClientRect()
    const rectSecond = secondElement.getBoundingClientRect()

    if (this.direction < 0) {
      secondElement.after(firstElement)
      firstElement.style.left = (rectSecond.x + rectSecond.width) + 'px'
    } else {
      secondElement.before(firstElement)
      firstElement.style.left = (rectSecond.x - rectSecond.width) + 'px'
    }
  }
}
