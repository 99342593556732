import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import I18n from "@/plugins/I18n"
import messages from "@/data/i18n/messages"
// import '@google/model-viewer'
import * as earcut from "earcut"
// eslint-disable-next-line
(window as any).earcut = earcut

import './assets/scss/index.scss'

createApp(App)
  .use(store)
  .use(router)
  .use(I18n, {
    messages
  })
  .use(PerfectScrollbar)
  .mount('#app')
