
import {prop, Vue} from 'vue-class-component'

class ProjectTitleProps {
  lines = prop({
    type: Array,
    required: true
  })
}

export default class ProjectTitle extends Vue.with(ProjectTitleProps) {

}
