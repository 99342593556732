import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Mesh, MeshBuilder, ShaderMaterial, Vector3} from "@babylonjs/core";
import VrBlobSatelliteModel
  from "@/components/Landing/Background/classes/sceneItems/blobs/VrBlobModel/VrBlobSatelliteModel.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class VrBlobModel extends BaseBlob {

  private _satellites: VrBlobSatelliteModel[] = []

  get meshFront() : Mesh {
    return this._meshFront
  }

  constructor(props: SceneItemArgs) {
    super(props);

    this._linesCount = 40
    this._toRadius = 0.1
    this._speed = 0.2

    //this._createMaterials()
    this._createCustomPbr()
    this._createModel()
  }

  protected _createStaticCustomPbr() {
    super._createStaticCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/vr-blobed/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = false
  }

  protected _createCustomPbr() {
    super._createCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/vr-blobed/fx2/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
  }

  protected _update() {
    super._update()
    this._toRadius = Math.sin(this._time * 0.0001) * 0.05 + 0.1
  }

  private _createModel() : void {
    this._mesh = new Mesh('blob root', this.scene)

    this._meshFront = MeshBuilder.CreateSphere('blob', {
      diameter: 0.7,
      segments: 300,
      sideOrientation: Mesh.FRONTSIDE
    }, this.scene)

    this._meshBack = MeshBuilder.CreateSphere('blob', {
      diameter: 0.7,
      segments: 300,
      sideOrientation: Mesh.BACKSIDE
    }, this.scene)


    this._meshFront.material = this._customFrontMaterial
    this._meshBack.material = this._materialBack

    this._meshFront.parent = this._mesh
    this._meshBack.parent = this._mesh

    this._meshFront.scaling = this._meshBack.scaling = Vector3.Zero()
    this._toRotation.y = Math.PI

    this._meshBack.layerMask = 2
    this._meshFront.layerMask = 2

    this.blobedPostProcess.addMeshForRender(this._meshFront, true)
    this.blobedPostProcess.addMeshForRender(this._meshBack, false)

    this._createSatellites()

    EventBus.emit(EventBusEvents.BlobedVrLoaded)
  }

  // private _createMaterials() : void {
  //   this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/landing/vr-blobed/fx/blob", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //   this._materialFront.backFaceCulling = true
  //
  //   this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/vr-blobed/fx/blob-back", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //
  //   this._materialBack.backFaceCulling = false
  //   this._createLights()
  // }
  //
  // private _createModel() : void {
  //
  //   const scaling = .4
  //
  //   this.loadModelFromUrl('/landing/vr-blobed/model.glb', false, false)
  //     .then((assetContainer) => {
  //       const originMesh:Mesh = assetContainer.meshes.filter((mesh) => mesh.name === 'Sphere')[0] as Mesh
  //
  //       const parentMesh = new Mesh('capsule-root', this.scene)
  //
  //       originMesh.parent = parentMesh
  //       originMesh.position = Vector3.Zero()
  //       originMesh.scaling = new Vector3(scaling, scaling, scaling)
  //
  //       this._meshFront = parentMesh.clone()
  //       this._meshFront.name = 'Front capsule clone'
  //       this._meshBack = parentMesh.clone()
  //       this._meshFront.name = 'Back capsule clone'
  //
  //       this._meshFront.getChildMeshes().forEach((mesh) => {
  //         if (mesh.material) {
  //           //mesh.material = this._customFrontMaterial
  //           mesh.material.backFaceCulling = true
  //         }
  //       })
  //
  //       this._meshBack.getChildMeshes().forEach((mesh) => {
  //         const meshInstance : Mesh = mesh as Mesh
  //         meshInstance.makeGeometryUnique()
  //         meshInstance.flipFaces(true)
  //         mesh.material = this._materialBack
  //       })
  //
  //       parentMesh.dispose()
  //
  //       this._meshFront.scaling = this._meshBack.scaling = Vector3.Zero()
  //
  //       this._meshFront.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)
  //       this._meshBack.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)
  //
  //       this._meshFront.layerMask = 2
  //       this._meshBack.layerMask = 2
  //
  //       this.blobedPostProcess.addMeshForRender(this._meshFront, true)
  //       this.blobedPostProcess.addMeshForRender(this._meshBack, false)
  //
  //       //this._toRotation = new Vector3(0, -Math.PI * 2, 0)
  //
  //       this._createSatellites()
  //
  //       EventBus.emit(EventBusEvents.BlobedVrLoaded)
  //     })
  // }

  private _createSatellites() : void {
    for (let i = 0 ; i < 3; i ++) {
      const satellite = new VrBlobSatelliteModel({
        sceneController: this.sceneController
      })
      satellite.setParentModel(this, i)
      this._satellites.push(satellite)
    }
  }

  public show() : void {
    if (!this._visible) {
      this._visible = true

      this._rotationOffset = new Vector3(-0.6, - Math.PI * 2, 0.3)

      this.scaleTo(Vector3.One(), 2)
      this.rotateTo(new Vector3(-0.6, 0.1, 0.3), 2)
      this.positionTo(new Vector3(0.2, 0, 0) , 2)
    }
  }

  public hide() : void {
    if (this._visible) {
      this._visible = false
      this.scaleTo(Vector3.Zero(), 2)
      this.rotateTo(new Vector3(-0.6, Math.PI * 2, 0.3) , 2)
    }
  }
}
