import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_landing_project_page = _resolveComponent("landing-project-page")!
  const _component_projects_menu_content = _resolveComponent("projects-menu-content")!
  const _component_main_menu_content = _resolveComponent("main-menu-content")!
  const _component_main_menu_button = _resolveComponent("main-menu-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_landing_project_page, {
      class: _normalizeClass({visible: _ctx.state.projectId !== null}),
      projectId: _ctx.state.projectId,
      onProject: _ctx.onProject
    }, null, 8, ["class", "projectId", "onProject"]),
    _createVNode(_component_projects_menu_content, {
      class: _normalizeClass({visible: _ctx.projectsMenuVisible, top: _ctx.state.projectId !== null || _ctx.state.nextProjectId !== null}),
      onBackClick: _ctx.onProjectsBackClick,
      onProject: _ctx.onProject
    }, null, 8, ["class", "onBackClick", "onProject"]),
    _createVNode(_component_main_menu_content, {
      class: _normalizeClass({hidden: !_ctx.mainMenuContentVisible}),
      onSelected: _ctx.onMainMenuItemSelected
    }, null, 8, ["class", "onSelected"]),
    _createVNode(_component_main_menu_button, {
      onClick: _ctx.onMenuButtonClick,
      onTochstart: _withModifiers(_ctx.onMenuButtonClick, ["stop"]),
      menuOpened: _ctx.state.menuOpened || _ctx.state.contactsOpened
    }, null, 8, ["onClick", "onTochstart", "menuOpened"]),
    _createElementVNode("div", {
      class: "lang-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLangButtonClick && _ctx.onLangButtonClick(...args)))
    }, _toDisplayString(_ctx.state.locale), 1)
  ]))
}