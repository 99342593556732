import {SceneItem} from "@/classes/SceneController/abstract/SceneItem.class";
import {Effect, Engine, Mesh, MeshBuilder, ShaderMaterial, Texture, Vector2, Vector3} from "@babylonjs/core";
import SceneController from "@/classes/SceneController.class";
import FlyingDetail from "@/components/LgVangogh/classes/PictureModel/PictureDetails/FlyingDetail.class";

const vertexShader = '' +
  'precision highp float;\n' +
  'attribute vec3 position;\n' +
  'attribute vec3 normal;\n' +
  'attribute vec2 uv;\n' +
  'uniform mat4 worldViewProjection;\n' +
  'varying vec4 vPosition;\n' +
  'varying vec3 vNormal;\n' +
  'varying vec2 vUV;\n' +
  'void main() {\n' +
  '    vec4 p = vec4( position, 1. );\n' +
  '    vPosition = p;\n' +
  '    vNormal = normal;\n' +
  '    vUV = uv;\n' +
  '    gl_Position = worldViewProjection * p;\n' +
  '}'

const fragmentShader = '' +
  'precision highp float;\n' +
  'uniform mat4 worldView;\n' +
  'varying vec4 vPosition;\n' +
  'varying vec3 vNormal;\n' +
  'varying vec2 vUV;\n' +
  'uniform sampler2D textureSampler;\n' +
  'uniform vec2 uPoint;\n' +
  'uniform float uAlpha;\n' +
  'void main(void) {\n' +
  '    vec4 base = texture2D( textureSampler, vUV);\n' +
  '    float distance = 1. - smoothstep(.0, 0.15, distance(vUV, uPoint));\n' +
  '    base.a = uAlpha * distance * base.a * 2.;\n' +
  '    gl_FragColor = base;\n' +
  '}'

export default class PictureDetails extends SceneItem {

  private _parentMesh: Mesh
  private _mesh: Mesh

  private _netPlane!: Mesh
  private _netPlaneMaterial!: ShaderMaterial
  private _netAlpha = 0

  private _time = 0

  private _visible = false
  private _isPlaying = false

  private _flyingDetail!: FlyingDetail
  private _visibleDetailIndex : number | null = null
  private _currentDetailIndex =  -1//-1

  private _interval!: any
  private _pointPosition: Vector2 = new Vector2(.5, .5)
  private _pathIndex = 0

  private _updateDisabled = true

  private _detailsTimeouts = [
    14000,
    30000,
    10000,
    30000,

    12000,
    10000,
    13000,
  ]

  private _detailsPaths : Array<Vector2[]> = [
    [
      new Vector2(0.7, 0.3),
      new Vector2(0.3, 0.3),
      new Vector2(0.3, 0.6),
      new Vector2(0.5, 0.6),
      new Vector2(0.5, 0.7),
    ],
    [
      new Vector2(0.5, 0.2),
      new Vector2(0.2, 0.2),
      new Vector2(0.2, 0.6),
      new Vector2(0.5, 0.6),
    ],
    [
      new Vector2(0.5, 0.3),
      new Vector2(0.7, 0.3),
      new Vector2(0.7, 0.7),
      new Vector2(0.6, 0.7),
    ],
    [
      new Vector2(0.2, 0.8),
      new Vector2(0.2, 0.3),
      new Vector2(0.7, 0.3),
      new Vector2(0.7, 0.5),
    ],

    [
      new Vector2(0.7, 0.3),
      new Vector2(0.3, 0.3),
      new Vector2(0.3, 0.6),
      new Vector2(0.5, 0.6),
      new Vector2(0.5, 0.7),
    ],
    [
      new Vector2(0.5, 0.2),
      new Vector2(0.2, 0.2),
      new Vector2(0.2, 0.6),
      new Vector2(0.5, 0.6),
    ],
    [
      new Vector2(0.5, 0.3),
      new Vector2(0.7, 0.3),
      new Vector2(0.7, 0.7),
      new Vector2(0.6, 0.7),
    ],
  ]

  public get currentDetailIndex() : number {
    return this._currentDetailIndex
  }

  set visible(val : boolean) {
    if (this._visible !== val) {
      this._visible = val

      if (this._visible) {
        this._updateDisabled = false
        this._isPlaying = true

        setTimeout(() => {
          this._startInterval()
        }, 11000)
      } else {
        this.clean()
      }
    }
  }

  get visible() : boolean {
    return this._visible
  }

  constructor({sceneController, parentMesh} : { sceneController: SceneController, parentMesh: Mesh }) {
    super({
      sceneController
    })

    this._parentMesh = parentMesh
    this._mesh = new Mesh('details root', this.scene)
    this._mesh.parent = this._parentMesh
    this._mesh.scaling = Vector3.Zero()

    this._createShaders()
    this._createNetPlane()

    this._flyingDetail = new FlyingDetail({
      sceneController: this.sceneController,
      parentMesh: this._parentMesh
    })

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _startInterval() : void {

    if (this._interval) {
      clearTimeout(this._interval)
      this._interval = null
    }

    if (this._visibleDetailIndex !== null) {
      this._flyingDetail.hideFrame()

      if (this._currentDetailIndex >= this._flyingDetail.framesCount - 1) {
        this.visible = false
      } else {
        if (this._isPlaying) {
          setTimeout(() => {
            this._visibleDetailIndex = null
            this._interval = setTimeout(() => {
              this._startInterval()
            }, 3000)
          }, 3000)
        }
      }

    } else {
      this._currentDetailIndex++
      this._pathIndex = 0

      this._flyingDetail.showFrameWithIndex(this._currentDetailIndex)
      this._visibleDetailIndex = this._currentDetailIndex

      this._interval = setTimeout(() => {
        this._startInterval()
      }, this._detailsTimeouts[this._currentDetailIndex])
    }
  }

  private _stopInterval() : void {
    if (this._interval) {
      clearTimeout(this._interval)
      this._interval = null
    }

    if (this._isPlaying) {
      this._isPlaying = false

      if (this._visibleDetailIndex !== null) {
        this._flyingDetail.hideFrame()
        setTimeout(() => {
          this._visibleDetailIndex = null
        }, 3000)
      }
    }
  }

  private _showDetail(index: number) : void {
    this._visibleDetailIndex = index
    this._flyingDetail.showFrameWithIndex(index)
  }

  private _createShaders() : void {
    Effect.ShadersStore["netVertexShader"] = vertexShader
    Effect.ShadersStore["netFragmentShader"] = fragmentShader

    this._netPlaneMaterial = new ShaderMaterial('net material', this.scene, {
      vertex: 'net',
      fragment: 'net'
    },{
      needAlphaBlending : true,
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection", "textureSampler"],
    })

    const texture: Texture = new Texture('/projects/lg-vangogh/textures/net-texture.png', this.scene)
    texture.hasAlpha = true

    this._netPlaneMaterial.alphaMode = Engine.ALPHA_COMBINE
    this._netPlaneMaterial.setTexture('textureSampler', texture)
    this._netPlaneMaterial.setVector2('uPoint', Vector2.Zero())
    this._netPlaneMaterial.setFloat('uAlpha', this._netAlpha)
  }

  private _createNetPlane() : void {
    this._netPlane = MeshBuilder.CreatePlane('net', {
      size: 2,
      sideOrientation: Mesh.DOUBLESIDE
    }, this.scene)

    this._netPlane.parent = this._mesh
    this._netPlane.position.z = 0.1

    this._netPlane.material = this._netPlaneMaterial
  }

  private _getPathPosition() : Vector2 | null {
    if (this._currentDetailIndex >= -1 && this._currentDetailIndex < this._detailsPaths.length - 1) {
      const pointsList: Vector2[] = this._detailsPaths[this._currentDetailIndex + 1]
      const point:Vector2 = pointsList[this._pathIndex]

      if (Vector2.Distance(this._pointPosition, point) < 0.01) {
        this._pathIndex++
        if (this._pathIndex >= pointsList.length) {
          this._pathIndex = 0
        }
      }
      return pointsList[this._pathIndex]
    }

    return null
  }

  private _update = () : void => {
    if (!this._updateDisabled) {
      const deltaTime = this.sceneController.engine.getDeltaTime()

      if (this._isPlaying) {
        this._flyingDetail.visible = true
        this._mesh.scaling = Vector3.One()

        let toPosition: Vector2 | null

        if (this._flyingDetail.detailVisible) {
          toPosition = this._flyingDetail.frameTextureOffset
        } else {
          const toPoint: Vector2 | null = this._getPathPosition()
          toPosition = toPoint ?? (new Vector2(0.5, 0.5))
        }

        this._netAlpha += ((1 - this._netAlpha) / 1000) * deltaTime

        let delta: Vector2 = toPosition!.subtract(this._pointPosition)

        if (!this._flyingDetail.detailVisible) {
          const k = 0.0004
          delta = delta.normalize().multiplyByFloats(deltaTime * k, deltaTime * k)
        } else {
          delta.x *= 0.1
          delta.y *= 0.1
        }

        const newPosition: Vector2 = this._pointPosition.add(delta)

        if (Vector2.Distance(newPosition, this._pointPosition) > 0.01) {
          this._pointPosition = this._pointPosition.add(delta)
        } else {
          this._pointPosition = newPosition
        }

        this._netPlaneMaterial.setVector2('uPoint', this._pointPosition)
        this._netPlaneMaterial.setFloat('uAlpha', this._netAlpha)
        this._flyingDetail.setTexturePosition(this._pointPosition)

      } else {
        this._flyingDetail.visible = false
        this._netAlpha += ((0 - this._netAlpha) / 100) * deltaTime
        this._netPlaneMaterial.setFloat('uAlpha', this._netAlpha)

        if (this._netAlpha < 0.1) {
          this._mesh.scaling = Vector3.Zero()
        }
      }
    }
  }

  public pause() : void {
    this._stopInterval()
  }

  public resume() : void {
    if (!this._isPlaying) {
      this._isPlaying = true
      this._interval = setTimeout(() => {
        this._startInterval()
      }, 10000)
    }
  }

  public clean() : void {
    if (this._interval) {
      clearTimeout(this._interval)
      this._interval = null
    }

    this._isPlaying = false
    this._currentDetailIndex = -1

    if (this._visibleDetailIndex !== null) {
      this._flyingDetail.hideFrame()
      this._visibleDetailIndex = null
    }
  }
}
