import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Engine, Mesh, PostProcess, RenderTargetTexture, Texture} from "@babylonjs/core";
import isMobile from "is-mobile";

export default class BlobedPostProcess extends SceneItem {

  private _postProcess!: PostProcess
  private _frontRenderMeshes: Mesh[] = []
  private _backRenderMeshes: Mesh[] = []

  private _frontRenderTargetTexture!: RenderTargetTexture
  private _backRenderTargetTexture!: RenderTargetTexture

  constructor(props: SceneItemArgs) {
    super(props)

    const textureSize = isMobile() ? 1024 : 1536

    this._frontRenderTargetTexture = new RenderTargetTexture('front render target', {
      width: textureSize,
      height: textureSize
    }, this.scene)

    this._frontRenderTargetTexture.samples = 4

    this._backRenderTargetTexture = new RenderTargetTexture('back render target', {
      width: textureSize,
      height: textureSize
    }, this.scene)

    this._backRenderTargetTexture.samples = 2


    this.scene.customRenderTargets.push(this._frontRenderTargetTexture)
    this.scene.customRenderTargets.push(this._backRenderTargetTexture)

    this._createPostProcess()
  }

  public addMeshForRender(mesh: Mesh, front = true) : void {
    if (front) {
      this._frontRenderMeshes.push(mesh)
      this._frontRenderTargetTexture.renderList!.push(mesh)
      mesh.getChildMeshes().forEach((mesh) => {
        this._frontRenderTargetTexture.renderList!.push(mesh)
      })
    } else {
      this._backRenderMeshes.push(mesh)
      this._backRenderTargetTexture.renderList!.push(mesh)
      mesh.getChildMeshes().forEach((mesh) => {
        this._backRenderTargetTexture.renderList!.push(mesh)
      })
    }
  }

  private _createPostProcess() : void {

    this._postProcess = new PostProcess(
      'image-mask',
      '/landing/blobed-effects/fx/blobed',
      [],
      ['frontTexture', 'backTexture'],
      1.,
      this.scene.activeCamera!,
      Texture.BILINEAR_SAMPLINGMODE
    )

    this._postProcess.onApply = (effect) => {
      effect.setTexture('frontTexture', this._frontRenderTargetTexture)
      effect.setTexture('backTexture', this._backRenderTargetTexture)
    }
  }

  public dispose() : void {
    this._postProcess.dispose()
    this._frontRenderTargetTexture.dispose()
    this._backRenderTargetTexture.dispose()

    this._frontRenderMeshes = []
    this._backRenderMeshes = []
  }
}
