
import {Options, prop, Vue} from "vue-class-component";
import { reactive } from "vue";
import ProjectScene from "@/components/Landing/MainMenu/LandingProjectPage/ProjectScene.vue";
import ProjectsContents, {ProjectContent} from "@/components/Landing/MainMenu/LandingProjectPage/ProjectsContents.class";
import ProjectTitle from "@/components/Landing/MainMenu/LandingProjectPage/ProjectTitle.vue";
import isMobile from "is-mobile";
import ProjectVideo from "@/components/Landing/MainMenu/LandingProjectPage/ProjectVideo.vue";

class LandingProjectPageProps {
  projectId = prop({})
}

interface LandingProjectPageState {
  currentProjectId: string | null,
  content: ProjectContent | null,
  isMobile: boolean
}

@Options({
  components: {
    ProjectScene,
    ProjectTitle,
    ProjectVideo
  },

  watch: {
    projectId(val) {
      clearTimeout(this.clearTimeout)

      if (val) {
        this.state.currentProjectId = val
        this.updateContent()
      } else {
        this.clearTimeout = setTimeout(() => {
          this.state.currentProjectId = null
        }, 2000)
      }
    }
  }
})
export default class LandingProjectPage extends Vue.with(LandingProjectPageProps) {
  private componentMounted = false
  private scrollTo = 0

  private projectsContents: ProjectsContents = new ProjectsContents()

  public clearTimeout = 0

  private lastTouch: Touch | null = null

  public state: LandingProjectPageState = reactive({
    currentProjectId: null,
    content: null,
    isMobile: false
  })

  public mounted() : void {
    this.componentMounted = true
    this.state.currentProjectId = this.projectId as (string | null)
    this.state.isMobile = isMobile()
    this.updateContent()
    this.requestAnimationFrame()

    document.addEventListener('touchstart', this.onTouchStart)
    document.addEventListener('touchend', this.onTouchEnd)
    document.addEventListener('touchmove', this.onTouchMove)
  }

  public unmounted() : void {
    this.componentMounted = false
    document.removeEventListener('touchstart', this.onTouchStart)
    document.removeEventListener('touchend', this.onTouchEnd)
    document.removeEventListener('touchmove', this.onTouchMove)
  }

  get page() : HTMLDivElement {
    return this.$refs.page as HTMLDivElement
  }

  public onMouseWheel(e: WheelEvent) : void {
    this.scrollTo += e.deltaY

    const contentRect = (this.$refs.content as HTMLDivElement).getBoundingClientRect()
    const heightDelta = contentRect.height - window.innerHeight

    if (this.scrollTo < 0) {
      this.scrollTo = 0
    }

    if (this.scrollTo > heightDelta) {
      this.scrollTo = heightDelta
    }
  }

  private onTouchMove(e: TouchEvent) : void {
    if (this.$refs.content) {
      const touch = e.touches[0]
      const deltaY = touch.clientY - this.lastTouch!.clientY

      this.lastTouch = touch

      this.scrollTo -= deltaY

      const contentRect = (this.$refs.content as HTMLDivElement).getBoundingClientRect()
      const heightDelta = contentRect.height - window.innerHeight

      if (this.scrollTo < 0) {
        this.scrollTo = 0
      }

      if (this.scrollTo > heightDelta) {
        this.scrollTo = heightDelta
      }
    }
  }

  private onTouchStart(e: TouchEvent) : void {
    this.lastTouch = e.touches[0]
  }

  private onTouchEnd(e: TouchEvent) : void {
    this.lastTouch = null
  }

  public updateContent() : void {
    this.state.content = this.projectsContents.get(this.state.currentProjectId!)
    this.scrollTo = 0
  }

  public onNextProjectClick(projectId: string) {
    this.scrollTo = 0
    this.$emit('project', projectId)
    this.$router.push(`/${projectId}`)
  }

  private update() : void {
    if (this.page) this.page.scrollTop += (this.scrollTo - this.page.scrollTop) / 20
  }

  private requestAnimationFrame() : void {
    if (this.componentMounted) {
      window.requestAnimationFrame(() => {
        this.update()
        this.requestAnimationFrame()
      })
    }
  }
}
