
import {setup, Vue} from "vue-class-component";
import {ref, reactive} from 'vue'
import VrSceneController, {VrSceneEvent, VrSceneEvents} from "@/components/Vr/classes/VrSceneController.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class Vr extends Vue {
  private _vrSceneController!: VrSceneController

  public state = reactive({
    xrAvailable: false,
    xrSessionActive: false
  })

  public mounted() : void {
    this._vrSceneController = new VrSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    this._vrSceneController.addEventListener(VrSceneEvents.XRHelperCreated, this._onXRHelperCreated)
    this._vrSceneController.addEventListener(VrSceneEvents.XRSessionStarted, this._onXRSessionStarted)
    this._vrSceneController.addEventListener(VrSceneEvents.XRSessionStopped, this._onXRSessionStopped)
    EventBus.emit(EventBusEvents.SceneLoaded)
  }

  public unmounted() : void {
    this._vrSceneController.removeEventListener(VrSceneEvents.XRHelperCreated, this._onXRHelperCreated)
    this._vrSceneController.removeEventListener(VrSceneEvents.XRSessionStarted, this._onXRSessionStarted)
    this._vrSceneController.removeEventListener(VrSceneEvents.XRSessionStopped, this._onXRSessionStopped)

    this._vrSceneController.dispose()
  }

  private _onXRHelperCreated (e: Event) : void {
    const available = (e as VrSceneEvent).data as boolean
    this.state.xrAvailable = available
  }

  private _onXRSessionStarted() : void {
    this.state.xrSessionActive = true
  }

  private _onXRSessionStopped() : void {
    this.state.xrSessionActive = false
  }

  public onStartXrSessionClick() : void {
    this._vrSceneController.enterToXR()
  }
}
