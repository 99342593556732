import {Camera, Matrix, Mesh, ShaderMaterial, Vector3} from "@babylonjs/core";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import BlobedCapsulesModel from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedCapsulesModel.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class CapsuleItemModel extends BaseBlob {
  private _parentModel!: BlobedCapsulesModel
  private _positionIndex = 0
  private _sPosition = Vector3.Zero()
  private _sScaling = 1

  private _frontChildMesh!: Mesh
  private _backChildMesh!: Mesh
  private _rotationY = 0

  constructor(props: SceneItemArgs) {
    super(props);

    this._linesCount = 25
    this._lineThickness = 0.4

    this._speed = 0.00005

    //this._createMaterial()
    this._createStaticCustomPbr()
    this._createCapsule()

    // this._meshFront.scaling = new Vector3(0.2, 0.2, 0.2)
    // this._meshBack.scaling = new Vector3(0.2, 0.2, 0.2)
  }

  protected _createStaticCustomPbr() {
    super._createStaticCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/capsule-satellite/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
  }

  protected _update() {
    super._update()

    if (this._meshFront) {
      const position = this._meshFront.position
      this._meshFront.position = this._meshBack.position = position.add(new Vector3(0, Math.sin(this._time * 0.001 + Math.PI * this._positionIndex * .8) * 0.02, 0))
      this._meshBack.position = this._meshFront.position

      this._backChildMesh.rotation = this._frontChildMesh.rotation = new Vector3(0, this._rotationY, 0)
    }
  }

  protected _onMouseMove(e: MouseEvent) {
    // this._rotation.x = -Math.PI * .5
    this._rotationY = e.clientX * 0.001 - 0.2
  }

  public setParentModel(model:BlobedCapsulesModel, index: number) : void {
    this._parentModel = model
    this._positionIndex = index

    switch (index) {
      case 0:
        this._sPosition.x = -0.5
        this._sPosition.y = -0.6
        this._sPosition.z = 0.2

        this._sScaling = 0.2
        break

      case 1:
        this._sPosition.x = -0.2
        this._sPosition.y = 0.98
        this._sPosition.z = 0.5

        this._sScaling = 0.15
        break

      case 2:
        this._sPosition.x = 1.2
        this._sPosition.y = 0.9
        this._sPosition.z = 2.2

        this._sScaling = 0.15
        break
    }

    this._sPosition = this._sPosition.multiplyByFloats(0.5, 0.5, 0.5).subtract(new Vector3(0,0,1))
  }


  // private _createMaterial() {
  //   this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/landing/capsule-satellite/fx/blob", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //   this._materialFront.backFaceCulling = false
  //
  //   this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/capsule-satellite/fx/blob-back", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //
  //   this._materialBack.backFaceCulling = true
  //
  //   this._createLights()
  // }

  private _createCapsule() : void {

    this.loadModelFromUrl('/landing/capsule-satellite/model.glb', false, false).then((assetContainer) => {
      const originMesh:Mesh = assetContainer.meshes.filter((mesh) => mesh.name === 'Capsule')[0] as Mesh
      const parentMesh = new Mesh('ico-root', this.scene)

      originMesh.parent = parentMesh
      originMesh.position = Vector3.Zero()

      this._meshFront = parentMesh.clone()
      this._meshBack = parentMesh.clone()

      this._meshFront.getChildMeshes().forEach((mesh) => {
        if (mesh.material) {
          mesh.material = this._customFrontMaterial
          this._frontChildMesh = mesh as Mesh
        }
      })

      this._meshBack.getChildMeshes().forEach((mesh) => {
        const meshInstance : Mesh = mesh as Mesh
        meshInstance.makeGeometryUnique()
        meshInstance.flipFaces(true)
        mesh.material = this._materialBack
        this._backChildMesh = mesh as Mesh
      })

      this._position.z = -2

      parentMesh.dispose()

      this._meshFront.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)
      this._meshBack.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)

      this._meshFront.layerMask = 2
      this._meshBack.layerMask = 2

      this.blobedPostProcess.addMeshForRender(this._meshFront, true)
      this.blobedPostProcess.addMeshForRender(this._meshBack, false)


      EventBus.emit(EventBusEvents.BlobedCapsulesLoaded)
    })
  }

  public show(direction = 1) : void {
    this._visible = true

    setTimeout(() => {
      this._position = this._sPosition.add(new Vector3(0, 3 * direction, 0))
      this.positionTo(this._sPosition, 2)
      this.scaleTo(new Vector3(this._sScaling, this._sScaling, this._sScaling), 0.1)
    }, 500 * this._positionIndex)
  }

  public hide() : void {
    this._visible = false

    this.positionTo(this._sPosition.add(new Vector3(0,0, -2)), 2)
  }
}
