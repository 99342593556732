import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {PostProcess, Vector2} from "@babylonjs/core";

export default class CircleShaderSceneItem extends SceneItem {
  private _time = 0
  private _postProcess!: PostProcess;


  constructor(props: SceneItemArgs) {
    super(props)
    this._createEffect()

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
  }

  private _createEffect() {
    const circlePostProcess = new PostProcess(
      'occlusion',
      '/projects/shaders-arts/fx/circle',
      ['time', 'screenSize'],
      [],
      1.,
      this.camera
    )

    circlePostProcess.onApply = (effect) => {
      effect.setFloat('time', this._time)
      effect.setVector2('screenSize', new Vector2(circlePostProcess.width, circlePostProcess.height))
    }

    this._postProcess = circlePostProcess
  }

  public dispose() : void {
    this._postProcess.dispose(this.camera)
  }
}
