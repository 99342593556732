import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {Camera, Matrix, Mesh, MeshBuilder, ShaderMaterial, Vector3} from "@babylonjs/core";
import VrBlobModel from "@/components/Landing/Background/classes/sceneItems/blobs/VrBlobModel.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class VrBlobSatelliteModel extends BaseBlob {
  private _parentModel!: VrBlobModel
  private _positionIndex = 0
  private _sPosition = Vector3.Zero()
  private _sRotation = Vector3.Zero()
  private _sScaling = 1

  constructor(props: SceneItemArgs) {
    super(props)

    this._linesCount = 60
    this._lineThickness = 0.25
    this._speed = -0.000006

    //this._createMaterials()
    this._createStaticCustomPbr()
    this._createModel()
  }

  protected _createStaticCustomPbr() {
    super._createStaticCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/vr-blobed-satellite/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
  }

  protected _update() {
    this._time += this.sceneController.engine.getDeltaTime()

    if (this._materialBack) {

      this._materialBack.setFloat('time', this._time)
      this._materialBack.setFloat('speed', this._speed)
      this._materialBack.setFloat('radius', this._radius)
      this._materialBack.setFloat('linesCount', this._linesCount)
    }

    if (this._meshFront) {

      this._meshFront.position = this._sPosition
      this._meshFront.scaling = this._meshBack.scaling = new Vector3(this._sScaling, this._sScaling, this._sScaling)
      this._meshFront.rotation = this._meshBack.rotation = this._sRotation

      this._sRotation.y += this._sScaling * 0.1

      this._meshBack.position = this._meshFront.position
    }
  }

  private _createMaterials() : void {
    this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/landing/vr-blobed-satellite/fx/blob", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })
    this._materialFront.backFaceCulling = false

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/vr-blobed-satellite/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
    this._createLights()
  }

  private _createModel() : void {
    this._meshFront = MeshBuilder.CreateSphere('vr-sphere-satellite', {
      diameter: 0.2,
      sideOrientation: Mesh.FRONTSIDE
    }, this.scene)

    this._meshBack = MeshBuilder.CreateSphere('vr-sphere-satellite', {
      diameter: 0.2,
      sideOrientation: Mesh.BACKSIDE
    }, this.scene)

    this._meshFront.material = this._customFrontMaterial
    this._meshBack.material = this._materialBack

    this._meshFront.layerMask = 2
    this._meshBack.layerMask = 2

    this.blobedPostProcess.addMeshForRender(this._meshFront, true)
    this.blobedPostProcess.addMeshForRender(this._meshBack, false)

    EventBus.emit(EventBusEvents.BlobedVrSatelliteLoaded)
  }

  public setParentModel(model: VrBlobModel, positionIndex: number) : void {
    this._parentModel = model
    this._positionIndex = positionIndex

    this._meshFront.parent = this._parentModel.meshFront
    this._meshBack.parent = this._parentModel.meshFront

    this._meshFront.position = this._meshBack.position = Vector3.Zero()
    this._meshFront.scaling = this._meshBack.scaling = Vector3.One()

    switch (positionIndex) {
      case 0:
        this._sPosition = new Vector3(-0.3, -0.2, -0.5)
        this._sScaling = 1.3
        break

      case 1:
        this._sPosition = new Vector3(-0.6, 0.4, 0.7)
        this._sScaling = 0.7
        this._linesCount = 25
        this._lineThickness = 0.1
        break

      case 2:
        this._sPosition = new Vector3(0.4, -0.1, 0.4)
        this._linesCount = 40
        this._lineThickness = 0.15
        break
    }
  }
}
