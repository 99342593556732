import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "background" }
const _hoisted_2 = { class: "canvas-container" }
const _hoisted_3 = { ref: "canvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_backgroundText = _resolveComponent("backgroundText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512)
    ]),
    _createVNode(_component_backgroundText, {
      sceneState: _ctx.state.sceneState
    }, null, 8, ["sceneState"])
  ]))
}