
import {Options, Vue} from "vue-class-component";
import SlideButton from "@/components/Landing/ui/SlideButton.vue";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import LangSwitcher from "@/components/Landing/MainMenu/LangSwitcher.vue";

@Options({
  components: {
    SlideButton,
    LangSwitcher
  }
})
export default class MainMenuContent extends Vue {
  onMenuItemClick(alias: string) {
    /*if (alias === 'contacts') {
      EventBus.emit(EventBusEvents.ContactsPageOpened, true)
    } else {
      this.$emit('selected', alias)
    }*/
    this.$router.push(`/${alias}`)
  }
}
