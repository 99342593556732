/* eslint-disable */
import {Mesh, SceneLoader, Space, TransformNode, Vector3} from "@babylonjs/core"
import * as BABYLON from "@babylonjs/core"
import "@babylonjs/loaders"
import isMobile from "is-mobile";

class TourGlbController extends EventTarget {

  static Events = {
    ready: 'ready'
  }

  /**
   *
   * @type {String[]}
   * @private
   */
  _warmingBrowserImagesUrls
  _warmingStarted = false

  /**
   * @type {String}
   * @private
   */
  _modelName

  /**
   * @type {TourSceneController}
   * @private
   */
  _sceneController

  /**
   * @type {Mesh}
   * @private
   */
  _mesh

  _disposed = false

  get _scene() {
    return this._sceneController.scene
  }

  constructor({modelName, sceneController}) {
    super()
    this._modelName = modelName
    this._sceneController = sceneController

    this._loadModel()
  }

  /**
   * По тихому в фоне прогреваем кеш браузера на все картинки
   */
  startWarming() {
    if (!this._disposed && this._warmingBrowserImagesUrls.length > 0) {
      const url = this._warmingBrowserImagesUrls.shift()

      const image = new Image()
      image.src = url
      image.onload = () => {
        image.remove()
        this.startWarming()
      }
    }
  }

  _loadModel() {
    this._mesh = new Mesh('cuber_root', this._scene)
    const meshContainer = new BABYLON.Mesh('mapped_area_container', this._scene)

    SceneLoader.LoadAssetContainer('/projects/tours/models/', `${this._modelName}.glb`, this._scene, (container) => {
      container.meshes.forEach(meshItem => {
        meshItem.isPickable = true
        if (meshItem.material) {
          meshItem.material.hasAlpha = true
          meshItem.visibility = 0
        }
        if (!meshItem.parent) meshItem.parent = meshContainer
      })

      container.addAllToScene()

      meshContainer.parent = this._mesh

      this._onModelLoaded()
    })
  }

  _onModelLoaded = () => {
    const initPos = this._mesh.getChildTransformNodes().filter(mesh => mesh.name.indexOf('Map_01') >= 0 )[0].position
    const pivot = new TransformNode('root')
    pivot.position = initPos.clone()
    this._mesh.parent = pivot
    pivot.rotate(Vector3.Up(), Math.PI / 2, Space.WORLD)

    setTimeout(() => {
      this._hideCubes()
      this.dispatchEvent(new Event(TourGlbController.Events.ready))
    }, 10)
  }

  createCubemapWarmingUrls({id, resolution}) {
    if (!this._warmingBrowserImagesUrls) this._warmingBrowserImagesUrls = []

    const pList = ['pz', 'nz', 'px', 'nx', 'py', 'ny']
    pList.forEach(p => {
      this._warmingBrowserImagesUrls.push(`/projects/tours/builded-maps/${this._modelName}/${id}/${resolution}/Panorama${id}_${p}.jpg`)
    });
  }

  getCubesData() {
    const dataList = []

    if (this._mesh) {
      this._mesh.getChildTransformNodes().filter(mesh => mesh.name.indexOf('CubeMap') >= 0 ).forEach(mesh => {
        const parentNode = mesh.parent && mesh.parent.name.indexOf('CubeMap') >= 0 ? mesh.parent : mesh

        const lowRes = '1024'
        const hiRes = '2048'

        const splitted = parentNode.name.split('_')
        const id = parseInt(splitted[splitted.length - 1])

        // const host = window.location.host.indexOf('localhost:') >= 0 ? 'https://afi-park-pano.dev.immercyb.com' : '//' + window.location.host
        // const host = '//localhost:8080'

        dataList.push({
          url: '/projects/tours/builded-maps/' + this._modelName + `/${id}/` + lowRes + '/Panorama' + id,
          hqUrl: '/projects/tours/builded-maps/' + this._modelName + `/${id}/` + hiRes +'/Panorama' + id,
          position: parentNode.getAbsolutePosition().clone(),
          id: parentNode.name
        })

        this.createCubemapWarmingUrls({
          id,
          resolution: isMobile() ? lowRes : hiRes
        })

      })

      if (!this._warmingStarted) {
        this._warmingStarted = true
        this.startWarming()
      }
    }

    return dataList
  }

  _hideCubes() {
    this._mesh.getChildMeshes().filter(mesh => mesh.name.indexOf('CubeMap') >= 0 ).forEach(mesh => {
      mesh.scaling = Vector3.Zero()
    })
  }

  dispose() {
    this._disposed = true
    if (this._mesh) {
      this._mesh.dispose()
    }
  }
}

export default TourGlbController
