import {App, reactive} from "vue";
import { ref } from "vue";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

interface PluginOptions {
  messages: Map<string, unknown>,
  defaultLocale: string | undefined,
  locale: string | undefined,
}

class Translator {
  private _messages!: Map<string, unknown>
  private _locale = 'en'
  private _reactiveMessages : any = {}

  public setMessages(messages: Map<string, unknown>) : void {
    this._messages = messages

    const reactiveMessages : any = {}
    const keys = Array.from(this._messages.keys()) as string[]

    keys.forEach((key) => {
      const data : any = messages.get(key)
      reactiveMessages[key] = data[this._locale]
    })

    this._reactiveMessages = reactive(reactiveMessages)
  }

  public setLocale(locale: string) {
    if (this._locale !== locale) {
      this._locale = locale
      const keys = Array.from(this._messages.keys()) as string[]

      keys.forEach((key) => {
        const data: any = this._messages.get(key)
        this._reactiveMessages[key] = data[this._locale]
      })
    }
  }

  public getLocale() : string {
    return this._locale
  }

  public translate(key: string) : string {
    const data = this._reactiveMessages[key]
    if (data) {
      return data
    } else {
      return `!!!!Translation not found for key: "${key}"`
    }
  }
}

const I18n = {
  translator: new Translator(),
  install(app: App, options: PluginOptions) {

    this.translator.setMessages(options.messages)
    const initialLocale = window.location.host.indexOf('immercyb.ru') >= 0 ? 'ru' : 'en'
    this.translator.setLocale(options.defaultLocale || initialLocale)

    app.config.globalProperties.$currentLocale = ref(this.translator.getLocale())

    app.config.globalProperties.$tr = (message: string) => {
      return this.translator.translate(message)
    }

  },

  setLocale(locale: string) : void {
    this.translator.setLocale(locale)
    EventBus.emit(EventBusEvents.LocaleChanged)
  },

  getLocale() : string {
    return this.translator.getLocale()
  }
}

export default I18n
