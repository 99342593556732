
import SlideMixin from "./SlideMixin";
import {Options} from "vue-class-component";
import CircleButton from "@/components/Landing/ui/CircleButton.vue";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

@Options({
  components: {
    CircleButton
  }
})
export default class FinalSlide extends SlideMixin {
  public onGetInTouchClick() : void {
    //EventBus.emit(EventBusEvents.ContactsPageOpened, true)
    this.$router.push({
      path: '/contacts'
    })
  }

  public onScrollUpClick() : void {
    EventBus.emit(EventBusEvents.ScrollUp)
  }
}
