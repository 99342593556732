
import EventBusEvents from "@/classes/EventBusEvents";
import EventBus from "@/classes/EventBus.class";
import {prop, Vue} from "vue-class-component";
import SceneController from "../../../../classes/SceneController.class";
import JettaSceneContoller from "../../../JettaProject/classes/JettaSceneContoller.class";
import { reactive } from "vue";
import TourSceneController from "@/components/MeshedTour/classes/TourSceneController.class";
import KrausSceneController, {KrausSceneControllerEvents} from "@/components/Kraus/classes/KrausSceneController.class";

interface CubeMapData {
  name: string
  title: string
  points: []
  initialPointId: string,
  initRotation: number
}

class ProjectSceneProps {
  projectId : string = prop({
    type: String,
    required: true
  })
}

export default class ProjectScene extends Vue.with(ProjectSceneProps) {
  private sceneController!: SceneController
  private tourSceneController!: TourSceneController

  public state = reactive({
    sceneLoaded: false
  })

  private _createTimeout!: any

  public mounted() : void {
    this.createSceneController()
    EventBus.addEventListener(EventBusEvents.SceneLoaded, this.onSceneLoaded)
  }

  public unmounted() : void {
    clearTimeout(this._createTimeout)

    if (this.tourSceneController) {
      this.tourSceneController.removeEventListener(TourSceneController.Events.loadComplete, this.onTourLoadComplete)
      this.tourSceneController.dispose()
    }

    if (this.sceneController) {
      this.sceneController.removeEventListener(KrausSceneControllerEvents.ModelsLoaded, this.onKrausModelsLoaded)
      this.sceneController.dispose()
    }

    EventBus.removeEventListener(EventBusEvents.SceneLoaded, this.onSceneLoaded)
  }

  private createSceneController() : void {
    this._createTimeout = setTimeout(() => {
      switch (this.projectId) {
        case 'jetta':
          this.sceneController = new JettaSceneContoller({
            canvas: this.$refs.canvas as HTMLCanvasElement
          })
          break

        case 'kraus':
          this.sceneController = new KrausSceneController({
            canvas: this.$refs.canvas as HTMLCanvasElement
          })

          this.sceneController.addEventListener(KrausSceneControllerEvents.ModelsLoaded, this.onKrausModelsLoaded)
          break

        case 'tour': {

          const cubeMapData: CubeMapData = {
            name: '3k_room1',
            title: '3к квартира 1',
            points: [],
            initialPointId: 'CubeMap_01',
            initRotation: Math.PI
          }

          this.tourSceneController = new TourSceneController({
            canvas: this.$refs.canvas as HTMLCanvasElement,
            settings: {
              cubeSize: 3.6,
              cameraAcceleration: 0.001
            }
          })

          this.tourSceneController.addEventListener(TourSceneController.Events.loadComplete, this.onTourLoadComplete)

          this.tourSceneController.createTour({
            modelName: cubeMapData.name,
            cameraRotation: cubeMapData.initRotation,
            startFromCubeWithId: cubeMapData.initialPointId ? cubeMapData.initialPointId : '',
            // locationTags: this.cubeMapData.locationPoints
          })
        }

          break
      }
    }, 4000)
  }

  private onTourLoadComplete() : void {
    EventBus.emit(EventBusEvents.SceneLoaded)
  }

  private onKrausModelsLoaded() : void {
    EventBus.emit(EventBusEvents.SceneLoaded)
  }

  private onSceneLoaded() : void {
    this.state.sceneLoaded = true
  }
}
