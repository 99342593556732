
import {Vue} from "vue-class-component"
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class Logo extends Vue {
  public onLogoClick() : void {
    EventBus.emit(EventBusEvents.ScrollUp)
    this.$router.push('/')
  }
}
