const messages: Map<string, unknown> = new Map([
  [
    'XR solutions for Web,<br/>Mobile and Headsets', {

      en: 'XR solutions for Web,<br/>Mobile and Headsets',
      ru: 'Мультиплатформенные XR-решения<br/>Web, Mobile, Headsets'

    }
  ],
  [
    'Coding magic', {
      en: 'Coding magic',
      ru: 'Программируем магию'
    }
  ],
  [
    'Immersive and Interactive 3D', {
      en: 'Immersive and Interactive 3D',
      ru: 'Иммерсивное интерактивное 3D'
    }
  ],

  [
    'Market leaders', {
      en: 'Market leaders',
      ru: 'Лидеры рынка'
    }
  ],
  [
    'in Web3D, experts', {
      en: 'in Web3D, experts',
      ru: 'в области Web3D'
     }
  ],
  [
    'in AR and VR', {
      en: 'in AR and VR',
      ru: 'эксперты по AR и VR'
    }
  ],
  [
    'We create immersive and interactive<br/>experiences using cutting-edge<br/>technologies, best creative ideas<br/>and top level design.', {
      en: 'We create immersive and interactive<br/>experiences using cutting-edge<br/>technologies, best creative ideas<br/>and top level design.',
      ru: 'Создаем иммерсивные интерактивные<br/>продукты, используя новейшие<br/>технологии, лучшие креативные идеи<br/>и безупречный дизайн.'
    }
  ],

  [
    'The technologies we use allow us to construct eye-catching, functional and multi-task products, such as auto-configurators, building design construction tools, 2D to 3D solutions, communication platforms, metaverses and so on.', {
      en: 'The technologies we use allow us to construct eye-catching, functional and multi-task products, such as auto-configurators, building design construction tools, 2D to 3D solutions, communication platforms, metaverses and so on.',
      ru: 'Используемые нами технологии позволяют создавать<br/>не только красивые, но и очень функциональные<br/>решения, такие как автоконфигураторы, конструкторы<br/>зданий, 2D-to-3D визуализаторы, 3D-туры,<br/>коммуникационные платформы и метавселенные.\n'
    }
  ],
  [
    'View<br/>projects', {
      en: 'View<br/>projects',
      ru: 'Смотреть<br/>проекты'
    }
  ],
  [
    'Web3D is state-of-the-art interactive instrument that is perfect for presenting any type of information like storytelling or educating.', {
      en: 'Web3D is state-of-the-art interactive instrument that is perfect for presenting any type of information like storytelling or educating.',
      ru: 'Web3D это ультрасовременный интерактивный инструмент для презентации любого типа информации, от сторителлинга до обучения.'
    }
  ],

  [
    'Experiencing', {
      en: 'Experiencing',
      ru: 'Ощущение'
    }
  ],
  [
    'an event', {
      en: 'an event',
      ru: 'события'
    }
  ],

  [
    'We provide new and unique combinations of technologies, methods and pipelines, that help us find the most striking but at the same time, effective way to solve any problem and achieve desired objective', {
      en: 'We provide new and unique combinations of technologies, methods and pipelines, that help us find the most striking but at the same time, effective way to solve any problem and achieve desired objective',
      ru: 'Мы находим новые уникальные комбинации технологий, методов и пайплайнов, позволяющие решать стоящие перед нами задачи наиболее ярким, и, в тоже время, эффективным способом.'
    }
  ],
  [
    'We are already living out a lot of alternative realities in AR, while using our mobile gadgets. The future is here!', {
      en: 'We are already living out a lot of alternative realities in AR, while using our mobile gadgets. The future is here!',
      ru: 'Мы уже проживаем множество<br/>“дополненных” жизней в AR,<br/>ежедневно используя свои мобильные<br/>гаджеты. Будущее наступило!'
    }
  ],
  [
    'We are using all this to experience something new, to surround ourselves with digital art, and get the ultimate impression from interacting with content.', {
      en: 'We are using all this to experience something new, to surround ourselves with digital art, and get the ultimate impression from interacting with content.',
      ru: 'Используем всё это, чтобы проживать новый опыт, окружать себя цифровым искусством, получать новые незабываемые впечатления от взаимодействия с контентом.'
    }
  ],

  [
    'Transferring the user to a Virtual Reality is the easiest way to connect his audio-visual sensors to your communication, especially in experience simulators, events or product presentations.', {
      en: 'Transferring the user to a Virtual Reality is the easiest way to connect his audio-visual sensors to your communication, especially in experience simulators, events or product presentations.',
      ru: 'Перенос пользователя в виртуальную реальность — это самый прямой способ подключения его аудиовизуальных сенсоров к вашей коммуникации, особенно если речь идет о симуляции опыта, ивенте или презентации продукта.'
    }
  ],

  [
    'Metaverse spaces<br/>Interactive presentations<br/>Educational apps', {
      en: 'Metaverse spaces<br/>Interactive presentations<br/>Educational apps',
      ru: 'Пространства метавселенных<br/>Интерактивные презентации<br/>Обучающие приложения'
    }
  ],
  [
    'Configurators<br/>Car simulators<br/>Games<br/>360-Videos', {
      en: 'Configurators<br/>Car simulators<br/>Games<br/>360-Videos',
      ru: 'Конфигураторы<br/>Автосимуляторы<br/>Игры<br/>360-Видео'
    }
  ],

  [
    'Configurators<br/>Car simulators<br/>Games<br/>360-Videos', {
      en: 'Configurators<br/>Car simulators<br/>Games<br/>360-Videos',
      ru: 'Конфигураторы<br/>Автосимуляторы<br/>Игры<br/>360-Видео'
    }
  ],

  [
    'Deep', {
      en: 'Deep',
      ru: 'Глубокое'
    }
  ],

  [
    'immersion', {
      en: 'immersion',
      ru: 'погружение'
    }
  ],

  [
    'Get in touch', {
      en: 'Get in touch',
      ru: 'Контакты'
    }
  ],
  [
    'Scroll Up', {
      en: 'Scroll Up',
      ru: 'Вверх'
    }
  ],
  [
    'Developing magic since 2020', {
      en: 'Developing magic since 2020',
      ru: 'Разрабатываем магию с 2020'
    }
  ],
  [
    '© 2022. All rights reserved', {
      en: '© 2022. All rights reserved',
      ru: '© 2022. Все права защищены'
    }
  ],

  [
    'Contacts', {
      en: 'Contacts',
      ru: 'Контакты'
    }
  ],
  [
    'If you have any questions,<br/>just let us know', {
      en: 'If you have any questions,<br/>just let us know',
      ru: 'Будем рады ответить<br/>на любые вопросы'
    }
  ],
  [
    'Mail', {
      en: 'Mail',
      ru: 'Почта'
    }
  ],
  [
    'Socials', {
      en: 'Socials',
      ru: 'Социальные сети'
    }
  ],
  [
    'Send message', {
      en: 'Get in touch',
      ru: 'Напишите нам'
    }
  ],
  [
    'Send message', {
      en: 'Get in touch',
      ru: 'Напишите нам'
    }
  ],
  [
    'Send form', {
      en: 'Get in touch',
      ru: 'Обратная связь'
    }
  ],
  [
    'Your name', {
      en: 'Your name',
      ru: 'Ваше имя'
    }
  ],
  [
    'E-mail/Phone', {
      en: 'E-mail/Phone',
      ru: 'Почта/Телефон'
    }
  ],
  [
    'Message', {
      en: 'Message',
      ru: 'Текст сообщения'
    }
  ],
  [
    'Send', {
      en: 'Send',
      ru: 'Отправить'
    }
  ],

  [
    'Scroll Down', {
      en: 'Scroll Down',
      ru: 'Вниз'
    }
  ],
  [
    'Keep scrolling!', {
      en: 'Keep scrolling!',
      ru: 'Ещё вниз!'
    }
  ],

  [
    'Menu', {
      en: 'Menu',
      ru: 'Меню'
    }
  ],
  [
    'Close', {
      en: 'Close',
      ru: 'Закрыть'
    }
  ],
  [
    'Home', {
      en: 'Home',
      ru: 'Главная'
    }
  ],
  [
    'Projects', {
      en: 'Projects',
      ru: 'Проекты'
    }
  ],
  [
    'Back', {
      en: 'Back',
      ru: 'Назад'
    }
  ],
  [
    'Immersive<br/>Cybernetics', {
      en: 'Immersive<br/>Cybernetics',
      ru: 'Иммерсивная<br/>Кибернетика'
    }
  ],
  [
    'Thank you, your message has been sent! We will contact you as soon as possible.', {
      en: 'Thank you, your message has been sent!<br/>We will contact you as soon as possible.',
      ru: 'Спасибо, ваше сообщение отправлено!<br/>Мы свяжемся с вами в самое ближайшее время.'
    }
  ],

  [
    'View now', {
      en: 'View now',
      ru: 'Посмотреть'
    }
  ],

  [
    'Jetta subtitle', {
      en: '3D Showroom for New Volkswagen Jetta',
      ru: '3D Шоурум для Новой Volkswagen Jetta'
    }
  ],
  [
    'jetta column-1', {
      en: 'Not a single step aside from  your computer - this is the opportunity provided by the VW Youniverse virtual space to enjoy the New Volkswagen Jetta showroom. Additional features include a test drive request, car availability check as well as an offer from the nearest dealer.<br/>' +
        '<br/>' +
        'To make things more realistically-looking we are presenting the car in a 3D environment created in strict accordance with the Volkswagen exhibition guidelines.',
      ru: 'Создание виртуального выставочного пространства VW Youniverse позволило аудитории бренда посетить шоурум Нового Volkswagen Jetta не отрываясь от экранов своих компьютеров и телефонов. Там же можно было записаться на тест-драйв, узнать о наличии и запросить предложение у ближайшего дилера.<br/>' +
        '<br/>' +
        'Для большей реалистичности мы разместили автомобиль на подиуме в 3D-сцене, сделанной по выставочным гайдлайнам компании Volkswagen.'
    }
  ],
  [
    'jetta column-2', {
      en: 'Customers have an opportunity to better accustom themselves with a new VW model by using numerous options provided:<br/>' +
        'To choose the body color,<br/>' +
        'To have a detailed look of the interior,<br/>' +
        'To choose an upholstery,<br/>' +
        'To select a disk type,<br/>' +
        'To open doors and trunk,<br/>' +
        'To turn on front, stop and rear lights as well as to check a night driving mode,<br/>' +
        'To check interior lighting,<br/>' +
        'To look through 10 main USP features.<br/>' +
        '<br/>' +
        'This project was carried into effect for VW and LarniLane event agency.',
      ru: 'Пользователи смогли ближе познакомиться с новой моделью VW, получив доступ к разнообразному функционалу конфигуратора: <br/>' +
        'Выбор цветов кузова<br/>' +
        'Осмотр интерьера<br/>' +
        'Выбор обшивки салона<br/>' +
        'Подбор дисков<br/>' +
        'Открывание дверей и багажника<br/>' +
        'Включение фар и габаритов, ночной режим<br/>' +
        'Управление внутренней подсветкой салона<br/>' +
        'Просмотр десяти основных USP автомобиля<br/>' +
        '<br/>' +
        'Проект реализован для компании Volkswagen и Ивент-агентства LarniLane.<br/>'
    }
  ],

  [
    'Lg Vangogh subtitle', {
      en: 'Vincent Van Gogh and “The Red Vineyards near<br/>Arles” for LG Signature',
      ru: 'Винсент Ван Гог и “Красные виноградники<br/>в Арле” для LG Signature'
    }
  ],
  [
    'Lg Vangogh column-1', {
      en: 'A powerful combination of Art and Technology is realized in WebAR using the 8th Wall platform. The customer could get to AR features directly from the browser without any need to install a mobile app. The whole presentation is divided into several topics detailing the history of the painting and the Van Gogh bio.',
      ru: 'Кейс про иммерсивный WebAR - переплетение искусства и технологий в Дополненной реальности. Реализовано на основе 8th Wall в вебе. То есть оказаться в AR можно сразу из браузера, без необходимости устанавливать на телефон мобильное приложение. Всё действие разделено на несколько отдельных сцен, связанных с историей происхождения картины и биографией самого Ван Гога.'
    }
  ],
  [
    'Lg Vangogh column-2', {
      en: 'Please join LG Signature in an AR journey to Arles with the opportunity to have a look at the vineyard blessed by an autumn sun and to come across and enjoy the secrets of the great painting.<br/>' +
        '<br/>' +
        'Created by partnership of LG with the Pushkin State Museum of Fine Arts with the collaboration of very creative guys from Immerse Lab.',
      ru: 'Вместе с LG SIGNATURE, предлагаем вам совершить AR-путешествие в Арль: оказаться среди залитых осенним солнцем виноградников, обнаружить и раскрыть тайны великого шедевра.<br/>' +
        '<br/>' +
        'Создано благодаря партнерству LG с Пушкинским музеем (ГМИИ имени Пушкина), в коллаборации с очень креативными ребятами из Immerse Lab.'
    }
  ],

  [
    'Kraus subtitle', {
      en: '2D-3D Configuration Tool and Calculator<br/>for Kraus Construction Company',
      ru: '2D-3D Конфигуратор + Калькулятор<br/>для Строительной Компании Краус'
    }
  ],
  [
    'Kraus column-1', {
      en: 'An innovative tool for the business construction combines a configurator of the prefab buildings and a feature to calculate the construction costs depending on particular design.<br/>' +
        '<br/>' +
        'Prefab buildings could be made with two types of blocks - either lath or sandvich-paneled. While using a 2D tool the customer with the help of a simple drag-and-drop menu could create the general outline of the building with all the particulars - floors and rooms with all necessary features like windows, doors, partition walls, staircases inside and outside, bath and toilet equipment, waterproofing and thermal insulation, electric wiring, indoor and outdoor finish with various colors and materials, flooring, roofing and frame types. After that he has an option to see the image in 3D and to have a printout with 2D design and floor plans, with 3D image of the prefab building in 6 projections, full cost estimate as well as the calculation of the assembling and delivering to the address indicated on the map.',
      ru: 'Уникальное решение для строительного рынка - конфигуратор модульных зданий и, одновременно, калькулятор для расчета стоимости здания в зависимости от конфигурации.<br/>' +
        '<br/>' +
        'Модульные здания строятся из контейнеров 2-х типов - обрешеточных и сэндвич-панельных. В 2D-редакторе клиент, с помощью простых инструментов drag-and-drop, может сам создать схему здания со всеми помещениями и этажами, добавить всё необходимое - окна, двери, перегородки, внутренние и внешние лестницы, сантехнику, гидроизоляцию, утепление, электричество, выбрать внутреннюю и внешнюю отделку, ее цвета и материалы, настроить полы, типы кровли и каркаса, и потом посмотреть на получившееся здание в 3D, получить оформленную заявку с 2D-схемами, планами этажей, 3D-изображениями модульного здания в шести проекциях, полный расчет его стоимости, а также расчет монтажа и доставки до выбранного на карте адреса.'
    }
  ],
  [
    'Kraus column-2', {
      en: 'Company managers could use an admin option to work with all customer requests and correlate the costs of various elements of the prefab buildings. The tool includes step-by-step learning options for 2D and 3D modes.<br/>',
      ru: 'Для менеджеров компании создан удобный административный интерфейс, в котором можно обрабатывать все полученные от клиентов заявки, а также редактировать стоимости отдельных составляющих модульных зданий.<br/>' +
        'В продукте также предусмотрены подробные обучающие подсказки для 2D и 3D режимов.'
    }
  ],

  [
    'Tours subtitle', {
      en: 'Render and Scan 3D-Tours<br/>for Web and Mobile',
      ru: 'Виртуальные 3D-туры на Основе Панорамных<br/>Рендеров или Сканов'
    }
  ],
  [
    'Tours column-1', {
      en: 'We have created our own engine for 3D virtual tours. There are endless possibilities to customize the tour itself as well as the design of the user interface. Any desired content could be added to the tour: text, photo, video, audio, links and so on.\n' +
        '\n' +
        'We provide two main types of virtual tours. There are tours made by scanning the existing buildings or other real life objects like museums, galleries, historical structures etc. The other type is a tour made by using 3D-models of buildings still in the design or construction phase (residential complex, living quarters, and commercial real estate).',
      ru: 'Мы разработали собственный движок для 3D-туров в вебе, с неограниченными возможностями кастомизации как самого тура, так и дизайна пользовательского интерфейса. Также, в наши туры можно добавлять контент любого формата - тексты, фото, видео, аудио, ссылки и тд.<br/>' +
        '<br/>' +
        'Два основных типа виртуальных туров, которые мы производим, это туры на основе сканов существующих пространств и зданий (музеи, галереи, исторические постройки и пр.), и туры на основе 3D-моделей не существующих пока строений (жилые комплексы, строящаяся коммерческая недвижимость и т.д.).'
  }
  ],
  [
    'Tours column-2', {
      en: 'We have produced more than ten different virtual tours for the AFI Development company - AFI Tower and AFI Park living quarters, including apartment tours, lobby, gyms, spa, beauty salons and other amenities of the residential complex.',
      ru: 'Для компании AFI Development нами создано более десяти виртуальных туров по ЖК AFI Tower и AFI Park, включая туры по квартирам, лобби, спортивным залам, спа, салонам красоты и другой прилегающей инфраструктуре комплексов.'
    }
  ],

  [
    'AR logo subtitle', {
      en: 'Immercyb Logo WebAR',
      ru: 'Логотип в Дополненной Реальности'
    }
  ],
  [
    'AR logo column-1', {
      en: 'WebAR is an easy and eye-catching way to showcase your company logo in AR. No mobile app needed, you will be able to view it right from your web page.',
      ru: 'WebAR - очень простой и красивый способ разместить лого вашей компании в Дополненной реальности. В этом случае не требуется установка мобильного приложения, а быстрое попадание в AR осуществляется прямо со страницы вашего сайта.'
    }
  ],
  [
    'AR logo column-2', {
      en: 'The gamification of the interaction with the logo will boost the user engagement rate and generate digital photo / video content of your brand for social networks. It’s a great way to give new emotions to your clients, colleagues and partners!',
      ru: 'Геймификация взаимодействия с логотипом в AR повышает вовлеченность пользователей, позволяя им генерировать брендированный цифровой фото/видео контент для своих социальных страниц. Подарите новые эмоции своим клиентам, сотрудникам и партнерам!'
    }
  ],
  [
    'To switch to augmented reality, press this button on the mobile phone screen', {
      en: 'Press this button to switch to augmented reality mode',
      ru: 'Для перехода в дополненную реальность нажмите эту кнопку на экране мобильного телефона'
    }
  ]
])

export default messages
