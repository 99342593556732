import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AboutImmercyb from "@/components/AboutImmercyb.vue"
import JettaProject from "@/components/JettaProject.vue"
import AugmentedViewer from "@/components/AugmentedViewer.vue"
import MeshedTour from "@/components/MeshedTour.vue"
import Kraus from "@/components/Kraus.vue"
import StretchesShader from "@/components/StretchesShader.vue"
import LgTest from "@/components/LgTest.vue";
import VrMan from "@/components/VrMan.vue";
import WaterWorld from "@/components/WaterWorld.vue";
import LgVangogh from "@/components/LgVangogh.vue";
import PostProcess from "@/components/PostProcess.vue";
import LogoFlatShading from "@/components/LogoFlatShading.vue";
import ShadersArts from "@/components/ShadersArts.vue";
import Vr from "@/components/Vr.vue";
import Landing from "@/components/Landing.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:project?',
    name: 'Landing',
    component: Landing,
    meta: {
      title: 'Immersive cybernetics'
    }
  },
  // {
  //   path: '/jetta',
  //   name: 'Jetta',
  //   component: JettaProject,
  //   meta: {
  //     title: 'Volkswagen Jetta 2021 showroom'
  //   }
  // },
  // {
  //   path: '/tour',
  //   name: 'Tour',
  //   meta: {
  //     title: '3D-Tour Engine'
  //   },
  //   component: MeshedTour
  // },
  // {
  //   path: '/kraus',
  //   name: 'Kraus',
  //   component: Kraus,
  //   meta: {
  //     title: 'Kraus Blocks Constructor'
  //   }
  // },
  {
    path: '/lg-vangogh',
    name: 'LgVangogh',
    component: LgVangogh,
    meta: {
      title: 'LG Vangogh'
    }
  },
  {
    path: '/vr',
    name: 'Vr',
    component: Vr,
    meta: {
      title: 'Vr'
    }
  },
  {
    path: '/lg-test',
    name: 'LgTest',
    component: LgTest,
    meta: {
      title: 'Lg test'
    }
  },
  // {
  //   path: '/stretches',
  //   name: 'Strethes',
  //   component: StretchesShader,
  //   meta: {
  //     title: 'Stretch'
  //   }
  // },
  {
    path: '/vr-man',
    name: 'VrMan',
    component: VrMan,
    meta: {
      title: 'Vr man'
    }
  },
  {
    path: '/water-world',
    name: 'WaterWorld',
    component: WaterWorld,
    meta: {
      title: 'Water world'
    }
  },
  {
    path: '/post-process',
    name: 'PostProcess',
    component: PostProcess,
    meta: {
      title: 'Post processing'
    }
  },
  {
    path: '/logo-flat-shading',
    name: 'LogoFlatShading',
    component: LogoFlatShading,
    meta: {
      title: 'Logo flat shading'
    }
  },
  {
    path: '/shaders-arts',
    name: 'ShadersArts',
    component: ShadersArts,
    meta: {
      title: 'Shaders arts'
    }
  },
  {
    path: '/ar/:modelId?',
    name: 'AugmentedViewer',
    component: AugmentedViewer,
    meta: {
      title: 'Augmented viewer'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
