import {ActionContext} from "vuex";

interface LgVangoghState {
  step: number
}

export default {
  state: () => ({
    step: 0
  }),

  mutations: {
    setStep(state: LgVangoghState, step: number) {
      state.step = step
    }
  },

  actions: {
    nextStep({commit, state} : ActionContext<LgVangoghState, any>) {
      const nextStep = state.step + 1
      commit('setStep', nextStep)
    }
  },

  getters: {
    step: (state: LgVangoghState) : number => state.step
  }
}
