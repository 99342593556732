import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {PostProcess, Texture, Vector2} from "@babylonjs/core";
import eases from "eases";

export default class ImageMask extends SceneItem {
  private _postProcess!: PostProcess
  private _time = 0
  private _screenSize = Vector2.Zero()
  private _mousePosition = Vector2.Zero()
  private _mouseOffset = Vector2.Zero()
  private _mouseOffsetTime = 0

  private _radius = 0

  private _texture: Texture | null = null
  private _prevTexture: Texture | null = null

  private _hidden = true

  constructor(props: SceneItemArgs) {
    super(props)

    window.addEventListener('mousemove', this._onMouseMove)
  }

  private _onMouseMove = (e:MouseEvent) => {
    this._mousePosition.x = e.clientX / window.innerWidth
    // this._mousePosition.y = (window.innerHeight / 2) / window.innerHeight
    this._mousePosition.y = e.clientY / window.innerHeight
  }

  private _createPostProcess() : void {

    this._postProcess = new PostProcess(
      'image-mask',
      '/landing/image-effects/fx/image-mask',
      ['time', 'radius', 'screenSize', 'mouse'],
      [],
      1.,
      this.camera
    )

    this._postProcess.onApply = (effect) => {
      this._screenSize.x = this._postProcess.width
      this._screenSize.y = this._postProcess.height

      const texture : Texture | null = this._prevTexture || this._texture

      const delta = Vector2.Zero()
      delta.y = Math.sin(this._time * 0.001) * this._radius * 0.1
      delta.x = ((1 - this._radius) - Math.cos(this._time * 0.001)) * this._radius * 0.1

      const position: Vector2 = this._mousePosition.clone().add(this._mouseOffset).add(delta)
      position.y = 0.5 * this._radius + this._mousePosition.y * (1 - this._radius) + delta.y

      // position.y -= Math.sin(this._time * 0.001) * .1
      // position.x += (1 - this._radius) - Math.cos(this._time * 0.001) * .1

      effect.setFloat('time', this._time)
      effect.setFloat('radius', this._radius)
      effect.setVector2('screenSize', this._screenSize)
      effect.setVector2('mouse', position)
      effect.setTexture('textureSampler', texture)
    }

    this.scene.onBeforeRenderObservable.add(() => {
      this._time += this.sceneController.engine.getDeltaTime()

      if (this._hidden || this._prevTexture) {
        this._radius += (-0.2 - this._radius) / 20
        this._mouseOffset.x += (0 - this._mouseOffset.x) / 10

        if (this._radius < 0) {
          this._radius = 0
          this._prevTexture = null
        }
      } else {
        this._radius += (1 - this._radius) / 20
        this._mouseOffset.x += (0.3 - this._mouseOffset.x) / 20
        //if (this._radius > 1) this._radius = 1
      }
    })
  }

  public hide() : void {
    this._mouseOffsetTime = this._time + 2000
    this._hidden = true
  }

  public show() : void {
    this._mouseOffsetTime = this._time + 2000
    this._hidden = false
  }

  public setTexture(texture: Texture) : void {

    this._prevTexture = this._texture
    this._texture = texture

    if (!this._postProcess) this._createPostProcess()
  }


  public dispose() : void {
    this._postProcess.dispose()
    window.removeEventListener('mousemove', this._onMouseMove)
  }
}
