
import {Options, Vue} from "vue-class-component";
import ShadersArtsSceneController from "@/components/ShadersArts/classes/ShadersArtsSceneController.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import VSwatches from 'vue3-swatches'
import {Color3} from "@babylonjs/core";


export enum EffectsList {
  Psyho = 'Psyho',
  RayMarching = 'Ray marching',
  TexturedCube = 'Ray marching textured cube',
  LavaWorld = 'Lava world',
  FaceMesh = 'Face mesh',
}

@Options({
  components: {VSwatches},
  watch: {
    colorHex(color) {
      console.log(color)
    }
  }
})
export default class ShadersArts extends Vue {
  public scenesList: EffectsList[] = [
    EffectsList.Psyho,
    EffectsList.RayMarching,
    EffectsList.LavaWorld,
    EffectsList.FaceMesh,
    EffectsList.TexturedCube
  ]

  public colorHex: string = Color3.Blue().toHexString()

  public currentEffect!: EffectsList
  private _sceneController!: ShadersArtsSceneController

  public mounted() : void {
    this._createSceneController()
    EventBus.emit(EventBusEvents.SceneLoaded)
  }

  public unmounted() : void {
    this._sceneController.dispose()
  }

  private _createSceneController() : void {
    this._sceneController = new ShadersArtsSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
    this.currentEffect = this._sceneController.currentEffect
    this._updateOptions()
  }

  public setColor(hexString: string) : void {
    this.colorHex = hexString
    this._updateOptions()
  }

  private _updateOptions() : void {
    this._sceneController.setOptions({
      color: Color3.FromHexString(this.colorHex)
    })
  }

  public enableEffect(name: EffectsList) : void {
    this._sceneController.enableEffect(name)
    this.currentEffect = name
  }
}
