import SceneController from "@/classes/SceneController.class";
import * as BABYLON from '@babylonjs/core'
import GroundModel from "@/components/LgVangogh/classes/GroundModel.class";
import PictureModel, {PictureAnimations, PictureModelEvents} from "@/components/LgVangogh/classes/PictureModel.class";
import {
  Color4, CubeTexture, DefaultRenderingPipeline,
  // HardwareScalingOptimization,
  // MergeMeshesOptimization,
  // TextureOptimization,
  SceneOptimizer,
  SceneOptimizerOptions,
  Vector3
} from "@babylonjs/core";
import ArPlaceMarkerModel from "@/components/LgVangogh/classes/ArPlaceMarkerModel.class";
import CameraLightsController from "@/components/LgVangogh/classes/CameraLightsController.class";
import PictureGround from "@/components/LgVangogh/classes/PictureGround.class";
import SpheresController from "@/components/LgVangogh/classes/SpheresController.class";

export enum LgVangoghSceneControllerEvents {
  PictureModelLoaded = 'PictureModelLoaded',
  PictureAnimationComplete = 'PictureAnimationComplete',
  SoundStarted = 'SoundStarted'
}

export enum WebXRDriver {
  EightWall = 'EightWall',
  BabylonWebXR = 'BabylonWebXR'
}

export default class LgVangoghSceneController extends SceneController {
  private _webXRDriver : WebXRDriver
  private _groundModel!: GroundModel
  private _pictureModel!: PictureModel
  private _arPlaceMarkerModel!: ArPlaceMarkerModel
  private _pictureGround!: PictureGround
  private _spheresController!: SpheresController

  private _lightsController!: CameraLightsController

  private _sceneOptimizerOptions!: SceneOptimizerOptions
  private _sceneOptimizer!: SceneOptimizer

  private _webxrEnabled = false

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})

    const hdrTexture: CubeTexture = CubeTexture.CreateFromPrefilteredData("/projects/about-immercyb/env/autoshop.env", this.scene)
    this.scene.environmentTexture = hdrTexture

    this._webXRDriver = WebXRDriver.EightWall
    this._initDriver()

    this.scene.clearColor = new Color4(0,0,0,1)
    //this._createOptimizer()
  }


  private _initDriver() : void {
    if (this._webxrEnabled) {
      if (this._webXRDriver === WebXRDriver.EightWall) {
        (window as any)['BABYLON'] = BABYLON

        const XRExtras: any = (window as any)['XRExtras']
        const windowIframeInnerPipelineModule: any = (window as any)['iframeInnerPipelineModule']
        const onxrloaded = () => {
          const XR8: any = (window as any)['XR8']

          XR8.addCameraPipelineModules([             // Add camera pipeline modules.
            XRExtras.AlmostThere.pipelineModule(),   // Detects unsupported browsers and gives hints.
            XRExtras.Loading.pipelineModule(),       // Manages the loading screen on startup.
            XRExtras.RuntimeError.pipelineModule(),  // Shows an error image on runtime error.
            windowIframeInnerPipelineModule
          ])

          this._startEightWallScene()
        }

        XRExtras.Loading.showLoading({onxrloaded})

        const loadImageContainer = document.querySelector('#loadImageContainer')
        if (loadImageContainer) {
          const logoEl = document.createElement('div')
          logoEl.className = 'logo'
          loadImageContainer.before(logoEl)
        }

        const cameraRequestElement = document.getElementById('requestingCameraPermissions')

        if (cameraRequestElement) cameraRequestElement.innerHTML = '' +
          '<div class="logo"></div>'

        const interval = setInterval(() => {
          const sensorsRequestElement = document.querySelector('.prompt-box-8w')
          if (sensorsRequestElement) {
            const p = document.querySelector('.prompt-box-8w p')
            const cancelButton = document.querySelector('.prompt-box-8w .prompt-button-8w')
            const continueButton = document.querySelector('.prompt-box-8w .prompt-button-8w.button-primary-8w')

            if (p && cancelButton && continueButton) {
              const logoEl = document.createElement('div')
              logoEl.className = 'logo'
              p.before(logoEl)
              p.innerHTML = '<span>РАЗРЕШИТЕ ДОСТУП К<br/>ДАТЧИКАМ ДВИЖЕНИЯ<br/>И КАМЕРЕ</span><span class="lg-partner">LG SIGNATURE ЯВЛЯЕТСЯ ПАРТНЕРОМ ГМИИ<br/>ИМ. А.С. ПУШКИНА В ПРОЕКТЕ<br/>ПО ИССЛЕДОВАНИЮ И РЕСТАВРАЦИИ<br/>КАРТИНЫ ВАН ГОГА<br/>“КРАСНЫЕ ВИНОГРАДНИКИ В АРЛЕ.<br/>МОНМАЖУР”</span>'
              cancelButton.innerHTML = 'Отмена'
              continueButton.innerHTML = 'ПРОДОЛЖИТЬ'
              clearInterval(interval)
            }
          }
        }, 10)
      }
    } else {
      this._startEightWallScene()
    }
  }

  private _startEightWallScene() : void {
    this.createCameraController()
    this.cameraController.camera.position = new Vector3(0, 3, 7)
    this.cameraController.camera.setTarget(new Vector3(0, 1.5, 0))

    // this._createVignette()

    this._initModels()
  }

  private _initModels() : void {
    this._lightsController =  new CameraLightsController({
      sceneController: this
    })

    this._groundModel = new GroundModel({
      sceneController: this
    })

    this._arPlaceMarkerModel = new ArPlaceMarkerModel({
      sceneController: this
    })

    this._pictureModel = new PictureModel({
      sceneController: this
    })

    this._pictureGround = new PictureGround({
      sceneController: this
    })

    this._spheresController = new SpheresController({
      sceneController: this
    })

    this._pictureModel.addEventListener(PictureModelEvents.Loaded, this._onPictureModelLoaded)
    this._pictureModel.addEventListener(PictureModelEvents.AnimationComplete, this._onPictureAnimationComplete)
    this._pictureModel.addEventListener(PictureModelEvents.SoundStarted, this._onSoundStarted)
  }

  private _onSoundStarted = () : void => {
    this.dispatchEvent(new Event(LgVangoghSceneControllerEvents.SoundStarted))
  }

  private _onPictureModelLoaded = () : void => {
    this.dispatchEvent(new Event(LgVangoghSceneControllerEvents.PictureModelLoaded))
  }

  private _onPictureAnimationComplete = () : void => {
    this.dispatchEvent(new Event(LgVangoghSceneControllerEvents.PictureAnimationComplete))
  }

  public setupPicture() : void {
    this._pictureModel.setupOnSurface()
    this._arPlaceMarkerModel.setupOnSurface()
  }

  public playAnimation(animation: PictureAnimations): void {
    // console.log('playAnimation', animation)
    this._pictureModel.playAnimation(animation)
  }

  public playFrameSound(animation: PictureAnimations) : void {
    this._pictureModel.playFrameSound(animation)
  }

  private _createVignette() : void {
    // const postProcess = new ImageProcessingPostProcess("processing", 1.0, this.cameraController.camera);
    // postProcess.vignetteWeight = 3
    // postProcess.vignetteStretch = 10
    // postProcess.vignetteColor = new Color4(0, 0, 0, 1)
    // postProcess.vignetteEnabled = true
    // postProcess.exposure = 1.5

    const defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, [this.cameraController.camera])
    defaultPipeline.samples = 4

    // defaultPipeline.grainEnabled = true
    // defaultPipeline.grain.intensity = 5
    // defaultPipeline.grain.animated = true

    // defaultPipeline.bloomEnabled = true
    // defaultPipeline.bloomWeight = 0.2
    defaultPipeline.fxaaEnabled = true

    defaultPipeline.imageProcessingEnabled = true
    defaultPipeline.imageProcessing.vignetteWeight = 4
    defaultPipeline.imageProcessing.vignetteStretch = 12
    defaultPipeline.imageProcessing.vignetteColor = new Color4(0, 0, 0, 1)
    defaultPipeline.imageProcessing.vignetteEnabled = true
    // defaultPipeline.imageProcessing.exposure = 0.6
    // defaultPipeline.imageProcessing.contrast = 0.97

    // defaultPipeline.cameraFov = this.cameraController.camera.fov
  }

  public dispose() : void {
    this._pictureModel.removeEventListener(PictureModelEvents.Loaded, this._onPictureModelLoaded)
    this._pictureModel.removeEventListener(PictureModelEvents.AnimationComplete, this._onPictureAnimationComplete)
    this._pictureModel.removeEventListener(PictureModelEvents.SoundStarted, this._onSoundStarted)

    this._pictureModel.dispose()
    this._spheresController.dispose()
    this.engine.dispose()
  }
}
