import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {ParticleSystem, Texture, Vector3} from "@babylonjs/core";
import eases from "eases";

export default class CircleParticles extends SceneItem {
  private _particleSystem: ParticleSystem
  private _updateSpeed = 0
  private _toUpdateSpeed = 0
  private _defaultSpeed = 0.02

  private _time = 0
  private _easeStartTime = 0
  private _easeUpdateSpeed = 0
  private _easeDuration = 2 //seconds

  private _position: Vector3 = new Vector3(0, -200, 20)

  constructor(props: SceneItemArgs) {
    super(props)

    this._particleSystem = new ParticleSystem('circles', 1000, this.scene)
    this._particleSystem.particleTexture = new Texture('/landing/textures/particle.png', this.scene)
    this._particleSystem.particleTexture.hasAlpha = true
    this._particleSystem.isLocal = true
    this._particleSystem.blendMode = ParticleSystem.BLENDMODE_STANDARD

    this._particleSystem.emitter = this._position.clone()
    this._particleSystem.minEmitBox = new Vector3(-300, 0, 100)
    this._particleSystem.maxEmitBox = new Vector3(300, 0, 400)

    this._particleSystem.minEmitPower = 3
    this._particleSystem.maxEmitPower = 6

    this._particleSystem.minLifeTime = 50;
    this._particleSystem.maxLifeTime = 1000;

    this._particleSystem.preWarmCycles = 5000
    this._particleSystem.preWarmStepOffset = 1000

    this._updateSpeed = this._defaultSpeed
    this._particleSystem.updateSpeed = this._updateSpeed

    this._particleSystem.start()

    // setTimeout(() => {
    //   // this._particleSystem.stop(false)
    //   //this._particleSystem.updateSpeed = 2
    //   // this._particleSystem.start()
    // }, 3000)

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    this._time += this.sceneController.engine.getDeltaTime()

    const easingTime = ((this._time - this._easeStartTime) / this._easeDuration) / 1000

    if (easingTime < 1) {
      this._updateSpeed = (this._toUpdateSpeed - this._easeUpdateSpeed) * eases.cubicInOut(easingTime)
    } else {
      this._updateSpeed += (this._defaultSpeed - this._updateSpeed) / 10
    }

    this._particleSystem.updateSpeed = this._updateSpeed

    // const emitter = this._particleSystem.emitter as Vector3
    // emitter.x += 0.1

    // this._updateSpeed += (this._toUpdateSpeed - this._updateSpeed) / 10
    //
    // if (this._toUpdateSpeed !== this._defaultSpeed && Math.abs(this._toUpdateSpeed - this._updateSpeed) < 0.005) {
    //   this._toUpdateSpeed = this._defaultSpeed
    // }
  }

  setPosition(offset: Vector3) {
    const emitter = this._particleSystem.emitter as Vector3
    emitter.x = this._position.x + offset.x
    emitter.y = this._position.y + offset.y
  }

  public speedUp(speed: number) : void {
    this._easeStartTime = this._time

    this._easeUpdateSpeed = this._updateSpeed
    this._toUpdateSpeed = speed
  }
}
