import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-menu-content" }
const _hoisted_2 = { class: "buttons-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slideButton = _resolveComponent("slideButton")!
  const _component_lang_switcher = _resolveComponent("lang-switcher")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_slideButton, { disabled: true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$tr('Home')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_slideButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onMenuItemClick('cases')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$tr('Projects')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_slideButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMenuItemClick('contacts')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$tr('Contacts')), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_lang_switcher)
  ]))
}