
import { reactive } from "vue";
import SlideMixin from "./SlideMixin";
import isMobile from "is-mobile";

export default class MarketLeadersSlide extends SlideMixin {
  public state = reactive({
    slideInfoMargin: '',
    isMobile: isMobile()
  })

  mounted() {
    window.addEventListener('resize', this.onWindowResize)
    this.updateStyles()
  }

  unmounted() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  private onWindowResize() : void {
    this.updateStyles()
  }

  private updateStyles() : void {
    this.state.slideInfoMargin =  window.innerHeight - 480 * .5 + 'px'
  }
}
