import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import BuildingModelController, {BuildingModelControllerEvents} from "@/components/Kraus/classes/BuildingsController/BuildingModelController.class";
import {ArcRotateCamera, Mesh, PointerEventTypes, PointerInfo, Vector3} from "@babylonjs/core";
import {Nullable} from "@babylonjs/core/types";
import {PickingInfo} from "@babylonjs/core/Collisions/pickingInfo";

export enum BuildingsControllerEvents {
  ModelsLoaded = 'ModelsLoaded'
}
export default class BuildingsController extends SceneItem {
  private _modelsUrls: string[] = [
    '/projects/kraus/models/1.glb',
    '/projects/kraus/models/2.glb',
    '/projects/kraus/models/3.glb',
    '/projects/kraus/models/4.glb',
    '/projects/kraus/models/5.glb',
  ]

  private _modelsControllers: BuildingModelController[] = []
  private _selectedModelIndex = 0

  private _toCameraPosition: Vector3 | null = null
  private _toCameraTarget: Vector3 | null = null

  constructor(props: SceneItemArgs) {
    super(props)
    this._createModelsControllers()

    this.scene.onPointerObservable.add(this._onPointerEvent)
    this.scene.onBeforeRenderObservable.add(() => {
      this._update()
    })
  }

  private _update() : void {
    const camera: ArcRotateCamera = this.camera as ArcRotateCamera
    if (this._toCameraPosition) {
      camera.position = Vector3.Lerp(camera.position, this._toCameraPosition, 0.05)
      if (Vector3.Distance(camera.position, this._toCameraPosition) < 0.1) {
        this._toCameraPosition = null
      }
    }

    if (this._toCameraTarget) {
      camera.target = Vector3.Lerp(camera.target, this._toCameraTarget, 0.05)
      if (Vector3.Distance(camera.target, this._toCameraTarget) < 0.1) {
        this._toCameraTarget = null
      }
    }
  }

  private _onPointerEvent = (e: PointerInfo) : void => {
    if (e.type === PointerEventTypes.POINTERPICK) {
      const pickResult: Nullable<PickingInfo> = this.scene.pick(this.scene.pointerX, this.scene.pointerY)
      if (pickResult && pickResult.pickedMesh) {
        this._modelsControllers.forEach((controller, index) => {
          const contains = controller.containsMesh(pickResult.pickedMesh as Mesh)
          if (contains) {
            this._selectModel(index)
          }
        })
      }
    }
  }

  private _createModelsControllers() : void {
    this._modelsUrls.forEach((url, index) => {
      const controller: BuildingModelController = new BuildingModelController({
        sceneController: this.sceneController
      })

      controller.setPosition(index)
      controller.loadBuilding(url)
      controller.addEventListener(BuildingModelControllerEvents.Loaded, this._onModelLoaded)

      this._modelsControllers.push(controller)
    })
  }

  private _selectModel(index: number) : void {
    this._selectedModelIndex = index
    this._modelsControllers.forEach((modelController) => {
      modelController.enableTransparent(false)
    })

    this._modelsControllers[index].enableTransparent(true)

    const modelPosition: Vector3 = this._modelsControllers[index].mesh!.position

    this._toCameraPosition = new Vector3(modelPosition.x - index,1,- index - 0.5)
    this._toCameraTarget = modelPosition.clone()
  }

  private _onModelLoaded = () : void => {
    const list: BuildingModelController[] = this._modelsControllers.filter((controller) => !controller.loaded)
    if (list.length === 0) {
      this._selectModel(2)
      this.dispatchEvent(new Event(BuildingsControllerEvents.ModelsLoaded))
    }
  }
}
