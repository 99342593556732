import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {
  BlurPostProcess,
  DefaultRenderingPipeline,
  FxaaPostProcess,
  PostProcess,
  Texture,
  Vector2
} from "@babylonjs/core";

export default class TexturedCubeSceneItem extends SceneItem {
  private _time = 0
  private _postProcess!: PostProcess
  private _fxaaPostProcess!: FxaaPostProcess
  private _mouseVector: Vector2 = Vector2.Zero()
  private _logoSampler: Texture

  private _postProcesses: PostProcess[] = []

  constructor(props: SceneItemArgs) {
    super(props)
    this._logoSampler = new Texture('/projects/shaders-arts/logo-black-bg.jpg', this.scene, false, true, Texture.NEAREST_SAMPLINGMODE)
    this._createEffect()

    const defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, [this.camera])
    defaultPipeline.bloomEnabled = true
    // defaultPipeline.fxaaEnabled = true
    defaultPipeline.bloomWeight = 1
    defaultPipeline.bloomScale = 1
    defaultPipeline.bloomKernel = 64
    defaultPipeline.bloomThreshold = 1

    this.scene.onBeforeRenderObservable.add(this._update)
    window.addEventListener('mousemove', this._onMouseMove)
  }

  private _onMouseMove = (e : MouseEvent) => {
    this._mouseVector.x = e.clientX
    this._mouseVector.y = e.clientY
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
  }

  private _createEffect() {
    const cubePostProcess = new PostProcess(
      'occlusion',
      '/projects/shaders-arts/fx/cube',
      ['time', 'mouse', 'screenSize'],
      ['logoSampler'],
      1.,
      this.camera
    )

    cubePostProcess.onApply = (effect) => {
      effect.setFloat('time', this._time)
      effect.setVector2('screenSize', new Vector2(cubePostProcess.width, cubePostProcess.height))
      effect.setVector2('mouse', this._mouseVector)
      effect.setTexture('logoSampler', this._logoSampler)
    }

    this._postProcess = cubePostProcess

    this._postProcesses.push(cubePostProcess)

    // const occlusion = new PostProcess(
    //   'occlusion',
    //   '/projects/shaders-arts/fx/volumetric-light-occlusion',
    //   ['time', 'screenSize', 'lightPosition', 'lightRadius'],
    //   ['depthSampler'],
    //   1.,
    //   this.camera
    // )
    //
    // this._postProcesses.push(occlusion)
    //
    // const godRays = new PostProcess(
    //   'rays',
    //   '/projects/shaders-arts/fx/volumetric-light-rays',
    //   ['time', 'lightPosition'],
    //   ['occlusionSampler'],
    //   1.,
    //   this.camera
    // )
    //
    // this._postProcesses.push(godRays)
    //
    // const godRaysFinal = new PostProcess(
    //   'rays',
    //   '/projects/post-process/fx/volumetric-light-final',
    //   [],
    //   ['raysSampler'],
    //   1.,
    //   this.camera
    // )
    //
    // this._postProcesses.push(godRaysFinal)

    // occlusion.onApply = (effect) => {
    //   effect.setVector2('screenSize', new Vector2(occlusion.width, occlusion.height))
    //   effect.setVector2('lightPosition', new Vector2(0.5, 0.5))
    //   effect.setFloat('lightRadius', 0.5)
    //   effect.setTextureFromPostProcessOutput('depthSampler', cubePostProcess)
    // }

    // godRays.onApply = (effect) => {
    //   effect.setVector2('lightPosition', new Vector2(0.5, 0.5))
    //   effect.setTextureFromPostProcessOutput('occlusionSampler', occlusion)
    // }

    // godRaysFinal.onApply = (effect) => {
    //   effect.setTextureFromPostProcess('textureSampler', occlusion)
    //   effect.setTextureFromPostProcessOutput('raysSampler', godRays)
    // }

    // this._fxaaPostProcess = new FxaaPostProcess('fxaa', 1., this.camera)
    //
    // this._fxaaPostProcess.onApply = (effect) => {
    //   effect.setTextureFromPostProcessOutput('textureSampler', cubePostProcess)
    // }
  }

  public dispose() : void {
    //this._postProcess.dispose(this.camera)
    this._postProcesses.forEach(item => {
      item.dispose(this.camera)
    })
    if (this._fxaaPostProcess) this._fxaaPostProcess.dispose(this.camera)
    window.removeEventListener('mousemove', this._onMouseMove)
  }
}
