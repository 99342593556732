
import {Options, prop, Vue} from "vue-class-component";
import ProjectImageSceneController
  from "@/components/Landing/MainMenu/ProjectsMenuContent/ProjectImage/classes/ProjectImageSceneController.class";

class ProjectImageProps {
  textures = prop({
    type: Array,
    required: true
  })

  activeTexture = prop({
    type: Number,
    required: true
  })
}

@Options({
  watch: {
    activeTexture(index: number) {
      this.setTextureIndex(index)
    }
  }
})
export default class ProjectImage extends Vue.with(ProjectImageProps) {
  private sceneController!: ProjectImageSceneController

  public mounted() : void {
    this.sceneController = new ProjectImageSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    this.sceneController.setTextures(this.textures as string[])
  }

  public unmounted() : void {
    this.sceneController.dispose()
  }

  public setTextureIndex(index: number) : void {
    this.sceneController.setTextureIndex(index)
  }
}
