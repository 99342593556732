import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class"
import {Color3, Mesh, MeshBuilder, Vector3} from "@babylonjs/core"
import {GridMaterial} from "@babylonjs/materials";

export default class PictureGround extends SceneItem {
  private _ground!: Mesh

  constructor(props: SceneItemArgs) {
    super(props)
    this._createGrid()
  }

  private _createGrid() : void {

    const ratio = 0.05
    const groundMaterial : GridMaterial = new GridMaterial("groundMaterial", this.scene)
    groundMaterial.majorUnitFrequency = (100 / ratio) * 0.01;
    groundMaterial.minorUnitVisibility = 0.3;
    groundMaterial.gridRatio = ratio;
    groundMaterial.backFaceCulling = false;
    groundMaterial.mainColor = Color3.White()
    groundMaterial.lineColor = Color3.FromHexString('#FF9EE3')
    groundMaterial.opacity = 0.5;
    groundMaterial.wireframe = false;
    groundMaterial.fogEnabled = true

    this._ground = MeshBuilder.CreateGround('ground', {
      width: 1000,
      height: 1000,
      updatable: false,
      subdivisions: 2
    }, this.scene)
    // this._ground = Mesh.CreateBox("ground", 100, this._scene);
    this._ground.position.y = -0.01
    this._ground.material = groundMaterial
  }

  public hide() : void {
    this._ground.scaling = Vector3.Zero()
  }

  public show() : void {
    this._ground.scaling = Vector3.One()
  }

  public dispose() : void {
    this._ground.dispose()
  }
}
