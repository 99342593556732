
import {Options, prop, Vue} from "vue-class-component";
import CircleButton from "@/components/Landing/ui/CircleButton.vue";
import { reactive } from "vue";
import isMobile from "is-mobile";

class ContactsProps {
  hidden = prop({
    type: Boolean,
    required: true
  })
}

@Options({
  components: {
    CircleButton
  }
})
export default class Contacts extends Vue.with(ContactsProps) {
  public state = reactive({
    formVisible: false,
    formSended: false,
    name: '',
    email: '',
    message: '',
    error: false,
    isMobile: false
  })

  public mounted() {
    this.state.isMobile = isMobile()
  }

  public onGetInTouchClick() : void {
    this.state.formVisible = true
  }

  public onCloseButtonClick() : void {
    this.state.formVisible = false
  }

  public onSendClick() : void {
    const values: string[] = [
      this.state.name.trim(),
      this.state.email.trim(),
      this.state.message.trim()
    ]

    const filled = values.filter((value) => value.trim() === '').length === 0

    if (filled) {
      const formData: FormData = new FormData()
      formData.append('name', this.state.name)
      formData.append('email', this.state.email)
      formData.append('message', this.state.message)

      this.state.name = ''
      this.state.email = ''
      this.state.message = ''

      const xhr = new XMLHttpRequest()
      xhr.open('POST', '/message.php')
      xhr.onload = () => {
        this.state.formSended = true

        setTimeout(() => {
          this.state.formVisible = false
          setTimeout(() => {
            this.state.formSended = false
          }, 1000)
        }, 4000)
      }

      xhr.send(formData)
    } else {
      this.state.error = true
      setTimeout(() => {
        this.state.error = false
      }, 1000)
    }
  }
}
