
import SlideMixin from "./SlideMixin";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class VrSlide extends SlideMixin {
  public onViewProjectsClick() : void {
    EventBus.emit(EventBusEvents.ViewProjects)
  }
}
