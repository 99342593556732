import {Vector3} from "@babylonjs/core";
import eases from "eases";

export default class VectorEasing {

  static cubicInOut(from: Vector3, to: Vector3, time: number) : Vector3 {
    const easeValue = eases.cubicInOut(time)
    const deltaVector = to.subtract(from).multiplyByFloats(easeValue, easeValue, easeValue)
    return from.add(deltaVector)
  }
}
