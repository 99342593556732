import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_math_design_slide = _resolveComponent("math-design-slide")!
  const _component_market_leaders_slide = _resolveComponent("market-leaders-slide")!
  const _component_web_3d_slide = _resolveComponent("web-3d-slide")!
  const _component_ar_slide = _resolveComponent("ar-slide")!
  const _component_vr_slide = _resolveComponent("vr-slide")!
  const _component_slider_slide = _resolveComponent("slider-slide")!
  const _component_final_slide = _resolveComponent("final-slide")!
  const _component_scroller_info = _resolveComponent("scroller-info")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slides-manager", {hidden: _ctx.hidden}])
  }, [
    _createVNode(_component_math_design_slide, {
      visible: _ctx.state.currentComponent === 'MathDesignSlide',
      position: _ctx.getPosition('MathDesignSlide')
    }, null, 8, ["visible", "position"]),
    _createVNode(_component_market_leaders_slide, {
      visible: _ctx.state.currentComponent === 'MarketLeadersSlide',
      position: _ctx.getPosition('MarketLeadersSlide')
    }, null, 8, ["visible", "position"]),
    _createVNode(_component_web_3d_slide, {
      visible: _ctx.state.currentComponent === 'Web3dSlide',
      position: _ctx.getPosition('Web3dSlide')
    }, null, 8, ["visible", "position"]),
    _createVNode(_component_ar_slide, {
      visible: _ctx.state.currentComponent === 'ArSlide1' || _ctx.state.currentComponent === 'ArSlide2',
      position: _ctx.getPosition('ArSlide1') === 'center' || _ctx.getPosition('ArSlide2') === 'center' ? 'center' : _ctx.getPosition('ArSlide1')
    }, null, 8, ["visible", "position"]),
    _createVNode(_component_vr_slide, {
      visible: _ctx.state.currentComponent === 'VrSlide',
      position: _ctx.getPosition('VrSlide')
    }, null, 8, ["visible", "position"]),
    _createVNode(_component_slider_slide, {
      visible: _ctx.state.currentComponent === 'SliderSlide',
      position: _ctx.getPosition('SliderSlide')
    }, null, 8, ["visible", "position"]),
    _createVNode(_component_final_slide, {
      visible: _ctx.state.currentComponent === 'FinalSlide',
      position: _ctx.getPosition('FinalSlide')
    }, null, 8, ["visible", "position"]),
    _createVNode(_component_scroller_info, {
      class: _normalizeClass({hidden: _ctx.hidden})
    }, null, 8, ["class"])
  ], 2))
}