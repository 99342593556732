import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {
  Mesh,
  MeshBuilder,
  ShaderMaterial,
  Vector3
} from "@babylonjs/core";
import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import isMobile from "is-mobile";

export default class BlobedSphereModel extends BaseBlob {

  constructor(props: SceneItemArgs) {
    super(props)

    this._createCustomPbr()
    this._createSphere()

    this._linesCount = 60
    this._lineThickness = .35
  }

  protected _update() {
    super._update()
  }

  protected _createCustomPbr() {
    super._createCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/blobed-sphere/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
  }

  private _createSphere() : void {
    this._mesh = new Mesh('blob root', this.scene)

    this._meshFront = MeshBuilder.CreateSphere('blob', {
      diameter: isMobile() ? 0.6 : 1,
      segments: 300,
      sideOrientation: Mesh.FRONTSIDE
    }, this.scene)

    this._meshBack = MeshBuilder.CreateSphere('blob', {
      diameter: isMobile() ? 0.6 : 1,
      segments: 300,
      sideOrientation: Mesh.BACKSIDE
    }, this.scene)


    this._meshFront.material = this._customFrontMaterial
    this._meshBack.material = this._materialBack

    this._meshFront.parent = this._mesh
    this._meshBack.parent = this._mesh

    this._meshFront.scaling = this._meshBack.scaling = Vector3.Zero()
    this._toRotation.y = Math.PI

    this._meshBack.layerMask = 2
    this._meshFront.layerMask = 2

    this.blobedPostProcess.addMeshForRender(this._meshFront, true)
    this.blobedPostProcess.addMeshForRender(this._meshBack, false)

    EventBus.emit(EventBusEvents.BlobedSphereLoaded)
  }

  // private _createMaterial() : void {
  //   this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/landing/blobed-sphere/fx/blob", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //
  //   this._materialFront.backFaceCulling = false
  //   this._materialFront.alphaMode = 1
  //
  //   this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/blobed-sphere/fx/blob-back", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //
  //   this._materialBack.backFaceCulling = true
  //
  //   this._createLights()
  // }

  setSpeed(val: number) {
    this._speed = val
  }

  setRadius(radius: number) {
    this._toRadius = radius
  }

  public show() : void {
    if (!this._visible) {
      this._visible = true
      this.scaleTo(Vector3.One())
      //this.rotateTo(new Vector3(0.3, 0, 0.3))
    }
  }

  public hide() : void {
    if (this._visible) {
      this._visible = false
      this.rotateTo(new Vector3(0.3, -Math.PI, 0.3), 2)
      this.positionTo(new Vector3(this._position.x, 3 ,this._position.z), 2)
    }
  }

  public dispose() : void {
    super.dispose()
    document.removeEventListener('mousemove', this._onMouseMove)
  }
}
