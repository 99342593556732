import {SceneItem, SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {PostProcess, Texture, Vector2} from "@babylonjs/core";
import eases from "eases";

export default class CirclesPostProcess extends SceneItem {

  private _postProcess!: PostProcess
  private _time = 0
  private _easeTime = 0
  private _offset = 0
  private _screenSize = Vector2.Zero()

  constructor(props: SceneItemArgs) {
    super(props)

    this._createPostProcess()
  }

  private _createPostProcess() : void {

    this._postProcess = new PostProcess(
      'image-mask',
      '/landing/circles-effects/fx/circles',
      ['time', 'screenSize', 'offset', 'mouse'],
      [],
      1.,
      this.scene.activeCamera!
    )

    this._postProcess.onApply = (effect) => {
      this._screenSize.x = this._postProcess.width
      this._screenSize.y = this._postProcess.height

      effect.setFloat('time', this._time)
      effect.setFloat('offset', this._offset)
      effect.setVector2('screenSize', this._screenSize)
    }

    this.scene.onBeforeRenderObservable.add(() => {
      this._time += this.sceneController.engine.getDeltaTime()

      if (this._easeTime > this._time) {
        const timeDelta = 1 - ((this._easeTime - this._time)) / 1000
        this._offset = eases.cubicInOut(timeDelta)
      } else {
        const timeDelta = ((this._time - this._easeTime) / 2) / 1000
        this._offset = -0.7 + eases.cubicInOut(timeDelta) * 0.7
        if (this._offset > 0) {
          this._offset = 0
        }
      }

      // this._offset += this.sceneController.engine.getDeltaTime() / 10000
      // if (this._offset > 1) {
      //   this._offset = -1
      // }
    })
  }

  public startAnimation() : void {
    this._easeTime = this._time + 1000
  }

  public dispose() : void {
    this._postProcess.dispose()
  }
}
