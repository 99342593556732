import SceneController from "@/classes/SceneController.class";
import {
  BlurPostProcess,
  Color4, DefaultRenderingPipeline,
  DepthRenderer,
  Effect,
  Mesh,
  MeshBuilder,
  NoiseProceduralTexture,
  PostProcess,
  Vector2
} from "@babylonjs/core";
import CityModelController from "@/components/PostProcess/classes/CityModelController.class";

export default class PostProcessSceneController extends SceneController {

  private _postProcess!: PostProcess
  private _postProccessTime = 0
  private _noiseTexture!: NoiseProceduralTexture
  private _depthRenderer!: DepthRenderer

  private _cityModelController!: CityModelController

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()

    this.cameraController.camera.upperRadiusLimit = 100
    this.cameraController.camera.radius = 13
    this.cameraController.camera.upperBetaLimit = Math.PI / 2 + Math.PI / 30;

    this.cameraController.camera.useAutoRotationBehavior = true
    this.cameraController.camera.autoRotationBehavior!.idleRotationSpeed = 0.2

    this.scene.clearColor = new Color4(0,0,0,1)

    this._createModels()
    this._createPostProcess()

    this._createVignette()

    this.scene.environmentIntensity = 0

    this.scene.onBeforeRenderObservable.add(this._update)

    this._depthRenderer = this.scene.enableDepthRenderer(this.cameraController.camera)
  }

  private _update = () => {
    this._postProccessTime += 0.1
  }

  private _createModels() : void {
    this._cityModelController = new CityModelController({
      sceneController: this
    })

    // const cube: Mesh = MeshBuilder.CreateBox('box',{
    //   size: 1,
    // }, this.scene)
    // cube.position.x = 2
    //
    // const cube2: Mesh = MeshBuilder.CreateBox('box2',{
    //   size: 1,
    // }, this.scene)
    //
    // cube2.position.x = -2
  }

  private _createPostProcess() : void {
    const lightPosition = new Vector2(0.5, 0.6)

    const occlusion = new PostProcess(
      'occlusion',
      '/projects/post-process/fx/volumetric-light-occlusion',
      ['time', 'screenSize', 'lightPosition', 'lightRadius'],
      ['depthSampler'],
      1.,
      this.cameraController.camera
    )

    const godRays = new PostProcess(
      'rays',
      '/projects/post-process/fx/volumetric-light-rays',
      ['time', 'lightPosition'],
      ['occlusionSampler'],
      1.,
      this.cameraController.camera
    )

    const blur = new BlurPostProcess('blur', new Vector2(2.0, 2.0), 32, 1.0, this.cameraController.camera)

    const godRaysFinal = new PostProcess(
      'rays',
      '/projects/post-process/fx/volumetric-light-final',
      [],
      ['raysSampler'],
      1.,
      this.cameraController.camera
    )


    occlusion.onApply = (effect) => {
      effect.setVector2('screenSize', new Vector2(occlusion.width, occlusion.height))
      effect.setVector2('lightPosition', lightPosition)
      effect.setFloat('lightRadius', 0.5)
      effect.setTexture('depthSampler', this._depthRenderer.getDepthMap())
    }

    godRays.onApply = (effect) => {
      effect.setVector2('lightPosition', lightPosition)
      effect.setTextureFromPostProcessOutput('occlusionSampler', occlusion)
    }

    blur.onApply = (effect) => {
      effect.setTextureFromPostProcessOutput('occlusionSampler', godRays)
    }

    godRaysFinal.onApply = (effect) => {
      effect.setTextureFromPostProcess('textureSampler', occlusion)
      effect.setTextureFromPostProcessOutput('raysSampler', blur)
    }
  }

  private _createVignette() : void {
    // const postProcess = new ImageProcessingPostProcess("processing", 1.0, this.cameraController.camera);
    // postProcess.vignetteWeight = 3
    // postProcess.vignetteStretch = 10
    // postProcess.vignetteColor = new Color4(0, 0, 0, 1)
    // postProcess.vignetteEnabled = true
    // postProcess.exposure = 1.5

    const defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, [this.cameraController.camera])
    defaultPipeline.samples = 10

    defaultPipeline.grainEnabled = true
    defaultPipeline.grain.intensity = 3
    defaultPipeline.grain.animated = true

    // defaultPipeline.bloomEnabled = true
    defaultPipeline.fxaaEnabled = true
    // defaultPipeline.bloomWeight = 0.2

    defaultPipeline.imageProcessingEnabled = true
    defaultPipeline.imageProcessing.vignetteWeight = 2
    defaultPipeline.imageProcessing.vignetteStretch = 5
    defaultPipeline.imageProcessing.vignetteColor = new Color4(0, 0, 0, 1)
    defaultPipeline.imageProcessing.vignetteEnabled = true
    defaultPipeline.imageProcessing.exposure = 1.
    defaultPipeline.imageProcessing.contrast = 1.4

    // defaultPipeline.cameraFov = this.cameraController.camera.fov
  }
}
