
import {Options, Vue} from "vue-class-component"
import VrManSceneController, {LightsProps} from "@/components/VrMan/classes/VrManSceneController.class";
import LightsSettings from "@/components/VrMan/LightsSettings.vue";

@Options({
  components: {LightsSettings}
})
export default class VrMan extends Vue {
  private _vrManSceneController!: VrManSceneController
  public sceneInited = false

  public mounted() : void {
    this._createSceneController()
  }

  public get lightsProps() : LightsProps | null {
    return this._vrManSceneController?.lightsProps??null
  }

  public unmounted() : void {
    this._vrManSceneController.dispose()
  }

  private _createSceneController() : void {
    this._vrManSceneController = new VrManSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    this.sceneInited = true
  }
}
