
import {prop, Vue} from "vue-class-component";

class IconButtonProps {
  icon : string | undefined = prop({
    type: String,
    required: false
  })
}

export default class IconButton extends Vue.with(IconButtonProps) {

}
