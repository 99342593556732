
import {Options, Vue} from "vue-class-component";
import MainMenuButton from "@/components/Landing/MainMenu/MainMenuButton.vue";
import { reactive } from "vue";
import MainMenuContent from "@/components/Landing/MainMenu/MainMenuContent.vue";
import ProjectsMenuContent from "@/components/Landing/MainMenu/ProjectsMenuContent.vue";
import LandingProjectPage from "@/components/Landing/MainMenu/LandingProjectPage.vue";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import I18n from "@/plugins/I18n";
import ProjectsContents from "@/components/Landing/MainMenu/LandingProjectPage/ProjectsContents.class";

interface MainMenuState {
  menuOpened: boolean,
  activeSubMenu: string,
  projectId: string | null,
  nextProjectId: string | null,
  contactsOpened: boolean,
  locale: string
}

@Options({
  components: {
    MainMenuButton,
    MainMenuContent,
    ProjectsMenuContent,
    LandingProjectPage
  },
  watch: {
    '$route': {
      handler: function(route, prevRoute) {
        this.navigatedFromSlide = prevRoute.path === '/'
        this.checkRoute()
      }
    }
  }
})
export default class MainMenu extends Vue {
  public state : MainMenuState = reactive({
    menuOpened: false,
    activeSubMenu: '',
    projectId: null,
    nextProjectId: null,
    contactsOpened: false,
    locale: ''
  })

  public navigatedFromSlide = false

  private projectsContents: ProjectsContents = new ProjectsContents()

  public get projectsMenuVisible() : boolean {
    return this.state.activeSubMenu === 'cases' && this.state.projectId === null && this.state.nextProjectId === null
  }

  public get mainMenuContentVisible() : boolean {
    return this.state.menuOpened && this.state.activeSubMenu === '' && this.state.projectId === null && !this.state.contactsOpened
  }

  public mounted() : void {
    this.updateLocaleState()

    EventBus.addEventListener(EventBusEvents.ViewProjects, this.onViewProjects)
    EventBus.addEventListener(EventBusEvents.ScrollUp, this.onScrollUp)
    EventBus.addEventListener(EventBusEvents.AllLoaded, this.onAllLoaded)
  }

  public unmounted() : void {
    EventBus.removeEventListener(EventBusEvents.ViewProjects, this.onViewProjects)
    EventBus.removeEventListener(EventBusEvents.ScrollUp, this.onScrollUp)
    EventBus.removeEventListener(EventBusEvents.AllLoaded, this.onAllLoaded)
  }

  private onContactPageState(e: Event) : void {
    // this.$router.push('/contacts')
  }

  private onScrollUp() : void {
    this.state.projectId = null
    this.state.nextProjectId = null

    this.state.activeSubMenu = ''
    this.state.menuOpened = false
    this.state.contactsOpened = false

    this.$emit('opened', this.state.menuOpened)
  }

  private onViewProjects() : void {
    this.$router.push('/cases')
  }

  public onMenuButtonClick() : void {
    if (this.state.contactsOpened) {
      if (this.navigatedFromSlide) {
        this.$router.push('/')
      } else {
        this.$router.push('/menu')
      }
    } else {
      if (this.state.projectId || this.state.nextProjectId) {
        this.$router.push('/cases')
      } else {
        if (this.state.menuOpened) {
          this.$router.push('/')
        } else {
          this.$router.push('/menu')
        }
      }
    }
  }

  private onAllLoaded() : void {
    setTimeout(() => {
      this.checkRoute()
    }, 2000)
  }

  public checkRoute() : void {
    const projectId = this.$route.params.project as string

    if (projectId.trim() === 'contacts') {
      this.state.projectId = null
      this.state.nextProjectId = null

      this.state.activeSubMenu = ''
      this.state.contactsOpened = true
      EventBus.emit(EventBusEvents.ContactsPageOpened, true)
    } else if (this.state.contactsOpened) {
      this.state.contactsOpened = false
      EventBus.emit(EventBusEvents.ContactsPageOpened, false)
    }

    if (projectId.trim() === 'cases') {
      this.state.projectId = null
      this.state.nextProjectId = null
      this.state.activeSubMenu = 'cases'
      this.state.menuOpened = true
    } else if (projectId.trim() === 'menu') {
      this.state.projectId = null
      this.state.nextProjectId = null
      this.state.activeSubMenu = ''

      this.state.menuOpened = true
    } else if (this.projectsContents.projectsIds.indexOf(projectId) >= 0) {
      this.state.projectId = projectId
      this.state.menuOpened = true
    } else {
      if (this.state.projectId) {
        this.state.projectId = null
      } else {
        this.state.menuOpened = false
        this.state.activeSubMenu = ''
      }
    }

    this.$emit('opened', this.state.menuOpened || this.state.contactsOpened)
  }

  public onMainMenuItemSelected(alias: string) : void {
    this.state.activeSubMenu = alias
  }

  public onProjectsBackClick() : void {
    this.state.activeSubMenu = ''
    this.$router.push('/menu')
  }

  public onLangButtonClick() : void {
    I18n.setLocale(I18n.getLocale() === 'ru' ? 'en' : 'ru')
    this.updateLocaleState()
  }

  private updateLocaleState() : void {
    this.state.locale = I18n.getLocale() === 'ru' ? 'en' : 'ru'
  }

  private onProject(projectId : string) : void {
    if (this.state.projectId !== null) {
      this.state.nextProjectId = projectId
      this.state.projectId = null
      setTimeout(() => {
        this.state.projectId = this.state.nextProjectId
      }, 2000)
    } else {
      this.state.projectId = projectId
    }
  }
}
