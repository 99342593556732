import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "background-text" }
const _hoisted_2 = { viewBox: "0 0 2000 388" }
const _hoisted_3 = {
  y: "225",
  stroke: "rgba(255, 255, 255, 0.1)",
  fill: "transparent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["text", {hidden: _ctx.state.hidden, right: _ctx.state.right}]),
      style: _normalizeStyle(_ctx.state.textStyle)
    }, [
      (_openBlock(), _createElementBlock("svg", _hoisted_2, [
        _createElementVNode("text", _hoisted_3, _toDisplayString(_ctx.state.currentText), 1)
      ]))
    ], 6)
  ]))
}