
import {Vue} from "vue-class-component";
import I18n from "@/plugins/I18n";
import { reactive } from "vue";

export default class LangSwitcher extends Vue {
  public state = reactive({
    locale: ''
  })

  public mounted() : void {
    this.updateLocaleState()
  }

  private updateLocaleState() : void {
    this.state.locale = I18n.getLocale()
  }

  public setLanguage(locale: string) {
    I18n.setLocale(locale)
    this.updateLocaleState()
  }

  public isActive(locale: string) : boolean {
    return this.state.locale === locale
  }
}
