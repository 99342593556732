import ModelController from "@/classes/SceneController/ModelController.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {
  Camera,
  Color3, DepthRenderer, Engine, FreeCamera,
  Mesh,
  MeshBuilder, PointerEventTypes, PointerInfo,
  RenderTargetTexture,
  ShaderMaterial,
  StandardMaterial,
  Texture,
  Vector2,
  Vector3
} from "@babylonjs/core";
import {WaterMaterial} from "@babylonjs/materials";
import {Shaders} from "@/classes/ShaderCreator/shaders";

class RippleInitiator {
  public time = 0
  public position: Vector3

  constructor({time, position} : {time: number, position: Vector3}) {
    this.time = time
    this.position = position
  }
}

export default class WaterModelController extends ModelController {
  private _moonMesh!: Mesh

  private _waterModel!: Mesh
  private _waterMaterial!: ShaderMaterial

  private _underWaterGroundMesh!: Mesh
  private _underWaterGroundMaterial!: ShaderMaterial

  private _lightSourceBox!: Mesh
  private _depthDebugMesh!: Mesh

  private _renderTargetTexture!: RenderTargetTexture
  private _depthRenderer!:DepthRenderer
  private _rttCamera!: FreeCamera

  private _topCamera!: FreeCamera

  private _time = 0

  private _testBox!:Mesh

  private _rippleInitiators: RippleInitiator[] = []

  constructor(args: SceneItemArgs) {
    super(args)
    this._createMaterial()
    this._createModel()

    this.scene.onBeforeRenderObservable.add(this._update)
    this.scene.onPointerObservable.add(this._onPointerEvent)
  }

  public createRipple(position: Vector3) : void {
    this._rippleInitiators.push(new RippleInitiator({
      time: this._time,
      position: position
    }))

    if (this._rippleInitiators.length > 20) this._rippleInitiators.shift()
  }

  private _onPointerEvent = (pointerInfo:PointerInfo) => {
    if (pointerInfo.type === PointerEventTypes.POINTERPICK && pointerInfo.pickInfo?.pickedMesh) {
      const point : Vector3 = pointerInfo.pickInfo.pickedPoint!
      this.createRipple(new Vector3(point.x, 0, point.z))
    }
  }

  private _cleanRippleInitiators() : void {
    this._rippleInitiators = this._rippleInitiators.filter((rippleInitiator) => {
      return rippleInitiator.time + 2000 > this._time
    })
  }

  private _getRippleInitiatorsDataForShader() : {positions: number[] , times: number[]} {
    const positions : number[] = []
    const times : number[] = []

    this._rippleInitiators.forEach((rippleInitiator) => {
      //positions.concat([rippleInitiator.position.x, rippleInitiator.position.y, rippleInitiator.position.z])
      positions.push(rippleInitiator.position.x)
      positions.push(rippleInitiator.position.y)
      positions.push(rippleInitiator.position.z)

      times.push(rippleInitiator.time)
    })

    return {
      positions,
      times
    }
  }

  private _update = () => {
    this._time += this.sceneController.engine.getDeltaTime()
    this._cleanRippleInitiators()

    // this._testBox.rotation.y += Math.abs(Math.cos(this._time * 0.01)) * 0.02

    // this._lightSourceBox.position.x = Math.cos(this._time * 0.01 / 10) * 3;
    // this._lightSourceBox.position.z = Math.sin(this._time * 0.01 / 10) * 3;

    this._lightSourceBox.lookAt(Vector3.Down())

    this._rttCamera.setTarget(Vector3.Down())

    const rippleInitiatorsData = this._getRippleInitiatorsDataForShader()

    this._waterMaterial.setFloat('time', this._time)
    this._waterMaterial.setVector3('cameraPosition', this.camera.position)
    this._waterMaterial.setVector3('lightPosition', this._lightSourceBox.position)
    this._waterMaterial.setTexture('rttSampler', this._renderTargetTexture)

    if (rippleInitiatorsData.positions.length > 0) this._waterMaterial.setArray3('rippleInitiatorsPosition', rippleInitiatorsData.positions)
    if (rippleInitiatorsData.times.length > 0) this._waterMaterial.setFloats('rippleInitiatorsTime', rippleInitiatorsData.times)
    this._waterMaterial.setInt('rippleInitiatorsCount', this._rippleInitiators.length)

    const matrix = this._rttCamera.getViewMatrix(true).multiply(this._rttCamera.getProjectionMatrix())

    this._underWaterGroundMaterial.setFloat('time', this._time)
    this._underWaterGroundMaterial.setVector3('cameraPosition', this.camera.position)
    this._underWaterGroundMaterial.setMatrix('lightMatrix', matrix)
    this._underWaterGroundMaterial.setTexture('rttSampler', this._depthRenderer.getDepthMap())

    if (rippleInitiatorsData.positions.length > 0) this._underWaterGroundMaterial.setArray3('rippleInitiatorsPosition', rippleInitiatorsData.positions)
    if (rippleInitiatorsData.times.length > 0) this._underWaterGroundMaterial.setFloats('rippleInitiatorsTime', rippleInitiatorsData.times)
    this._underWaterGroundMaterial.setInt('rippleInitiatorsCount', this._rippleInitiators.length)
  }

  private _createMaterial() : void {
    this._waterMaterial = new ShaderMaterial("shader water", this.scene, {
      vertex: Shaders.Water,
      fragment: Shaders.Water
    }, {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      samplers: ['textureSampler'],
      needAlphaBlending: false,
    })

    this._waterMaterial.backFaceCulling = true
    this._waterMaterial.setFloat('time', this._time)
    this._waterMaterial.setTexture('bumpSampler', new Texture('/projects/water-world/Water_norm.jpg', this.scene));
    this._waterMaterial.setTexture('waterSampler', new Texture('/projects/water-world/water.jpeg', this.scene));

    this._waterMaterial.setArray3('rippleInitiatorsPosition', [0,0,0])
    this._waterMaterial.setFloats('rippleInitiatorsTime', [0])
    this._waterMaterial.setInt('rippleInitiatorsCount', 0)

    this._underWaterGroundMaterial = new ShaderMaterial("shader water shadow", this.scene, {
      vertex: Shaders.WaterShadow,
      fragment: Shaders.WaterShadow
    }, {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      samplers: ['textureSampler'],
      needAlphaBlending: true,
    })

    this._underWaterGroundMaterial.setArray3('rippleInitiatorsPosition', [0,0,0])
    this._underWaterGroundMaterial.setFloats('rippleInitiatorsTime', [0])
    this._underWaterGroundMaterial.setInt('rippleInitiatorsCount', 0)
    this._underWaterGroundMaterial.setTexture('stonesSampler', new Texture('/projects/water-world/stones.jpeg', this.scene))
  }

  private _createModel() : void {

    const moonMat: StandardMaterial = new StandardMaterial('moon', this.scene)
    moonMat.diffuseColor = new Color3(0.5,0.5,1)

    this._moonMesh = MeshBuilder.CreateSphere('moon', {
      diameter: 2.5
    }, this.scene)

    this._moonMesh.position = new Vector3(35, 7, 50)
    this._moonMesh.material = moonMat

    const box: Mesh = MeshBuilder.CreateBox('box', {
      size: 1
    }, this.scene)
    box.position = new Vector3(0,0,-20)
    box.lookAt(Vector3.Zero())
    box.visibility = 0

    this._lightSourceBox = box

    this._waterModel = Mesh.CreateGround('water model', 40, 40, 300, this.scene)
    this._waterMaterial.setFloat('time', 0)
    this._waterModel.material = this._waterMaterial

    this._underWaterGroundMesh = Mesh.CreateGround('water model', 40, 40, 100, this.scene)
    this._underWaterGroundMesh.position.y = -2
    this._underWaterGroundMesh.material = this._underWaterGroundMaterial
    this._underWaterGroundMesh.isPickable = false

    this._createDepthRenderer()
  }

  private _createDepthRenderer() : void {
    /** RTT **/

    const logoMat = new StandardMaterial('logo', this.scene)
    logoMat.diffuseTexture = new Texture('/projects/water-world/logo.jpg', this.scene)
    logoMat.emissiveColor = Color3.White()

    const logoPlane: Mesh = MeshBuilder.CreatePlane('logo', {
      width: 6.2 * 2,
      height: 2.88 * 2,
      sideOrientation: Mesh.DOUBLESIDE
    })

    //
    logoPlane.rotation.x = Math.PI / 2 - Math.PI / 15
    logoPlane.rotation.y = Math.PI / 2
    //logoPlane.rotation.z = Math.PI / 5

    logoPlane.material = logoMat
    logoPlane.position.y = -1.2
    logoPlane.position.x = -14


    this._rttCamera = new FreeCamera('rtt camera', new Vector3(-10,20,1), this.scene)
    this._rttCamera.mode = Camera.ORTHOGRAPHIC_CAMERA
    this._rttCamera.orthoBottom = - 20
    this._rttCamera.orthoTop = 20
    this._rttCamera.orthoLeft = -20
    this._rttCamera.orthoRight = 20

    this._topCamera = new FreeCamera('top camera', Vector3.Up(), this.scene)
    this._topCamera.mode = Camera.ORTHOGRAPHIC_CAMERA
    this._topCamera.orthoBottom = - 20
    this._topCamera.orthoTop = 20
    this._topCamera.orthoLeft = -20
    this._topCamera.orthoRight = 20

    this._topCamera.setTarget(Vector3.Zero())
    this._topCamera.rotation.z = Math.PI

    this._renderTargetTexture = new RenderTargetTexture('rtt', 1024, this.scene)
    this._renderTargetTexture.activeCamera = this._topCamera
    this._renderTargetTexture.renderList!.push(this._underWaterGroundMesh)
    this._renderTargetTexture.renderList!.push(logoPlane)

    this.scene.customRenderTargets.push(this._renderTargetTexture)

    this._depthRenderer = this.scene.enableDepthRenderer(this._rttCamera, false, false)
    this._depthRenderer.enabled = true
    this._depthRenderer.getDepthMap().renderList = []
    this._depthRenderer.getDepthMap().renderList!.push(logoPlane)

    const depthMat: StandardMaterial = new StandardMaterial('depth mat', this.scene)

    depthMat.diffuseTexture = this._depthRenderer.getDepthMap()

    this._depthDebugMesh = MeshBuilder.CreatePlane('depth debug', {
      size: 3
    }, this.scene)
    this._depthDebugMesh.position.x = -10
    this._depthDebugMesh.position.y = 10
    this._depthDebugMesh.material = depthMat
    this._depthDebugMesh.visibility = 0
  }

  public addMeshForRenderShadow(mesh: Mesh) :void {

    mesh.getChildMeshes().forEach(meshItem => {
      this._depthRenderer.getDepthMap().renderList!.push(meshItem)
      this._renderTargetTexture.renderList!.push(meshItem)
    })
  }
}
