
import {Vue} from "vue-class-component";
import PostProcessSceneController from "@/components/PostProcess/classes/PostProcessSceneController.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class PostProcess extends Vue {
  private _sceneController!: PostProcessSceneController

  public mounted() : void {
    this._sceneController = new PostProcessSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    EventBus.emit(EventBusEvents.SceneLoaded)
  }

  public unmounted() : void {
    this._sceneController.dispose()
  }
}
