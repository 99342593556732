import SceneController from "@/classes/SceneController.class";
import {Color4, DefaultRenderingPipeline, HemisphericLight, PointLight, Vector3} from "@babylonjs/core";
import WaterModelController from "@/components/WaterWorld/classes/WaterModelController.class";
import ShadersCreator from "@/classes/ShaderCreator/ShadersCreator.class";
import DropsController from "@/components/WaterWorld/classes/DropsConstroller.class";
import ShipModelController from "@/components/WaterWorld/classes/ShipModelController.class";

export default class WaterWorldSceneController extends SceneController {

  private _waterModelController!: WaterModelController
  private _dropsController!: DropsController
  private _shipModelController!: ShipModelController

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})

    this._initScene()
  }

  private _initScene() : void {
    ShadersCreator.CreateShaders()

    this.createCameraController()
    this._createLights()
    this.scene.clearColor = new Color4(0,0,0,1)
    this.scene.environmentIntensity = 0

    this.cameraController.camera.target = new Vector3(0, 0, 0)
    this.cameraController.camera.position.y = 2
    this.cameraController.camera.upperRadiusLimit = 100
    this.cameraController.camera.radius = 60
    this.cameraController.camera.beta = Math.PI / 2 - Math.PI / 12
    this.cameraController.camera.alpha = -Math.PI / 3
    this.cameraController.camera.useAutoRotationBehavior = true
    this.cameraController.camera.autoRotationBehavior!.idleRotationSpeed = 0.07;

    this._waterModelController = new WaterModelController({
      sceneController: this
    })

    this._dropsController = new DropsController({
      sceneController: this
    })

    this._dropsController.setWaterController(this._waterModelController)

    this._shipModelController = new ShipModelController({
      sceneController: this
    })

    this._shipModelController.loadModel().then(() => {
      this._waterModelController.addMeshForRenderShadow(this._shipModelController.mesh!)
    })

    this._createVignette()
  }

  private _createLights() : void {
    const pointLight:PointLight = new PointLight('point light', new Vector3(-3,1, 2), this.scene)
    pointLight.intensity = 10
    pointLight.radius = 20
  }

  private _createVignette() : void {
    // const postProcess = new ImageProcessingPostProcess("processing", 1.0, this.cameraController.camera);
    // postProcess.vignetteWeight = 3
    // postProcess.vignetteStretch = 10
    // postProcess.vignetteColor = new Color4(0, 0, 0, 1)
    // postProcess.vignetteEnabled = true
    // postProcess.exposure = 1.5

    const defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, [this.cameraController.camera])
    defaultPipeline.samples = 4

    defaultPipeline.grainEnabled = true
    defaultPipeline.grain.intensity = 5
    defaultPipeline.grain.animated = true

    defaultPipeline.bloomEnabled = false
    // defaultPipeline.fxaaEnabled = true

    defaultPipeline.imageProcessingEnabled = true
    // defaultPipeline.imageProcessing.vignetteWeight = 2
    // defaultPipeline.imageProcessing.vignetteStretch = 5
    // defaultPipeline.imageProcessing.vignetteColor = new Color4(0, 0, 0, 1)
    // defaultPipeline.imageProcessing.vignetteEnabled = true
    // defaultPipeline.imageProcessing.exposure = 0.3
    // defaultPipeline.imageProcessing.contrast = 0.97

    // defaultPipeline.cameraFov = this.cameraController.camera.fov
  }
}
