export interface ProjectContent {
  id: string,
  title: string,
  url: string,
  image: string,
  info: {
    title: string,
    text1: string,
    text2: string
  }
  nextProject: {
    title: string[],
    id: string
  }
}

export default class ProjectsContents {
  private contentList: ProjectContent [] = [
    {
      id: 'jetta',
      title: '3D-Showroom<br/>for VW',
      url: 'http://62.76.45.167',
      image: '/landing/image-effects/textures/jetta.jpg',
      info: {
        title: 'Jetta subtitle',
        text1: 'jetta column-1',
        text2: 'jetta column-2'
      },
      nextProject: {
        title: ['3D-Tour', 'Engine'],
        id: 'tour'
      }
    },
    {
      id: 'tour',
      title: '3D-Tour<br/>Engine',
      url: 'https://afi-tower.immercyb.com/tower',
      image: '/landing/image-effects/textures/tour.jpg',
      info: {
        title: 'Tours subtitle',
        text1: 'Tours column-1',
        text2: 'Tours column-2'
      },
      nextProject: {
        title: ['Kraus Blocks', 'Constructor'],
        id: 'kraus'
      }
    },
    {
      id: 'kraus',
      title: 'Kraus Blocks<br/>Constructor',
      url: 'https://kraus-blocks.ru/',
      image: '/landing/image-effects/textures/kraus.jpg',
      info: {
        title: 'Kraus subtitle',
        text1: 'Kraus column-1',
        text2: 'Kraus column-2'
      },
      nextProject: {
        title: ['LG Signature', 'WebAR'],
        id: 'lg-arle'
      }
    },
    {
      id: 'lg-arle',
      title: 'LG Signature<br/>WebAR',
      url: 'https://immercyb.com/lg-vangogh',
      image: '/landing/image-effects/textures/lg.jpg',
      info: {
        title: 'Lg Vangogh subtitle',
        text1: 'Lg Vangogh column-1',
        text2: 'Lg Vangogh column-2'
      },
      nextProject: {
        title: ['Immercyb Logo', 'WebAR'],
        id: 'ar-logo'
      }
    },
    {
      id: 'ar-logo',
      title: 'Immercyb Logo<br/>WebAR',
      url: 'https://immercyb.com/ar/immercyb-logo',
      image: '/landing/image-effects/textures/ar-logo-case.jpg',
      info: {
        title: 'AR logo subtitle',
        text1: 'AR logo column-1',
        text2: 'AR logo column-2'
      },
      nextProject: {
        title: ['3D-Showroom', 'for VW'],
        id: 'jetta'
      }
    }
  ]

  get(id: string) : ProjectContent | null {
    const list: ProjectContent[] = this.contentList.filter((item) => item.id === id)
    return list.length > 0 ? list[0] : null
  }

  get projectsIds() : string[] {
    return this.contentList.map((item) => item.id)
  }
}
