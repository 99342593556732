import { createStore } from 'vuex'
import lgVangogh from "@/store/modules/lgVangogh";

export default createStore({
  state: {
  },

  mutations: {
  },

  actions: {
  },

  getters: {
  },

  modules: {
    lgVangogh
  }
})
