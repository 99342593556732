
import SlideMixin from "./SlideMixin";
import {Options} from "vue-class-component";
import WordsSlider from "@/components/Landing/SlidesManager/slides/SliderSlide/WordsSlider.vue";

@Options({
  components: {
    WordsSlider
  }
})
export default class SliderSlide extends SlideMixin {
}
