import ModelController from "@/classes/SceneController/ModelController.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {AssetContainer, Vector3} from "@babylonjs/core";

export default class ShipModelController extends ModelController {
  private _time = 0

  constructor(args: SceneItemArgs) {
    super(args)
    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    if (this.mesh) {
      this._time += this.sceneController.engine.getDeltaTime()

      this.mesh.rotation.z = Math.sin(this._time * 0.002) * 0.03 -Math.PI / 10;
      this.mesh.rotation.x = Math.cos(this._time * 0.002) * 0.02 -Math.PI / 20;
    }
  }

  public loadModel() : Promise<void> {
    const promise: Promise<void> = new Promise<void>((resolve) => {
      this.loadModelFromUrl('/projects/water-world/ship.glb').then(() => {
        this.mesh!.scaling = new Vector3(1.3, 1.3, 1.3)
        this.mesh!.position.y = -1
        this.mesh!.rotation.z = -Math.PI / 10
        this.mesh!.rotation.x = -Math.PI / 20
        resolve()
      })
    })

    return promise
  }
}
