
import SlideMixin from "@/components/Landing/SlidesManager/slides/SlideMixin";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import isMobile from "is-mobile";

export default class Web3dSlide extends SlideMixin {
  public isMobile = false

  created() : void {
    this.isMobile = isMobile()
  }

  public mounted() : void {
    //
  }

  public onViewProjectsClick() : void {
    EventBus.emit(EventBusEvents.ViewProjects)
  }
}
