import {prop, Vue} from "vue-class-component";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

class SlideMixinProps {
  visible : boolean = prop({
    required: true
  })
  position: string = prop({
    required: true
  })
}

export default class SlideMixin extends Vue.with(SlideMixinProps) {
  private slideContentEl!: HTMLDivElement
  private frameRequestEnabled = false
  private touchCaptured = false

  private top = 0
  private bottom = 0
  private lastTouch!: Touch
  private marginTop = 0
  private toMarginTop = 0

  private touchStartTime = 0
  private yDelta = 0
  private yDeltaSum = 0

  mounted() : void {
    this.slideContentEl = this.$el.querySelector('.slide-content')
    this.frameRequestEnabled = true
    this.requestAnimationFrame()

    document.addEventListener('touchstart', this.onTouchStart)
    document.addEventListener('touchend', this.onTouchEnd)
    document.addEventListener('touchmove', this.onTouchMove)
  }

  unmounted() : void {
    this.frameRequestEnabled = false

    document.removeEventListener('touchstart', this.onTouchStart)
    document.removeEventListener('touchend', this.onTouchEnd)
    document.removeEventListener('touchmove', this.onTouchMove)
  }

  private onTouchStart(e: TouchEvent) : void {
    this.touchCaptured = true
    this.lastTouch = e.touches[0]
    this.touchStartTime = performance.now()
  }

  private onTouchMove(e:TouchEvent) : void {
    if (this.$route.path === '/') {
      this.yDelta = this.lastTouch.clientY - e.touches[0].clientY
      this.yDeltaSum += this.yDelta

      if (this.visible && this.slideContentEl) {
        this.toMarginTop -= this.yDelta
        this.lastTouch = e.touches[0]
      }
    }
  }

  private onTouchEnd() : void {
    this.touchCaptured = false
    const timeDelta = performance.now() - this.touchStartTime

    if (timeDelta < 300 && Math.abs(this.yDeltaSum) > 50) {
      if (this.yDeltaSum > 0) { // && this.bottom < 0) {
        EventBus.emit(EventBusEvents.ShowNextSlide)
      }

      if (this.yDeltaSum < 0) { // && this.top > 0) {
        EventBus.emit(EventBusEvents.ShowPrevSlide)
      }
    }

    this.yDeltaSum = 0
  }

  requestAnimationFrame() : void {
    if (this.frameRequestEnabled) window.requestAnimationFrame(() => {
      this.update()
      this.requestAnimationFrame()
    })
  }

  private update() {
    if (this.slideContentEl) {
      const rect = this.slideContentEl.getBoundingClientRect()
      this.bottom = rect.bottom - window.innerHeight + 60
      this.top = rect.top

      if (this.visible) {
        this.marginTop += (this.toMarginTop - this.marginTop) / 10

        if (!this.touchCaptured) {
          if (rect.height > window.innerHeight) {
            if (this.bottom < 0) {
              this.toMarginTop -= this.bottom / 20
            }

            if (this.top > 0) {
              this.toMarginTop -= this.top / 20
            }
          } else {
            this.toMarginTop = 0
          }
        }
      } else {
        this.toMarginTop = 0
      }

      this.slideContentEl.style.marginTop = this.marginTop.toFixed(0) + 'px'
    }
  }
}
