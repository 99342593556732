import SceneController from "@/classes/SceneController.class"
import VrManModelController from "@/components/VrMan/classes/VrManModelController.class";
import EventBus from "@/classes/EventBus.class"
import EventBusEvents from "@/classes/EventBusEvents"
import {Color4, Vector3} from "@babylonjs/core";

export interface LightsProps {
  positions: Vector3[]
  colors: string[]
  targets: Vector3[]
  forces: number[]
  specularForces: number[],
  shininess: number[]
}

export default class VrManSceneController extends SceneController {

  private _modelController: VrManModelController

  public get lightsProps() : LightsProps {
    return this._modelController.lightsProps
  }

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({
      canvas
    })

    this.createCameraController()

    this._modelController = new VrManModelController({
      sceneController: this
    })

    this.scene.clearColor = new Color4(0.01,0.00,0.12,0)

    this.cameraController.camera.target = new Vector3(0., 6, 0.)
    this.cameraController.camera.beta = Math.PI / 2 - Math.PI / 20
    this.cameraController.camera.alpha = Math.PI / 2 + Math.PI / 6
    this.cameraController.camera.lowerRadiusLimit = 5
    this.cameraController.camera.upperRadiusLimit = 50
    this.cameraController.camera.radius = 30
    // this.cameraController.camera.wheelPrecision = isMobile() ? 10 :  1

    // this.cameraController.camera.position = new Vector3(0,0, 9000)

    this.engine.setHardwareScalingLevel(1)

    EventBus.emit(EventBusEvents.SceneLoaded)
  }
}
