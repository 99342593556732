import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "landing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_background = _resolveComponent("background")!
  const _component_slides_manager = _resolveComponent("slides-manager")!
  const _component_contacts = _resolveComponent("contacts")!
  const _component_main_menu = _resolveComponent("main-menu")!
  const _component_logo = _resolveComponent("logo")!
  const _component_landing_preloader = _resolveComponent("landing-preloader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_background, {
      hidden: _ctx.state.menuOpened || _ctx.state.contactsOpened
    }, null, 8, ["hidden"]),
    _createVNode(_component_slides_manager, {
      hidden: _ctx.state.menuOpened || _ctx.state.contactsOpened
    }, null, 8, ["hidden"]),
    _createVNode(_component_contacts, {
      hidden: !_ctx.state.contactsOpened
    }, null, 8, ["hidden"]),
    _createVNode(_component_main_menu, { onOpened: _ctx.onMainMenuOpened }, null, 8, ["onOpened"]),
    _createVNode(_component_logo),
    _createVNode(_component_landing_preloader)
  ]))
}