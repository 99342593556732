
import {Options, prop, Vue} from "vue-class-component";
import IconButton from "@/components/Landing/ui/IconButton.vue";

class MainMenuButtonProps {
  menuOpened: boolean = prop({
    type: Boolean,
    required: true
  })
}

@Options({
  components: {
    IconButton
  }
})
export default class MainMenuButton extends Vue.with(MainMenuButtonProps) {

}
