import SceneController from "@/classes/SceneController.class";
import {Color4, Texture} from "@babylonjs/core";
import ImageMask
  from "@/components/Landing/MainMenu/ProjectsMenuContent/ProjectImage/classes/postProcess/ImageMask.class";

export default class ProjectImageSceneController extends SceneController {
  private _texturesUrls!: string[]
  private _imageMask!: ImageMask

  private _textures!: Texture[]

  constructor({canvas} : {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.scene.clearColor = new Color4(0,0,0,0)

    this.createCameraController()

    this._imageMask = new ImageMask({
      sceneController: this
    })
  }

  public setTextureIndex(index: number) : void {
    if (index > 0) {
      this._imageMask.setTexture(this._textures[index])
      this._imageMask.show()
    } else  {
      this._imageMask.hide()
    }
  }

  public setTextures(textures: string[]) : void {
    this._texturesUrls = textures
    this._textures = this._texturesUrls.map((url) => new Texture(url, this.scene))

    this._imageMask.setTexture(this._textures[0])
  }
}
