import BaseBlob from "@/components/Landing/Background/classes/sceneItems/blobs/BaseBlob.class";
import {SceneItemArgs} from "@/classes/SceneController/abstract/SceneItem.class";
import {
  Mesh,
  ShaderMaterial,
  Vector3
} from "@babylonjs/core";
import SatelliteIcoSphereModel
  from "@/components/Landing/Background/classes/sceneItems/blobs/BlobedIcoSphere/SatelliteIcoSphereModel.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class BlobedIcoSphereModel extends BaseBlob {

  private _satelittes:SatelliteIcoSphereModel[] = []

  get meshFront() : Mesh {
    return this._meshFront
  }

  constructor(props: SceneItemArgs) {
    super(props)

    this._linesCount = 20
    this._lineThickness = .4
    this._speed = 0.00005

    //this._createMaterial()
    this._createStaticCustomPbr()
    this._createSphere()
  }

  protected _createStaticCustomPbr() {
    super._createStaticCustomPbr()

    this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/ico-sphere/fx/blob-back", {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
      needAlphaBlending: false,
    })

    this._materialBack.backFaceCulling = true
  }

  private _createSatellites() : void {
    for (let i = 0; i < 3 ; i++) {
      const satelitte = new SatelliteIcoSphereModel({
        sceneController: this.sceneController
      })

      satelitte.setParentSphere(this, i)
      this._satelittes.push(satelitte)
    }
  }

  protected _update() {
    super._update();
    if (!this._visible && this._scaling.y < 0.1) {
      this._rotationOffset = Vector3.Zero()
    }
  }

  // private _createMaterial() {
  //   this._materialFront = new ShaderMaterial("blob-material-front", this.scene, "/landing/ico-sphere/fx/blob", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //
  //   this._materialFront.backFaceCulling = false
  //
  //   this._materialBack = new ShaderMaterial("blob-material-back", this.scene, "/landing/ico-sphere/fx/blob-back", {
  //     attributes: ["position", "normal", "uv"],
  //     uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"],
  //     needAlphaBlending: false,
  //   })
  //
  //   this._materialBack.backFaceCulling = true
  //
  //   this._createLights()
  // }

  private _createSphere() {
    const scaling = 0.4

    this.loadModelFromUrl('/landing/ico-sphere/model.glb', false, false).then((assetContainer) => {
      const originMesh:Mesh = assetContainer.meshes.filter((mesh) => mesh.name === 'Platonic')[0] as Mesh
      const parentMesh = new Mesh('ico-root', this.scene)

      parentMesh.layerMask = 2
      originMesh.parent = parentMesh
      originMesh.position = Vector3.Zero()
      originMesh.scaling = new Vector3(scaling, scaling, scaling)

      this._meshFront = parentMesh.clone()
      this._meshBack = parentMesh.clone()

      this._meshFront.getChildMeshes().forEach((mesh) => {
        if (mesh.material) mesh.material = this._customFrontMaterial
      })

      this._meshBack.getChildMeshes().forEach((mesh) => {
        const meshInstance : Mesh = mesh as Mesh
        meshInstance.makeGeometryUnique()
        meshInstance.flipFaces(true)
        mesh.material = this._materialBack
      })

      // originMesh.dispose()
      parentMesh.dispose()

      this._meshFront.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)
      this._meshBack.getChildMeshes().forEach((mesh) => mesh.layerMask = 2)

      this._meshFront.layerMask = 2
      this._meshBack.layerMask = 2

      this.blobedPostProcess.addMeshForRender(this._meshFront, true)
      this.blobedPostProcess.addMeshForRender(this._meshBack, false)

      this._meshFront.scaling = this._meshBack.scaling = Vector3.Zero()
      this._createSatellites()

      EventBus.emit(EventBusEvents.BlobedIcoLoaded)
    })
  }

  public show() : void {
    if (!this._visible) {
      this._visible = true
      this.scaleTo(Vector3.One(), 2)
      this.rotateTo(new Vector3(-0.6,Math.PI * 2 + Math.PI * 0.4, -0.4), 2)
      this.positionTo(new Vector3(0.5, 0, 0), 2)
    }
  }

  public hide() : void {
    if (this._visible) {
      this._visible = false
      this.rotateTo(new Vector3(0, Math.PI * 4, 0), 2)
      this.scaleTo(Vector3.Zero(), 2)
      this.positionTo(new Vector3(0, 0, 0), 2)
    }
  }
}
