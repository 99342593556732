
import {prop, Vue} from "vue-class-component";
import {Color4, Vector3} from "@babylonjs/core";

class LightsSettingsProps {
  public positions = prop({
    type: Array
  })

  public targets = prop({
    type: Array
  })

  public colors = prop({
    type: Array
  })

  public forces = prop({
    type: Array
  })

  public specularForces = prop({
    type: Array
  })

  public shininess = prop({
    type: Array
  })
}

export default class LightsSettings extends Vue.with(LightsSettingsProps) {

}
