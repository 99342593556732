import {Effect} from "@babylonjs/core";
import stretchVertexShader from 'raw-loader!./shaders/stretch-vertex.txt'
import stretchFragmentShader from 'raw-loader!./shaders/stretch-fragment.txt'

import fractalVertexShader from 'raw-loader!./shaders/fractal-vertex.txt'
import fractalFragmentShader from 'raw-loader!./shaders/fractal-fragment.txt'

import manVertexShader from 'raw-loader!./shaders/man-vertex.txt'
import manFragmentShader from 'raw-loader!./shaders/man-fragment.txt'

import waterVertexShader from 'raw-loader!./shaders/water-vertex.txt'
import waterFragmentShader from 'raw-loader!./shaders/water-fragment.txt'

import waterShadowVertexShader from 'raw-loader!./shaders/water-shadow-vertex.txt'
import waterShadowFragmentShader from 'raw-loader!./shaders/water-shadow-fragment.txt'

export default class ShadersCreator {
  public static CreateShaders() : void {
    Effect.ShadersStore["stretchVertexShader"] = stretchVertexShader
    Effect.ShadersStore["stretchFragmentShader"] = stretchFragmentShader

    Effect.ShadersStore["fractalFragmentShader"] = fractalFragmentShader
    Effect.ShadersStore["fractalVertexShader"] = fractalVertexShader

    Effect.ShadersStore["manFragmentShader"] = manFragmentShader
    Effect.ShadersStore["manVertexShader"] = manVertexShader

    Effect.ShadersStore["waterFragmentShader"] = waterFragmentShader
    Effect.ShadersStore["waterVertexShader"] = waterVertexShader

    Effect.ShadersStore["waterShadowFragmentShader"] = waterShadowFragmentShader
    Effect.ShadersStore["waterShadowVertexShader"] = waterShadowVertexShader
    // Effect.ShadersStore["stretchFragmentShader"] = "\r\n" + "precision highp float;\r\n" + "varying vec2 vUV;\r\n" + "uniform sampler2D textureSampler;\r\n" + "void main(void) {\r\n" + "    gl_FragColor = texture2D(textureSampler, vUV);\r\n" + "}\r\n";
  }
}
