import SceneController from "@/classes/SceneController.class";
import {PostProcess, Texture, Vector2} from "@babylonjs/core";

export default class LogoFlatShadingSceneController extends SceneController {

  private _time = 0

  constructor({canvas}: {canvas: HTMLCanvasElement}) {
    super({canvas})
    this.createCameraController()
    this._createPostProcess()

  }

  private _createPostProcess() {
    const logoPostProcess = new PostProcess(
      'occlusion',
      '/projects/logo-flat-shading/fx/logo',
      ['time', 'screenSize'],
      ['logoSampler', 'colorsSampler'],
      1.,
      this.cameraController.camera
    )

    const logoTexture = new Texture('/projects/logo-flat-shading/textures/logo.jpg', this.scene)
    const colorsTexture = new Texture('/projects/logo-flat-shading/textures/colors.jpg', this.scene)


    logoPostProcess.onApply = (effect) => {
      this._time += this.engine.getDeltaTime()
      effect.setFloat('time', this._time)
      effect.setVector2('screenSize', new Vector2(logoPostProcess.width, logoPostProcess.height))
      effect.setTexture('logoSampler', logoTexture)
      effect.setTexture('colorsSampler', colorsTexture)
    }
  }
}
