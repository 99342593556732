import SceneController from "@/classes/SceneController.class"
import {AbstractMesh, AnimationGroup, Mesh, PBRMaterial, Size, TransformNode, Vector2, Vector3} from "@babylonjs/core"
import ModelController from "@/classes/SceneController/ModelController.class"

export enum FlyingDetailEvents {
  ScannerAnimationComplete = 'ScannerAnimationComplete'
}

export default class FlyingDetail extends ModelController {
  private _texturePosition:Vector2 = Vector2.Zero()
  private _pictureSize: Size = new Size(2, 2)

  private _parentMesh: Mesh
  private _frames: TransformNode[] = []

  private _visibleFrameIndex: number | null = null
  private _lastFrameIndex: number | null = null

  private _scannerAnimationGroup!: AnimationGroup
  private _scannerAnimationComplete = false

  private _visible = false
  private _state = 'show'

  public detailVisible = false

  set visible(val : boolean) {
    if (this._visible !== val) {
      this._visible = val

      if (!this.visible) {
        this.hideFrame()
      }
      // if (this.mesh) this.mesh.scaling = val ? Vector3.One().multiplyByFloats(-1, 1, 1) : Vector3.Zero()
    }
  }

  public get framesCount() : number {
    return this._frames ? this._frames.length : 0
  }

  public get isPlaying() : boolean {
    return this._scannerAnimationGroup?.isPlaying??false
  }

  public get frameTextureOffset() : Vector2 | null {
    const offset : Vector2 = Vector2.Zero()

    if (this._lastFrameIndex !== null) {
      const frameNode: TransformNode = this._frames[this._lastFrameIndex]
      switch (frameNode.name) {
        case 'High_tech_frame_Art_part1':
          offset.x = 0.5
          offset.y = 0.6
          break
        case 'High_tech_frame_Art_part2':
          offset.x = 0.55
          offset.y = 0.8
          break
        case 'High_tech_frame_Art_part3':
          offset.x = 0.8
          offset.y = 0.8
          break
        case 'High_tech_frame_Art_part4':
          offset.x = 0.3
          offset.y = 0.7
          break
        case 'High_tech_frame_Art_part5':
          offset.x = 0.45
          offset.y = 0.63
          break
        case 'High_tech_frame_Art_part6':
          offset.x = 0.8
          offset.y = 0.68
          break
        case 'High_tech_frame_Art_part7':
          offset.x = 0.55
          offset.y = 0.45
          break
      }
      return offset
    }

    return  null
  }

  constructor({sceneController, parentMesh} : { sceneController: SceneController, parentMesh: Mesh }) {
    super({
      sceneController
    })

    this._parentMesh = parentMesh

    this._loadModel()

    this.scene.onBeforeRenderObservable.add(this._update)
  }

  private _update = () : void => {
    const deltaTime = this.sceneController.engine.getDeltaTime()

    if (this._scannerAnimationComplete && this._visibleFrameIndex !== null && this._frames) {
      this._frames.forEach((frameMesh, index) => {
        const childMeshes : AbstractMesh[] = frameMesh.getChildMeshes()

        childMeshes.forEach((childItem) => {
          if (index === this._visibleFrameIndex) {
            childItem.visibility += ((1 - childItem.visibility) / 500) * deltaTime
          } else {
            childItem.visibility += ((0 - childItem.visibility) / 500) * deltaTime
          }
        })
      })
    } else if (this._frames) {
      this._frames.forEach((frameMesh, index) => {
        const childMeshes: AbstractMesh[] = frameMesh.getChildMeshes()
        childMeshes.forEach((childItem) => {
          childItem.visibility += ((0 - childItem.visibility) / 500) * deltaTime
        })
      })
    }
  }

  private _onScannerAnimationComplete = () : void => {
    if (this._state === 'hide') {
      this.detailVisible = false

      this._visibleFrameIndex = null
      this._lastFrameIndex = null
      if (this.mesh) this.mesh.scaling = Vector3.Zero()
    }

    this._scannerAnimationComplete = true
    this.dispatchEvent(new Event(FlyingDetailEvents.ScannerAnimationComplete))
  }

  private _loadModel() : void {
    this.loadModelFromUrl('/projects/lg-vangogh/models/details-5.glb').then((assetContainer) => {

      assetContainer.animationGroups.forEach((animationGorupItem) => {
        if (animationGorupItem.name === 'Scanner_lines_animationAction') {
          this._scannerAnimationGroup = animationGorupItem
          animationGorupItem.onAnimationGroupEndObservable.add(this._onScannerAnimationComplete)
        } else {
          animationGorupItem.start(true)
        }
      })

      assetContainer.transformNodes.forEach((nodeItem) => {
        if (nodeItem.name.indexOf('High_tech_frame') >= 0) {
          const index = parseInt(nodeItem.name.substr('High_tech_frame_Art_part'.length, 1)) - 1
          this._frames[index] = nodeItem

          nodeItem.getChildMeshes().forEach((meshItem) => {
            if (meshItem.material) {
              meshItem.visibility = 0

              const material: PBRMaterial = meshItem.material as PBRMaterial
              material.emissiveIntensity = 0
              material.alphaMode = 2
              material.transparencyMode = 2
            }
          })
        }
      })

      assetContainer.materials.forEach(material => material.freeze())

      this.mesh!.parent = this._parentMesh
      this.mesh!.scaling = Vector3.Zero()
      this.mesh!.position.z = 0.1
    })
  }

  public setTexturePosition(position: Vector2) : void {
    this._texturePosition = position
    if (this.mesh) {
      this.mesh.position.x = this._texturePosition.x * this._pictureSize.width - this._pictureSize.width / 2
      this.mesh.position.y = this._texturePosition.y * this._pictureSize.height - this._pictureSize.height / 2
    }
  }

  public showFrameWithIndex(index: number | null) : void {
    this.detailVisible = true
    this._state = 'show'
    this._scannerAnimationComplete = false
    this._visibleFrameIndex = index
    if (index !== null) {
      if (this.mesh) this.mesh.scaling = Vector3.One().multiplyByFloats(-1, 1, 1)
      this._lastFrameIndex = index
    }
    this._scannerAnimationGroup.start(false, 1, 0)
  }

  public hideFrame() : void {
    this._state = 'hide'
    this._scannerAnimationComplete = false
    this._scannerAnimationGroup.start(false, -1)
  }
}
