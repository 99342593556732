
import {Vue} from "vue-class-component";
import LogoFlatShadingSceneController from "@/components/LogoFlatShading/classes/LogoFlatShadingSceneController.class";
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";

export default class LogoFlatShading extends Vue {
  private _sceneController!: LogoFlatShadingSceneController

  public mounted() : void {
    this._createSceneController()
    EventBus.emit(EventBusEvents.SceneLoaded)
  }

  private _createSceneController() : void {
    this._sceneController = new LogoFlatShadingSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })
  }
}
