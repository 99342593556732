

import {Options, Vue} from "vue-class-component"
import LgVangoghSceneController, {LgVangoghSceneControllerEvents} from "@/components/LgVangogh/classes/LgVangoghSceneController.class"
import {PictureAnimations} from "@/components/LgVangogh/classes/PictureModel.class"
import ProgressBar from "@/components/LgVangogh/ProgressBar.vue"
import FinalScreen from "@/components/LgVangogh/FinalScreen.vue"
import EventBus from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents"

@Options({
  components: {ProgressBar, FinalScreen}
})
export default class LgVangogh extends Vue {
  public pictureLoaded = false
  public setupButtonPressed = false
  public pictureAnimationComplete = false
  public preloaderVisible = false

  private _sceneController!:LgVangoghSceneController | null

  public currentAnimationIndex = 0

  public setupText = 'ДЛЯ УСТАНОВКИ AR ВЫБЕРИТЕ ПРОСТОРНУЮ,<br/>РОВНУЮ И ГОРИЗОНТАЛЬНУЮ ПОВЕРХНОСТЬ.<br/>ТАКЖЕ РЕКОМЕНДУЕМ ВКЛЮЧИТЬ ЗВУК НА<br/> ВАШЕМ УСТРОЙСТВЕ'

  private animationsList: PictureAnimations[] = [
    PictureAnimations.Start,
    PictureAnimations.Frames,
    PictureAnimations.Letter,
    PictureAnimations.Details,
    PictureAnimations.Smears,
    PictureAnimations.Final,
  ]

  public get allAnimationsComplete() : boolean {
    return this.currentAnimationIndex === this.animationsList.length - 1
  }

  mounted() : void {
    // console.log('v 2.0')

    this._sceneController = new LgVangoghSceneController({
      canvas: this.$refs.canvas as HTMLCanvasElement
    })

    this._sceneController.addEventListener(LgVangoghSceneControllerEvents.PictureModelLoaded, this._onModelLoaded)
    this._sceneController.addEventListener(LgVangoghSceneControllerEvents.PictureAnimationComplete, this._onPictureAnimationComplete)
    this._sceneController.addEventListener(LgVangoghSceneControllerEvents.SoundStarted, this._onSoundStarted)
  }

  public unmounted() {
    if (this._sceneController) {
      this._sceneController.removeEventListener(LgVangoghSceneControllerEvents.PictureModelLoaded, this._onModelLoaded)
      this._sceneController.removeEventListener(LgVangoghSceneControllerEvents.PictureAnimationComplete, this._onPictureAnimationComplete)
      this._sceneController.removeEventListener(LgVangoghSceneControllerEvents.SoundStarted, this._onSoundStarted)

      this._sceneController.dispose()
      this._sceneController = null
    }
  }

  private _onSoundStarted() : void {
    this.preloaderVisible = false
  }

  private _onPictureAnimationComplete() : void {
    this.pictureAnimationComplete = true

    // this.currentAnimationIndex = 5
    // this._sceneController.playAnimation(this.animationsList[this.currentAnimationIndex])
    // this._sceneController.playFrameSound(this.animationsList[this.currentAnimationIndex])
  }

  private _onModelLoaded() : void {
    EventBus.emit(EventBusEvents.SceneLoaded)
    this.pictureLoaded = true
  }

  public onSetupButtonClick() : void {
    this._sceneController!.setupPicture()
    this.setupButtonPressed = true
    this.preloaderVisible = true
  }

  public onContinueButtonClick() : void {
    this.pictureAnimationComplete = false

    this.currentAnimationIndex++
    if (this.currentAnimationIndex >= this.animationsList.length) this.currentAnimationIndex = 1

    this._sceneController!.playAnimation(this.animationsList[this.currentAnimationIndex])
    this._sceneController!.playFrameSound(this.animationsList[this.currentAnimationIndex])
  }
}
