
import {Vue} from "vue-class-component";
import EventBus, {EventBusEvent} from "@/classes/EventBus.class";
import EventBusEvents from "@/classes/EventBusEvents";
import {SceneState} from "@/components/Landing/Background/classes/BackgroundSceneController.class";
import {reactive} from "vue";

interface ScrollerInfoState {
  sceneState: SceneState | null,
  prevPage: number,
  currentPage: number,
  prevPageHidden: boolean,
  currentPageHidden: boolean
}

export default class ScrollerInfo extends Vue {
  public state : ScrollerInfoState = reactive({
    sceneState: SceneState.MathDesign,
    prevPage: 1,
    currentPage: 1,
    prevPageHidden: true,
    currentPageHidden: false
  })

  public get defaultScrollerVisible() : boolean {
    return this.state.sceneState === SceneState.MathDesign || this.state.sceneState === SceneState.MarketLeaders
  }

  public get scrollDownTextVisible() : boolean {
    return this.state.sceneState === SceneState.MathDesign
  }

  public get paginatorVisible() : boolean {
    const disabledScenes: (SceneState | null)[] = [SceneState.Slider, SceneState.Final]
    return this.state.sceneState !== null && !this.defaultScrollerVisible && disabledScenes.indexOf(this.state.sceneState) < 0
  }

  public mounted() : void {
    EventBus.addEventListener(EventBusEvents.LandingScene, this.onLandingScene)
  }

  public unmounted() : void {
    EventBus.removeEventListener(EventBusEvents.LandingScene, this.onLandingScene)
  }

  public getCurrentPage() : number {
    let page = 0

    interface dataItem {
      slides: (SceneState | null)[]
      page: number
    }

    const data : dataItem[] = [
      {
        slides: [SceneState.Web3d],
        page: 1
      },
      {
        slides: [SceneState.AR1,SceneState.AR2],
        page: 2
      },
      {
        slides: [SceneState.VR, SceneState.Slider, SceneState.Final],
        page: 3
      }
    ]

    data.forEach((item) => {
      if (item.slides.indexOf(this.state.sceneState) >= 0 && page < item.page) {
        page = item.page
      }
    })

    return page
  }

  public updateCurrentPage() : void {
    const page = this.getCurrentPage()
    if (this.state.currentPage !== page && page > 0) {
      this.state.prevPage = this.state.currentPage
      this.state.currentPage = page

      this.state.prevPageHidden = false
      this.state.currentPageHidden = true

      setTimeout(() => {
        this.state.prevPageHidden = true
        this.state.currentPageHidden = false
      }, 1000)

    }
  }

  public dotEnabled(dotNum: number) {
    const page = this.getCurrentPage()
    return dotNum <= page
  }

  private onLandingScene(e: Event) : void {
    const sceneState : SceneState | null = (e as EventBusEvent).data as SceneState | null
    this.state.sceneState = sceneState

    this.updateCurrentPage()
  }
}
